<template>
    <!-- component -->
  <div class="">
      <section id="bottom-navigation" class=" w-full m-auto grid grid-cols-6 fixed inset-x-0 bottom-0 z-10 bg-gray-900 text-gray-100 text-xs sm:text-sm  shadow">
          <router-link :to="{name : 'Home'}"  class="flex justify-between hover:bg-gray-700 duration-300">
            <div class="w-full  justify-center inline-block text-center p-1">
              <div class="flex justify-center"><img src="../assets/worker/peter.png" class="w-8"></div>
              <div>หน้าแรก</div>
            </div>
          </router-link>
      
          <router-link :to="{name : 'HowToPlay'}"  class="flex justify-between hover:bg-gray-700 duration-300">
            <div class="w-full  justify-center inline-block text-center p-1">
              <div class="flex justify-center"><img src="../assets/images/whitepaper.png" class="w-8"></div>
              <div>วิธีเล่น</div>
            </div>
          </router-link>
          <router-link :to="{name : 'Map'}"  class="flex justify-between hover:bg-gray-700 duration-300">
        <div class="w-full  justify-center inline-block text-center p-1">
          <div class="flex justify-center"><img src="../assets/images/map.png" class="w-8"></div>
          <div>แผนที่</div>
        </div>
          </router-link>
          <router-link :to="{name : 'Thief'}"  class="flex justify-between hover:bg-gray-700 duration-300">
        <div class="w-full  justify-center inline-block text-center p-1">
          <div class="flex justify-center"><img src="../assets/thief/benjamin.png" class="w-8"></div>
          <div>โจร</div>
        </div>
          </router-link>
          <router-link :to="{name : 'Rank'}"  class="flex justify-between hover:bg-gray-700 duration-300">
            <div class="w-full  justify-center inline-block text-center p-1">
              <div class="flex justify-center"><img src="../assets/images/rank.png" class="w-8"></div>
              <div>อันดับ</div>
            </div>
          </router-link>
          <router-link :to="{name : 'Stat'}"  class="flex justify-between hover:bg-gray-700 duration-300">
            <div class="w-full  justify-center inline-block text-center p-1">
              <div class="flex justify-center"><img src="../assets/images/search.png" class="w-8"></div>
              <div>สถิติ</div>
            </div>
          </router-link>
      </section>
  </div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  
  </style>