<template>
    <div class="p-1 sm:p-4 pb-16 mb-auto h-full text-white  overflow-auto" id="style-1">
      <div>ฟาร์ม</div>
    </div>
  </template>
  <script>
  export default {
      setup() {
          
      },
  }
  </script>