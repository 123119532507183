<template>
   
    <div class="p-3 bg-gray-900 rounded shadow space-y-1">
      <ModalAddItemToCharacter v-if="toggleModal === 'ModalAddItemToCharacter'" @cancelModal ="toggleModal = ''" />
      <ModalRemoveItemFromCharacter  v-if="toggleModal === 'ModalRemoveItemFromCharacter'" @cancelModal ="toggleModal = ''" />
      <ModalEnterCave v-if="toggleModal === 'ModalEnterCave'" @cancelModal ="toggleModal = ''"  />
      <ModalExitCave v-if="toggleModal === 'ModalExitCave'" @cancelModal ="toggleModal = ''"  />
      <div v-if="!character.isLive" class="w-full text-center bg-indigo-500 hover:bg-indigo-400 text-white font-bold p-1  border-b-4 border-indigo-700 hover:border-indigo-500 rounded duration-200 cursor-pointer">เกิดใหม่</div>
      <div @click="toggleModal = 'ModalEnterCave'" v-if="!character.cave" class="w-full text-center bg-indigo-500 hover:bg-indigo-400 text-white font-bold p-1  border-b-4 border-indigo-700 hover:border-indigo-500 rounded duration-200 cursor-pointer">เข้าถ้ำ</div>
      <div v-if="character.cave" @click="toggleModal = 'ModalExitCave'" class="w-full text-center bg-indigo-500 hover:bg-indigo-400 text-white font-bold p-1  border-b-4 border-indigo-700 hover:border-indigo-500 rounded duration-200 cursor-pointer">ออกถ้ำ</div>
      <div @click="toggleModal = 'ModalAddItemToCharacter'" class="w-full text-center bg-indigo-500 hover:bg-indigo-400 text-white font-bold p-1  border-b-4 border-indigo-700 hover:border-indigo-500 rounded duration-200 cursor-pointer">ใส่ไอเทม</div>
      <div @click="toggleModal = 'ModalRemoveItemFromCharacter'" class="w-full text-center bg-indigo-500 hover:bg-indigo-400 text-white font-bold p-1  border-b-4 border-indigo-700 hover:border-indigo-500 rounded duration-200 cursor-pointer">ถอดไอเทม</div>
    </div>
</template>
<script>
import getUser from '@/composables/getUser';
import { projectFirestore } from '@/firebase/config';
import { ref } from 'vue';
import ModalAddItemToCharacter from './ModalAddItemToCharacter.vue'
import ModalRemoveItemFromCharacter from './ModalRemoveItemFromCharacter.vue'
import ModalEnterCave from './ModalEnterCave.vue'
import ModalExitCave from './ModalExitCave.vue'
export default {
    components: { ModalExitCave, ModalEnterCave, ModalAddItemToCharacter,ModalRemoveItemFromCharacter },
    setup() {
        const { user } = getUser()
        const toggleModal = ref('')
        const character = ref({})
        const characterPrivate = ref({})
        projectFirestore.collection('characters').doc(user.value.uid).onSnapshot(characterDoc =>{
          character.value = characterDoc.data()
        })
        projectFirestore.collection('characterPrivate').doc(user.value.uid).onSnapshot(characterPrivateDoc =>{
          characterPrivate.value = characterPrivateDoc.data()
        })


        return { toggleModal, character, characterPrivate}
    },
}
</script>