<template>
  <div class="bg-gray-900 p-1  rounded text-center h-full flex justify-center items-center w-full">
    <div class="space-y-1 w-full">
      <div class="flex justify-center"><img :src="worker.img" class="w-40"></div>
      <div class="flex items-center space-x-2 justify-center font-bold">
        <div class="flex items-center space-x-1 border px-1 rounded  border-gray-600">
          <div><img src="../../assets/images/star.png" class="w-4"></div>
          <div class="font-bold">{{worker.star}}</div>
        </div>
        <div v-if="workerVip >= new Date()"><img src="../../assets/images/vip.png" class="w-5"></div>
        <div :class="worker.textColor" class="  text-lg font-bold">{{ worker.name }} </div>
        <div>({{ worker.power }})</div>
      </div>
      
      <div class="relative w-full h-7 sm:h-8 rounded bg-gray-300 text-xs text-gray-800 font-bold  overflow-hidden">
        <div
            class="bg-red-600  h-full"
            :style="{ width: `${100-((worker.hit / (worker.health + worker.itemHealth + worker.skillHealth+ worker.collectionHealth)) * 100)}%` }"
        ></div>
        <div class="absolute inset-0 flex items-center text-white justify-center  px-1 text-sm sm:text-lg">
            {{ (worker.health + worker.itemHealth + worker.skillHealth + worker.collectionHealth)- worker.hit }}/{{ worker.health + worker.itemHealth + worker.skillHealth + worker.collectionHealth }}
        </div>
      </div>
      <div class="grid grid-cols-3 gap-1 font-bold">
        <div class="flex justify-center space-x-1 bg-red-800 p-1.5 sm:p-2 rounded items-center">
          <div><img src="../../assets/images/sword.png" class="w-5 sm:w-8"></div>
          <div class="text-sm sm:text-lg">{{ worker.attack + worker.itemAttack + worker.skillAttack + worker.collectionAttack }}</div>
        </div>
        <div class="flex justify-center space-x-1 bg-blue-800 p-1.5 px-2 sm:p-2 rounded items-center">
          <div><img src="../../assets/images/shield.png" class="w-5 sm:w-8"></div>
          <div class="text-sm sm:text-lg">{{ worker.defend + worker.itemDefend + worker.skillDefend + worker.collectionDefend }}</div>
        </div>
        <div class="flex justify-center space-x-1 bg-amber-900 p-1.5 px-2 sm:p-2 rounded items-center">
          <div><img src="../../assets/images/target.png" class="w-5 sm:w-8"></div>
          <div class="text-sm sm:text-lg">{{ worker.accuracy + worker.itemAccuracy + worker.skillAccuracy + + worker.collectionAccuracy }}</div>
        </div>
      </div>
      <div class="flex justify-center items-center space-x-1 text-gray-300 bg-gray-800  p-1 sm:p-3 rounded">
        <div >เหรียญเงินที่หาได้วันนี้</div>
        <div class="font-bold">{{ worker.silverPrizeDay }}</div>
        <div><img src="../../assets/images/coin.png" class="w-5"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { projectFirestore } from '@/firebase/config'
import { ref } from 'vue'
export default {
    props: ['id'],
    setup(props) {
      const worker = ref({})
      const workerVip = ref()
      projectFirestore.collection('workers').doc(props.id).onSnapshot(workerDoc =>{
        worker.value = workerDoc.data()
        workerVip.value = workerDoc.data().vip.toDate()
      })

      return { worker,workerVip }
    },
}
</script>