<template>
    <ModalOpenWOrkerBoxWithSilver v-if="toggleModal === 'ModalOpenWOrkerBoxWithSilver'"  @cancelModal="toggleModal = ''" />
    <ModalCreateWorker v-if="toggleModal === 'ModalCreateWorker'"  @cancelModal="toggleModal = ''" />
    <ModalPetBox v-if="toggleModal === 'ModalPetBox'"  @cancelModal="toggleModal = ''"  />
    <ModalUpgradeItem v-if="toggleModal === 'ModalUpgradeItem'"  @cancelModal="toggleModal = ''" />
    <ModalDisassemble v-if="toggleModal === 'ModalDisassemble'" @cancelModal="toggleModal = ''"  />
    <ModalShop v-if="toggleModal === 'ModalShop'" @cancelModal="toggleModal = ''"  />
    <ModalAdventureShop v-if="toggleModal === 'ModalAdventureShop'" @cancelModal="toggleModal = ''"  />
    <ModalExchangeAnimalFood v-if="toggleModal === 'ModalExchangeAnimalFood'" @cancelModal="toggleModal = ''" />
    <ModalBuyHorse v-if="toggleModal === 'ModalBuyHorse'" @cancelModal="toggleModal = ''"  />
    <div class="p-1 pb-24 mb-auto h-full text-white  overflow-auto" id="style-1">
      <div class=" p-1 rounded grid grid-cols-3 sm:grid-cols-3 items-center gap-1 ">
        <div @click="toggleModal = 'ModalOpenWOrkerBoxWithSilver'" class="p-4 bg-gray-900 hover:bg-opacity-80 text-xs sm:text-sm text-center font-bold duration-300  rounded-lg  cursor-pointer">
          <div class="flex items-center justify-center"><img src="../assets/images/treasure.png" class="w-40"></div>
          <div class="flex justify-center items-center space-x-1  font-bold text-xs sm:text-sm">
            <div class="font-bold">3,000</div>
            <div><img src="../assets/images/coin.png" class="w-4 sm:w-5"></div>
          </div>
          <div>กล่องคนงาน</div>
        </div>
        <div @click="toggleModal = 'ModalCreateWorker'" class="p-4 bg-gray-900 hover:bg-opacity-80 text-xs sm:text-sm text-center font-bold duration-300  rounded-lg  cursor-pointer">
          <div class="flex items-center justify-center"><img src="../assets/images/treasure.png" class="w-40"></div>
          <div class="flex justify-center items-center space-x-1 ">
            <div class="font-bold">1</div>
            <div><img src="../assets/images/key.png" class="w-4 sm:w-5"></div>
          </div>
          <div>กล่องคนงาน</div>
        </div>
        <div @click="toggleModal = 'ModalPetBox'" class="p-4 bg-gray-900 hover:bg-opacity-80 text-xs sm:text-sm text-center font-bold duration-300  rounded-lg  cursor-pointer">
          <div class="flex items-center justify-center"><img src="../assets/images/treasure.png" class="w-40"></div>
          <div class="flex justify-center items-center space-x-1 text-xs sm:text-sm font-bold">
            <div class="font-bold">1</div>
            <div><img src="../assets/images/key.png" class="w-4 sm:w-5"></div>
          </div>
          <div>กล่องสุนัข</div>
        </div>
        <div @click="toggleModal = 'ModalShop'" class="p-4 bg-gray-900 hover:bg-opacity-80 duration-300  rounded-lg  cursor-pointer">
          <div class="flex items-center justify-center"><img src="../assets/images/saloon.png" class="w-40"></div>
          <div class="flex justify-center items-center space-x-1 text-xs sm:text-sm font-bold">
            <div>ร้านค้าอุปกรณ์เสริม</div>
          </div>
        </div>
        <div @click="toggleModal = 'ModalAdventureShop'" class="p-4 bg-gray-900 hover:bg-opacity-80 duration-300  rounded-lg  cursor-pointer">
          <div class="flex items-center justify-center"><img src="../assets/images/carriage.png" class="w-40"></div>
          <div class="flex justify-center items-center space-x-1 text-xs sm:text-sm font-bold">
            <div>ร้านค้านักผจญภัย</div>
          </div>
        </div>
        <div @click="toggleModal = 'ModalUpgradeItem'" class="p-4 bg-gray-900 hover:bg-opacity-80 duration-300  rounded-lg  cursor-pointer">
          <div class="flex items-center justify-center"><img src="../assets/images/blacksmith.png" class="w-40"></div>
          <div class="flex justify-center items-center space-x-1 text-xs sm:text-sm font-bold">
            <div>อัพเกรดไอเทม</div>
          </div>
        </div>
        <div @click="toggleModal = 'ModalDisassemble'" class="p-4 bg-gray-900 hover:bg-opacity-80 duration-300  rounded-lg  cursor-pointer">
          <div class="flex items-center justify-center"><img src="../assets/thief/jacob.png" class="w-40 bg-amber-500 rounded-full p-3"></div>
          <div class="flex justify-center items-center space-x-1 text-xs sm:text-sm font-bold">
            <div>แยกชิ้นส่วน</div>
          </div>
        </div>
        <!-- <div @click="toggleModal = 'ModalBuyHorse'" class="p-4 bg-gray-900 hover:bg-opacity-80 duration-300  rounded-lg  cursor-pointer">
          <div class="flex items-center justify-center"><img src="../assets/images/horse.png" class="w-40 "></div>
          <div class="flex justify-center items-center space-x-1 text-xs sm:text-sm font-bold">
            <div>ร้านขายม้า</div>
          </div>
        </div>
        <div @click="toggleModal = 'ModalExchangeAnimalFood'" class="p-4 bg-gray-900 hover:bg-opacity-80 duration-300  rounded-lg  cursor-pointer">
          <div class="flex items-center justify-center"><img src="../assets/images/haybale.png" class="w-40 "></div>
          <div class="flex justify-center items-center space-x-1 text-xs sm:text-sm font-bold">
            <div>แลกอาหารสัตว์</div>
          </div>
        </div> -->
      </div>
    </div>
  </template>
  <script>
import { ref } from 'vue'
  import ModalWeaponBox from '../components/shop/ModalWeaponBox.vue'
  import ModalPetBox from '../components/shop/ModalPetBox.vue'
  import ModalCreateWorker from '../components/ModalCreateWorker.vue'
  import ModalUpgradeItem from '../components/shop/ModalUpgradeItem.vue'
  import ModalDisassemble from '../components/shop/ModalDisassemble.vue'
  import ModalOpenWOrkerBoxWithSilver from '../components/shop/ModalOpenWorkerBoxWithSilver.vue'
  import ModalShop from '../components/shop/ModalShop.vue'
  import ModalAdventureShop from '../components/shop/ModalAdventureShop.vue'
  import ModalExchangeAnimalFood from '../components/shop/ModalExchangeAnimalFood.vue'
  import ModalBuyHorse from '../components/shop/ModalBuyHorse.vue'
  export default {
    components: { ModalBuyHorse, ModalExchangeAnimalFood,ModalAdventureShop,ModalOpenWOrkerBoxWithSilver,ModalShop,ModalUpgradeItem,ModalCreateWorker, ModalPetBox,ModalWeaponBox,ModalDisassemble },
    setup() {
      const toggleModal = ref('')

      return { toggleModal }
    }
  }
  </script>