<template>
  <ModalFreeWorker v-if="toggleModal === 'FreeWorker'" @cancelModal="toggleModal =''" />
  <div class="p-1 sm:p-4 sm:pb-20 pb-16 mb-auto h-full text-white overflow-auto" id="style-1">
    <div class="space-y-1">
      <section class="bg-gray-900 rounded">
        <div class="grid max-w-screen-xl px-4 py-2 mx-auto">
            <div class="mr-auto place-self-center w-full space-y-1">
                <div class="text-xl font-bold text-center text-lime-500">ผู้เล่นใหม่เพียงแค่สมัครสมาชิกรับฟรี 1 คนงาน !!</div>
                <a href="https://discord.gg/tdD5cx75Un" target="_blank" class="flex justify-center">
                  <div class="flex items-center space-x-2 justify-center p-2 sm:p-3 bg-indigo-900 hover:bg-indigo-800 duration-300 w-full text-center font-bold text-xl sm:text-3xl rounded-lg cursor-pointer">
                    <div class="text-center">เข้าดิสคอร์ดเพื่อพูดคุยซื้อขาย</div>
                    <div><img src="../assets/images/discord.png" class="w-7 sm:w-10"></div>
                  </div>
                </a>
                
            </div>
        </div>
    </section>
    <section v-if="user" class="bg-gray-900 rounded">
        <div class="grid max-w-screen-xl px-4 py-2 mx-auto lg:gap-8 xl:gap-0 lg:py-2 lg:grid-cols-12">
            <div class="mr-auto place-self-center lg:col-span-7">
                <h1 class="max-w-2xl mb-4 text-2xl text-yellow-400 font-extrabold tracking-tight leading-none md:text-3xl  ">ซื้อ 2 คีย์VIP 50 บาท รับฟรี จวน 5 ดาว</h1>
                <p class="max-w-2xl mb-6 font-light text-gray-300 lg:mb-8 md:text-lg lg:text-xl ">สามารถซื้อได้ที่ดิสคอร์ด Workerkey</p>
            </div>
            <div class=" lg:mt-0 lg:col-span-5 lg:flex mt-3">
              <div class="grid grid-cols-3 items-center">
                <div class="flex justify-center col-span-3 ">
                  <div class="space-y-1 bg-gray-800 border border-gray-700 p-2 rounded w-full">
                    <div class="flex items-center space-x-1 justify-center"><img src="../assets/worker/juan.png" class="w-24 h-24"></div>
                    <div class="flex justify-center items-center space-x-1 ">
                      <div class="flex items-center space-x-1 px-1 rounded border border-gray-700">
                        <div><img src="../assets/images/star.png" class="w-3"></div>
                        <div class="font-bold">5</div>
                      </div>
                      <div class="text-center font-bold text-sky-500">จวน</div>
                    </div>
                    <div class="flex justify-center items-center space-x-1 font-bold">
                      <div class="flex items-center space-x-1 bg-pink-900 p-0.5 rounded">
                        <div>100</div>
                        <div><img src="../assets/images/hearth.png" class="w-5"></div>
                      </div>
                      <div class="flex items-center space-x-1 bg-red-700 p-0.5 rounded">
                        <div>14</div>
                        <div><img src="../assets/images/sword.png" class="w-5"></div>
                      </div>
                      <div class="flex items-center space-x-1 bg-blue-700 p-0.5 rounded">
                        <div>0</div>
                        <div><img src="../assets/images/shield.png" class="w-5"></div>
                      </div>
                      <div class="flex items-center space-x-1 bg-yellow-900 p-0.5 rounded">
                        <div>0</div>
                        <div><img src="../assets/images/target.png" class="w-5"></div>
                      </div>
                 
                    </div>
                  </div>
                </div>
              </div>
            </div>                
        </div>
    </section>
      <section class="bg-gray-900 rounded">
        <div class="grid max-w-screen-xl px-4 py-2 mx-auto lg:gap-8 xl:gap-0 lg:py-2 lg:grid-cols-12">
            <div class="mr-auto place-self-center lg:col-span-7">
                <h1 class="max-w-2xl mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl  ">พัฒนาคนงานของคุณให้เก่งขึ้น </h1>
                <p class="max-w-2xl mb-6 font-light text-gray-300 lg:mb-8 md:text-lg lg:text-xl ">มีคนงาน 11 แบบให้คุณได้สะสม !! เกมที่คุณไม่ต้องเล่นเองเพียงแค่คุณมีคนงาน คนงานของคุณจะทำงานอัตโนมัติโดยที่คุณไม่ต้องเปิดจอค้าง</p>
            </div>
            <div class=" lg:mt-0 lg:col-span-5 lg:flex mt-3">
              <div class="grid grid-cols-6 sm:grid-cols-5">
                <div><img src="../assets/worker/peter.png" class="w-20"></div>
                <div><img src="../assets/worker/pierre.png" class="w-20"></div>
                <div><img src="../assets/worker/mark.png" class="w-20"></div>
                <div><img src="../assets/worker/nathan.png" class="w-20"></div>
                <div><img src="../assets/worker/juan.png" class="w-20"></div>
                <div><img src="../assets/worker/dakota.png" class="w-20"></div>
                <div><img src="../assets/worker/kofi.png" class="w-20"></div>
                <div><img src="../assets/worker/wong.png" class="w-20"></div>
                <div><img src="../assets/worker/houston.png" class="w-20"></div>
                <div><img src="../assets/worker/smith.gif" class="w-20"></div>
              </div>
            </div>                
        </div>
    </section>
    <section class="bg-gray-900 rounded">
        <div class="grid max-w-screen-xl px-4 py-2 mx-auto lg:gap-8 xl:gap-0 lg:py-2 lg:grid-cols-12">
            <div class="mr-auto place-self-center lg:col-span-7">
                <h1 class="max-w-2xl mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl  ">ผจญภัยในดินแดนดิบเถื่อน </h1>
                <p class="max-w-2xl mb-6 font-light text-gray-300 lg:mb-8 md:text-lg lg:text-xl ">ส่งคนงานของคุณออกไปล่าโจร ที่กำลังก่อกวนชาวเมือง ค้นหาแผนที่ลับที่ไม่มีใครเคยไปมาก่อน</p>
            </div>
            <div class=" lg:mt-0 lg:col-span-5 lg:flex mt-3">
              <div class="grid grid-cols-6 sm:grid-cols-5">
                <div><img src="../assets/thief/william.png" class="w-20"></div>
                <div><img src="../assets/thief/wicky.png" class="w-20"></div>
                <div><img src="../assets/thief/woody.png" class="w-20"></div>
                <div><img src="../assets/thief/mason.png" class="w-20"></div>
                <div><img src="../assets/thief/moon.png" class="w-20"></div>
                <div><img src="../assets/thief/lucas.png" class="w-20"></div>
                <div><img src="../assets/thief/benjamin.png" class="w-20"></div>
                <div><img src="../assets/thief/jacob.png" class="w-20"></div>
              </div>
            </div>                
        </div>
    </section>
    <section class="bg-gray-900 rounded">
        <div class="grid max-w-screen-xl px-4 py-2 mx-auto lg:gap-8 xl:gap-0 lg:py-2 lg:grid-cols-12">
            <div class="mr-auto place-self-center lg:col-span-7">
                <h1 class="max-w-2xl mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl  ">แลกเปลี่ยนไอเทมได้อย่างอิสระ </h1>
                <p class="max-w-2xl mb-6 font-light text-gray-300 lg:mb-8 md:text-lg lg:text-xl ">เมื่อคนงานของคุณสังหารโจรสำเร็จคุณจะได้รับเหรียญ และมีโอกาสได้รับไอเทม คุณสามารถนำไอเทมไปแลกเปลี่ยนกับคนอื่นได้หรือคุณจะเก็บไอเทมเหล่านั้นไว้อัพเกรดคนงานของคุณเอง</p>
            </div>
            <div class=" lg:mt-0 lg:col-span-5 lg:flex mt-3">
              <div class="grid grid-cols-6 sm:grid-cols-5">
                <div><img src="../assets/item/weapon/fork.png" class="w-20"></div>
                <div><img src="../assets/item/weapon/knife.png" class="w-20"></div>
                <div><img src="../assets/item/weapon/axe.png" class="w-20"></div>
                <div><img src="../assets/item/weapon/sword.png" class="w-20"></div>
                <div><img src="../assets/item/weapon/Whip.png" class="w-20"></div>
                <div><img src="../assets/images/head.png" class="w-20"></div>
                <div><img src="../assets/images/body.png" class="w-20"></div>
                <div><img src="../assets/images/foots.png" class="w-20"></div>
                <div><img src="../assets/item/pet/bordercollie.png" class="w-20"></div>
                <div><img src="../assets/item/pet/germanshepherd.png" class="w-20"></div>
              </div>
            </div>                
        </div>
    </section>
    
    
    <section class="bg-gray-900 rounded">
        <div class="grid max-w-screen-xl px-4 py-2 mx-auto lg:gap-8 xl:gap-0 lg:py-2 lg:grid-cols-12">
            <div class="mr-auto place-self-center lg:col-span-12">
                <h1 class=" mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-3xl text-center">Social Media</h1>
                <div class="flex items-center space-x-2">
                  <a href="https://www.facebook.com/workerkeycom" target="_blank"><img src="../assets/images/facebook.png" class="w-16 opacity-80 hover:opacity-100 cursor-pointer"></a>
                  <a href="https://www.youtube.com/watch?v=HUXxO73DvYc" target="_blank"><img src="../assets/images/youtube.png" class="w-16 opacity-80 hover:opacity-100 cursor-pointer"></a>
                  <a href="https://discord.gg/tdD5cx75Un" target="_blank"><img src="../assets/images/discord.png" class="w-16 opacity-80 hover:opacity-100 cursor-pointer"></a>
                </div>
            </div>
        </div>
    </section>
    </div>
  </div>
</template>

<script>
import { projectFirestore } from '@/firebase/config';
import { ref, computed } from 'vue';
import ModalFreeWorker from '../components/ModalFreeWorker.vue';
import getUser from '@/composables/getUser';

export default {
  components: { ModalFreeWorker },
  setup() {
  const { user } = getUser()
    const toggleModal = ref('');
    const stat = ref({});

    // ดึงข้อมูลจาก Firestore
    projectFirestore.collection('serverPublic').doc('stat').get().then(snapshot => {
      stat.value = snapshot.data();
    });



    return { stat, toggleModal,user };
  },
};
</script>



<style scoped>
#style-1 {
  /* Custom scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: #2d2d2d #1a1a1a;
}

#style-1::-webkit-scrollbar {
  width: 8px;
}

#style-1::-webkit-scrollbar-track {
  background: #1a1a1a;
}

#style-1::-webkit-scrollbar-thumb {
  background-color: #2d2d2d;
  border-radius: 10px;
}
</style>
