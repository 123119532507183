<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-full pb-16 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div v-if="itemLength >= 1">
            <div class="text-center">เลือกไอเทมที่ต้องการถอด</div>
            <div   :class="[items.length >= 4 ? 'sm:h-44 44 overflow-auto' :'h-auto']" class="grid grid-cols-1 h-60 overflow-auto" id="style-1">
              <div v-for="item in items" :key="item">
                <div @click="itemSelected = item" :class="[item.id === itemSelected.id ? 'bg-indigo-500' : 'bg-gray-900 hover:bg-indigo-500 cursor-pointer duration-300']" class="flex space-x-1 justify-between items-center  border-b border-gray-700">
                  <div class="flex items-center space-x-1 ">
                    <div class="flex justify-center"><img :src="item.img" class="w-8 sm:w-10"></div>
                    <div class="flex items-center space-x-1">
                      <div class="flex items-center space-x-1 border px-1 rounded  border-gray-600">
                        <div><img src="../../assets/images/star.png" class="w-3 sm:w-4"></div>
                        <div class="font-bold text-xs sm:text-sm">{{item.star}}</div>
                      </div>
                      <div class="font-bold text-xs sm:text-sm">{{ item.name }}</div>
                    </div>
                  </div>
                  <div class="flex items-center space-x-1 font-bold px-1 text-xs sm:text-sm">
                    <div>ค่าพลัง {{ item.star * 5 }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="itemLength  < 1">
            <div class="flex justify-center items-center h-28 border-2 border-dashed border-opacity-50">
              <div class="text-2xl font-bold opacity-50">ไม่พบไอเทม</div>
            </div>
          </div>
          <div>
            <div v-if="report" @click="report =''" :class="[report === 'ถอดไอเทมสำเร็จแล้ว' ? 'text-green-500' : 'text-red-500']" class="text-center  text-sm">{{ report }}</div>
          </div>
          <div class="mt-2">
            <button @click="handleRemoveItemFromCharacter" v-if="itemSelected.id && !isPending" type="button" class="w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">ถอดไอเทม</button>
            <button v-if="!itemSelected.id && !isPending && itemLength >= 1" type="button" class="w-full justify-center rounded bg-red-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 duration-300">กรุณาเลือกไอเทม</button>
            <button v-if="!isPending" @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
            <button v-if="isPending"  class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm animate-pulse  duration-300 cursor-pointer">LOADING...</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
<script>
import { ref, onUnmounted } from 'vue'
import { projectFirestore, projectFunctions } from '@/firebase/config'
import getUser from '@/composables/getUser'

export default {
  props: ['id'],
  setup(props, context) {
    const { user } = getUser()
    const cancelModal = (e) => { context.emit('cancelModal') }
    const items = ref([])
    const itemLength = ref(0)

    // เก็บ unsubscribe function ไว้
    const unsubscribeCharacterPrivate = projectFirestore
      .collection('characterPrivate')
      .doc(user.value.uid)
      .onSnapshot(characterPrivateDoc => {
        itemLength.value = characterPrivateDoc.data().items.length
        items.value = [] // เคลียร์รายการก่อนเติมข้อมูลใหม่
        characterPrivateDoc.data().items.forEach(itemId => {
          console.log(itemId)
          projectFirestore.collection('items').doc(itemId).get().then(itemDoc => {
            let itemData = itemDoc.data()
            itemData.id = itemDoc.id // เพิ่ม id ของเอกสารเข้าไปในข้อมูล item
            items.value.push(itemData)
          })
        })
      })

    // Unsubscribe เมื่อ component ถูกทำลาย
    onUnmounted(() => {
      unsubscribeCharacterPrivate()
    })

    const itemSelected = ref({ id: '' })
    const isPending = ref(false)
    const report = ref('')

    const handleRemoveItemFromCharacter = (e) => {
      const removeItemFromCharacter = projectFunctions.httpsCallable('removeItemFromCharacter')
      isPending.value = true
      removeItemFromCharacter({ itemId: itemSelected.value.id })
        .then(result => {
          isPending.value = false
          report.value = result.data
          itemSelected.value = { id: '' }
        })
        .catch(err => {
          isPending.value = false
          itemSelected.value = { id: '' }
        })
    }

    return { itemLength, report, isPending, handleRemoveItemFromCharacter, items, cancelModal, itemSelected }
  }
}
</script>
