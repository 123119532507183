<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-full pb-16 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div v-if="!itemSelected.id">
            <div v-if="items.length >= 1">
              <div class="text-center font-bold mb-1">เลือกอุปกรณ์ที่คุณต้องการอัพเกรด</div>
              <div :class="[items.length >= 6 ? 'h-60' : 'h-auto']" class="grid gird-cols-1 h-60 overflow-auto" id="style-1">
                <div v-for="item in items" :key="item">
                  <div @click="handleSelectItem(item.id,item)" class="flex items-center spaec-x-1 justify-between border-b border-gray-700 hover:bg-gray-900 cursor-pointer duration-300">
                    <div class="flex items-center space-x-1">
                      <div><img :src="item.img" class="w-8 sm:w-10"></div>
                      <div class="flex items-center space-x-1 font-bold">
                        <div class="flex items-center space-x-1 border rounded border-gray-600 px-0.5">
                          <div><img src="../../assets/images/star.png" class="w-3"></div>
                          <div>{{ item.star }}</div>
                        </div>
                        <div class="text-xs sm:text-sm">{{ item.name }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="items < 1" class="h-20 flex justify-center items-center">
              <div class="text-xl font-bold opacity-50">ไม่พบไอเทมที่สามารถอัพเกรดได้</div>
            </div>
          </div>
          <div v-if="itemSelected.id">
            <div class="bg-red-200 font-bold border-red-500 border rounded text-red-500 text-sm p-1 text-center">
              <div>คำเตือนเมื่ออัพเกรดไม่สำเร็จไอเทมจะกลายเป็นขยะทันที</div>
            </div>
            <div class="flex items-center">
              <div class="w-1/2">
                <div class="flex justify-center items-center mb-1"><img :src="itemSelected.img" class="w-24"></div>
                <div class="flex justify-center items-center space-x-1">
                  <div class="flex items-center justify-center space-x-1 px-2 border rounded border-gray-700">
                    <div><img src="../../assets/images/star.png" class="w-3"></div>
                    <div class="font-bold text-xs sm:text-sm">{{ itemSelected.star }}</div>
                  </div>
                  <div :class="itemSelected.textColor"  class="text-center font-bold text-xs sm:text-sm">{{ itemSelected.name }}</div>
                </div>
                <div class="flex justify-center items-center space-x-1 text-xs sm:text-sm">
                  <div v-if="itemSelected.health >= 1" class="flex items-center space-x-1 font-bold">
                    <div>{{ itemSelected.health }}</div>
                    <div><img src="../../assets/images/hearth.png" class="w-4"></div>
                  </div>
                  <div v-if="itemSelected.attack >= 1" class="flex items-center space-x-1 font-bold">
                    <div>{{ itemSelected.attack }}</div>
                    <div><img src="../../assets/images/sword.png" class="w-4"></div>
                  </div>
                  <div v-if="itemSelected.defend >= 1" class="flex items-center space-x-1 font-bold">
                    <div>{{ itemSelected.defend }}</div>
                    <div><img src="../../assets/images/shield.png" class="w-4"></div>
                  </div>
                  <div v-if="itemSelected.accuracy >= 1" class="flex items-center space-x-1 font-bold">
                    <div>{{ itemSelected.accuracy }}</div>
                    <div><img src="../../assets/images/target.png" class="w-4"></div>
                  </div>
                </div>
              </div>
              <div>
                <div><img src="../../assets/images/greenrightarrow.png" class="w-10 animate-pulse"></div>
              </div>
              <div class="w-1/2">
                
                <div class="flex justify-center items-center mb-1"><img :src="newItem.img" class="w-24"></div>
                <div class="flex justify-center items-center space-x-1">
                  <div class="flex items-center justify-center space-x-1 px-2 border rounded border-gray-700">
                    <div><img src="../../assets/images/star.png" class="w-3"></div>
                    <div class="font-bold text-xs sm:text-sm">{{ newItem.star }}</div>
                  </div>
                  <div :class="newItem.textColor" class="text-center font-bold text-xs sm:text-sm">{{ newItem.name }}</div>
                </div>
                <div class="flex justify-center items-center space-x-1 text-xs sm:text-sm">
                  <div v-if="newItem.health >= 1" class="flex items-center space-x-1 font-bold">
                    <div>{{ newItem.health }}</div>
                    <div><img src="../../assets/images/hearth.png" class="w-4"></div>
                  </div>
                  <div v-if="newItem.attack >= 1" class="flex items-center space-x-1 font-bold">
                    <div>{{ newItem.attack }}</div>
                    <div><img src="../../assets/images/sword.png" class="w-4"></div>
                  </div>
                  <div v-if="newItem.defend >= 1" class="flex items-center space-x-1 font-bold">
                    <div>{{ newItem.defend }}</div>
                    <div><img src="../../assets/images/shield.png" class="w-4"></div>
                  </div>
                  <div v-if="newItem.accuracy >= 1" class="flex items-center space-x-1 font-bold">
                    <div>{{ newItem.accuracy }}</div>
                    <div><img src="../../assets/images/target.png" class="w-4"></div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <div @click="report = ''" v-if="report" :class="[report === 'คุณอัพเกรดสำเร็จ' ? 'text-green-500' : 'text-red-500']" class="text-center font-bold text-sm hover:text-red-600 cursor-pointer">{{ report }}</div>
          <div class="space-y-1 mt-2">
            <button v-if="!isPending && !itemSelected.id && items.length >= 1" type="button" class="w-full justify-center rounded bg-red-900 px-3 py-2 text-sm font-semibold text-white shadow-sm  cursor-not-allowed">กรุณาเลือกไอเทม</button>
            <button v-if="!isPending && itemSelected.id && newItem.name !== newItem.oldItemName" @click="handleUpgradeItem" type="button" class="w-full justify-center rounded bg-indigo-900 hover:bg-indigo-800 px-3 py-2 text-sm font-semibold text-white shadow-sm duration-300">อัพเกรดไอเทม</button>
            <button v-if="itemSelected.id && !isPending" @click=" itemSelected = {id: ''}" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ย้อนกลับ</button>
            <button v-if="isPending"  type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300 cursor-not-allowed">Loading...</button>
            <button v-if="!itemSelected.id && !isPending" @click="cancelModal" type="button" class=" w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
import { projectFirestore, projectFunctions } from '@/firebase/config'
import getUser from '@/composables/getUser'
  export default {
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}
        const { user } = getUser()
        const items = ref([])
        projectFirestore.collection('items')
    .where('ownerUid', '==', user.value.uid)
    .where('used', '==', false)
    .orderBy('star', 'asc')
    .orderBy('type')
    .onSnapshot(snapshot => {
        // Reset items.value to avoid duplicate entries
        items.value = [];
        
        // Filter the results client-side to exclude 'trash' type
        snapshot.forEach(itemDoc => {
            let itemData = itemDoc.data();
            if (itemData.type !== 'horse' && itemData.type !== 'trash' && itemData.type !== 'collection' && itemData.type !== 'map' && itemData.type !== 'animalfood') {
                itemData.id = itemDoc.id; // Add the document id to the item data
                items.value.push(itemData);
            }
        });
    });



        const isPending = ref(false)
        const report = ref('')
        const itemSelected = ref({id:''})
        const newItem = ref({})
        const itemId = ref('')

        const upgradeItems = [
         {oldItemName: 'ท่อนไม้',name:'ท่อนไม้',health: 0, attack: 10, defend: 0, accuracy: 0,star: 0, type: 'weapon', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fstick.png?alt=media&token=f689ed10-e41d-44bc-ab00-c1dc0522063a'},
         {oldItemName: 'หมวกเน่า',name:'หมวกเน่า',health: 50, attack: 0, defend: 0, accuracy: 0,star: 0, type: 'head', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
         {oldItemName: 'เสื้อเน่า',name:'เสื้อเน่า',health: 50, attack: 0, defend: 1, accuracy: 0,star: 0, type: 'body', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
         {oldItemName: 'กางเกงเน่า',name:'กางเกงเน่า',health: 50, attack: 0, defend: 0, accuracy: 0,star: 0, type: 'bottom', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
         {oldItemName: 'รองเท้าเน่า',name:'รองเท้าเน่า',health: 50, attack: 0, defend: 0, accuracy: 0,star: 0, type: 'foots', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},

          {oldItemName: 'ส้อม',name: 'มีดหั่นผัก',health: 0,attack: 20,defend: 0,accuracy: 4,star: 2,type: 'weapon',textColor: 'text-amber-500',img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fbreadknife.png?alt=media&token=37625740-6cfc-4e79-80dc-ad00483945e9'},
          {oldItemName: 'มีดหั่นผัก',name:'พลั่ว',health: 0, attack: 25, defend: 0, accuracy: 8,star: 3, type: 'weapon', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fpitchfork.png?alt=media&token=87b33cf6-979f-4763-a63a-ad0b539a54d1'},
          {oldItemName: 'พลั่ว',name:'มีด',health: 0, attack: 30, defend: 0, accuracy: 6,star: 4, type: 'weapon', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fknife.png?alt=media&token=e473e019-a551-443b-a26b-c4aa2705fce6'},
          {oldItemName: 'มีด',name:'อีโต้',health: 0, attack: 35, defend: 0, accuracy: 7,star: 5, type: 'weapon', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fcleaver.png?alt=media&token=4fee1733-6261-4639-9e52-5e97a79b84c5'},
          {oldItemName: 'อีโต้',name:'ขวาน',health: 0, attack: 40, defend: 0, accuracy: 8,star: 6, type: 'weapon', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Faxe.png?alt=media&token=e1c886d4-0553-41a1-9f6d-f7f2c1d16df3'},
          {oldItemName: 'ขวาน',name:'ดาบโจรสลัด',health: 0, attack: 45, defend: 0, accuracy: 9,star: 7, type: 'weapon', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fpiratesword.png?alt=media&token=79009398-9469-40d0-bba2-a2c0261cc251'},
          {oldItemName: 'ดาบโจรสลัด',name:'ดาบเหล็กกล้า',health: 0, attack: 50, defend: 0, accuracy: 10,star: 8, type: 'weapon', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fsword.png?alt=media&token=61028d31-1ae6-4eed-9294-f00ad0971dd9'},
          {oldItemName: 'ดาบเหล็กกล้า',name:'ดาบเหล็กกล้า',health: 0, attack: 50, defend: 0, accuracy: 10,star: 8, type: 'weapon', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fsword.png?alt=media&token=61028d31-1ae6-4eed-9294-f00ad0971dd9'},
          {oldItemName: 'ดาบเหล็กกล้า',name:'หอกชนพื้นเมือง',health: 0, attack: 55, defend: 0, accuracy: 10,star: 9, type: 'weapon', textColor: 'text-violet-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fspear.png?alt=media&token=b9bb1d3e-9cf6-444b-a85f-988e31cef53b'},
          {oldItemName: 'หอกชนพื้นเมือง',name:'หอกชนพื้นเมือง',health: 0, attack: 55, defend: 0, accuracy: 10,star: 9, type: 'weapon', textColor: 'text-violet-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fspear.png?alt=media&token=b9bb1d3e-9cf6-444b-a85f-988e31cef53b'},

          {oldItemName: 'ผ้าพันคอขาด',name:'ผ้าพันคอคนจร',health: 0, attack: 0, defend: 0, accuracy: 2,star: 2, type: 'neck', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
          {oldItemName: 'ผ้าพันคอคนจร',name:'ผ้าพันคอชาวไร่',health: 0, attack: 0, defend: 0, accuracy: 3,star: 3, type: 'neck', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
          {oldItemName: 'ผ้าพันคอชาวไร่',name:'ผ้าพันคอนักเลง',health: 0, attack: 0, defend: 0, accuracy: 4,star: 4, type: 'neck', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
          {oldItemName: 'ผ้าพันคอนักเลง',name:'ผ้าพันคอโจร',health: 0, attack: 0, defend: 0, accuracy: 5,star: 5, type: 'neck', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
          {oldItemName: 'ผ้าพันคอโจร',name:'ผ้าพันคอพ่อค้า',health: 0, attack: 0, defend: 0, accuracy: 6,star: 6, type: 'neck', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
          {oldItemName: 'ผ้าพันคอพ่อค้า',name:'ผ้าพันคอโจรสลัด',health: 0, attack: 0, defend: 0, accuracy: 7,star: 7, type: 'neck', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
          {oldItemName: 'ผ้าพันคอโจรสลัด',name:'ผ้าพันคอคนรวย',health: 0, attack: 0, defend: 0, accuracy: 8,star: 8, type: 'neck', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
          {oldItemName: 'ผ้าพันคอคนรวย',name:'ผ้าพันคอคนรวย',health: 0, attack: 0, defend: 0, accuracy: 8,star: 8, type: 'neck', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
          {oldItemName: 'เสื้อคุมขาด',name:'เสื้อคุมคนจร',health: 0, attack: 0, defend: 0, accuracy: 2,star: 2, type: 'cape', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
          {oldItemName: 'เสื้อคุมคนจร',name:'เสื้อคุมชาวไร่',health: 0, attack: 0, defend: 0, accuracy: 3,star: 3, type: 'cape', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
          {oldItemName: 'เสื้อคุมชาวไร่',name:'เสื้อคุมนักเลง',health: 0, attack: 0, defend: 0, accuracy: 4,star: 4, type: 'cape', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
          {oldItemName: 'เสื้อคุมนักเลง',name:'เสื้อคุมโจร',health: 0, attack: 0, defend: 0, accuracy: 5,star: 5, type: 'cape', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
          {oldItemName: 'เสื้อคุมโจร',name:'เสื้อคุมพ่อค้า',health: 0, attack: 0, defend: 0, accuracy: 6,star: 6, type: 'cape', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
          {oldItemName: 'เสื้อคุมพ่อค้า',name:'เสื้อคุมโจรสลัด',health: 0, attack: 0, defend: 0, accuracy: 7,star: 7, type: 'cape', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
          {oldItemName: 'เสื้อคุมโจรสลัด',name:'เสื้อคุมคนรวย',health: 0, attack: 0, defend: 0, accuracy: 8,star: 8, type: 'cape', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
          {oldItemName: 'เสื้อคุมคนรวย',name:'เสื้อคุมคนรวย',health: 0, attack: 0, defend: 0, accuracy: 8,star: 8, type: 'cape', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
          {oldItemName: 'หมวกขาด',name:'หมวกคนจร',health: 80, attack: 0, defend: 2, accuracy: 0,star: 2, type: 'head', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
          {oldItemName: 'หมวกคนจร',name:'หมวกชาวไร่',health: 110, attack: 0, defend: 3, accuracy: 0,star: 3, type: 'head', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
          {oldItemName: 'หมวกชาวไร่',name:'หมวกนักเลง',health: 140, attack: 0, defend: 4, accuracy: 0,star: 4, type: 'head', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
          {oldItemName: 'หมวกนักเลง',name:'หมวกโจร',health: 170, attack: 0, defend: 5, accuracy: 0,star: 5, type: 'head', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
          {oldItemName: 'หมวกโจร',name:'หมวกพ่อค้า',health: 200, attack: 0, defend: 6, accuracy: 0,star: 6, type: 'head', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
          {oldItemName: 'หมวกพ่อค้า',name:'หมวกโจรสลัด',health: 230, attack: 0, defend: 7, accuracy: 0,star: 7, type: 'head', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
          {oldItemName: 'หมวกโจรสลัด',name:'หมวกคนรวย',health: 260, attack: 0, defend: 8, accuracy: 0,star: 8, type: 'head', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
          {oldItemName: 'หมวกคนรวย',name:'หมวกคนรวย',health: 260, attack: 0, defend: 8, accuracy: 0,star: 8, type: 'head', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
          {oldItemName: 'เสื้อขาด',name:'เสื้อคนจร',health: 80, attack: 0, defend: 3, accuracy: 0,star: 2, type: 'body', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
          {oldItemName: 'เสื้อคนจร',name:'เสื้อชาวไร่',health: 110, attack: 0, defend: 4, accuracy: 0,star: 3, type: 'body', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
          {oldItemName: 'เสื้อชาวไร่',name:'เสื้อนักเลง',health: 140, attack: 0, defend: 5, accuracy: 0,star: 4, type: 'body', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
          {oldItemName: 'เสื้อนักเลง',name:'เสื้อโจร',health: 170, attack: 0, defend: 6, accuracy: 0,star: 5, type: 'body', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
          {oldItemName: 'เสื้อโจร',name:'เสื้อพ่อค้า',health: 200, attack: 0, defend: 7, accuracy: 0,star: 6, type: 'body', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
          {oldItemName: 'เสื้อพ่อค้า',name:'เสื้อโจรสลัด',health: 230, attack: 0, defend: 8, accuracy: 0,star: 7, type: 'body', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
          {oldItemName: 'เสื้อโจรสลัด',name:'เสื้อคนรวย',health: 260, attack: 0, defend: 9, accuracy: 0,star: 8, type: 'body', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
          {oldItemName: 'เสื้อคนรวย',name:'เสื้อคนรวย',health: 260, attack: 0, defend: 9, accuracy: 0,star: 8, type: 'body', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
          {oldItemName: 'กางเกงขาด',name:'กางเกงคนจร',health: 80, attack: 0, defend: 2, accuracy: 0,star: 2, type: 'bottom', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
          {oldItemName: 'กางเกงคนจร',name:'กางเกงชาวไร่',health: 110, attack: 0, defend: 3, accuracy: 0,star: 3, type: 'bottom', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
          {oldItemName: 'กางเกงชาวไร่',name:'กางเกงนักเลง',health: 140, attack: 0, defend: 4, accuracy: 0,star: 4, type: 'bottom', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
          {oldItemName: 'กางเกงนักเลง',name:'กางเกงโจร',health: 170, attack: 0, defend: 5, accuracy: 0,star: 5, type: 'bottom', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
          {oldItemName: 'กางเกงโจร',name:'กางเกงพ่อค้า',health: 200, attack: 0, defend: 6, accuracy: 0,star: 6, type: 'bottom', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
          {oldItemName: 'กางเกงพ่อค้า',name:'กางเกงโจรสลัด',health: 230, attack: 0, defend: 7, accuracy: 0,star: 7, type: 'bottom', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
          {oldItemName: 'กางเกงโจรสลัด',name:'กางเกงคนรวย',health: 260, attack: 0, defend: 8, accuracy: 0,star: 8, type: 'bottom', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
          {oldItemName: 'กางเกงคนรวย',name:'กางเกงคนรวย',health: 260, attack: 0, defend: 8, accuracy: 0,star: 8, type: 'bottom', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
          {oldItemName: 'รองเท้าขาด',name:'รองเท้าคนจร',health: 80, attack: 0, defend: 2, accuracy: 0,star: 2, type: 'foots', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          {oldItemName: 'รองเท้าคนจร',name:'รองเท้าชาวไร่',health: 110, attack: 0, defend: 3, accuracy: 0,star: 3, type: 'foots', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          {oldItemName: 'รองเท้าชาวไร่',name:'รองเท้านักเลง',health: 140, attack: 0, defend: 4, accuracy: 0,star: 4, type: 'foots', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          {oldItemName: 'รองเท้านักเลง',name:'รองเท้าโจร',health: 170, attack: 0, defend: 5, accuracy: 0,star: 5, type: 'foots', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          {oldItemName: 'รองเท้าโจร',name:'รองเท้าพ่อค้า',health: 200, attack: 0, defend: 6, accuracy: 0,star: 6, type: 'foots', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          {oldItemName: 'รองเท้าพ่อค้า',name:'รองเท้าโจรสลัด',health: 230, attack: 0, defend: 7, accuracy: 0,star: 7, type: 'foots', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          {oldItemName: 'รองเท้าโจรสลัด',name:'รองเท้าคนรวย',health: 260, attack: 0, defend: 8, accuracy: 0,star: 8, type: 'foots', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          {oldItemName: 'รองเท้าคนรวย',name:'รองเท้าคนรวย',health: 260, attack: 0, defend: 8, accuracy: 0,star: 8, type: 'foots', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},

          {oldItemName: 'โกลเด้นรีทรีฟเวอร์',name:'ดัลเมเชียน',health: 100, attack: 1, defend: 0, accuracy: 0,star: 1, textColor: 'text-white', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fdalmatian.png?alt=media&token=36550e66-073b-4384-872c-9131fcbd14c9'},
          {oldItemName: 'ดัลเมเชียน',name:'บูลเทอร์เรีย',health: 120, attack: 2, defend: 0, accuracy: 0,star: 2, textColor: 'text-amber-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fbullterrier.png?alt=media&token=053515f5-9a40-404e-b6c1-fadd6a09eed3'},
          {oldItemName: 'บูลเทอร์เรีย',name:'บ๊อกเซอร์',health: 130, attack: 3, defend: 0, accuracy: 0,star: 3, textColor: 'text-lime-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fboxer.png?alt=media&token=38d2078e-6dce-4e18-ad9d-b680b9c9eefe'},
          {oldItemName: 'บ๊อกเซอร์',name:'บูลด็อก',health: 140, attack: 4, defend: 0, accuracy: 0,star: 4, textColor: 'text-green-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fbulldog.png?alt=media&token=901c8fe8-30f6-4f7a-aaa5-dc6a89556f52'},
          {oldItemName: 'บูลด็อก',name:'บอร์เดอร์คอลลี่',health: 150, attack:5, defend: 0, accuracy: 0,star: 5, textColor: 'text-cyan-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fbordercollie.png?alt=media&token=9bebdac7-3dc0-45f9-9c8b-2f5aff8e77f3'},
          {oldItemName: 'บอร์เดอร์คอลลี่',name:'เกรทเดน',health: 160, attack: 6, defend: 3, accuracy: 0,star: 6, textColor: 'text-sky-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fgreatdane.png?alt=media&token=9eca6356-b999-4434-8f98-14ab37cd50d4'},
          {oldItemName: 'เกรทเดน',name:'เยอรมันเชพเพิร์ด',health: 170, attack: 7, defend: 5, accuracy: 1,star: 7, textColor: 'text-blue-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fgermanshepherd.png?alt=media&token=efd6ef7a-2fff-4d57-9df5-27569d378c39'},
          {oldItemName: 'เยอรมันเชพเพิร์ด',name:'โดเบอร์แมน',health: 180, attack: 8, defend: 5, accuracy: 3,star: 8, textColor: 'text-indigo-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fdoberman.png?alt=media&token=1aa5bbf8-ee8e-4720-bb6b-0adba32b72cf'},
          {oldItemName: 'โดเบอร์แมน',name:'พิทบูล',health: 190, attack: 9, defend: 10, accuracy: 7,star: 9, textColor: 'text-violet-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fpitbull.png?alt=media&token=c5fe566a-ed20-4e80-8812-3449dc859601'},
          {oldItemName: 'พิทบูล',ame:'ร็อตไวเลอร์',health: 200, attack: 10, defend: 15, accuracy: 11,star: 10, textColor: 'text-pink-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Frottweiler.png?alt=media&token=01b12ac2-a4c9-4ceb-9005-23e62c338177'},
          {oldItemName: 'ร็อตไวเลอร์',ame:'ร็อตไวเลอร์',health: 200, attack: 10, defend: 15, accuracy: 11,star: 10, textColor: 'text-pink-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Frottweiler.png?alt=media&token=01b12ac2-a4c9-4ceb-9005-23e62c338177'},

          {oldItemName: 'ตราสัญลักษณ์วิลเลี่ยม',name:'ตราสัญลักษณ์วูดดี้',health: 0, attack: 3, defend: 0,accuracy: 0,star: 2, textColor: 'text-amber-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc'},
          {oldItemName: 'ตราสัญลักษณ์วูดดี้',name:'ตราสัญลักษณ์วิกกี้',health: 0, attack: 5, defend: 0,accuracy: 0,star: 3, textColor: 'text-lime-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
          {oldItemName: 'ตราสัญลักษณ์วิกกี้',name:'ตราสัญลักษณ์เมสัน',health: 0, attack: 7, defend: 0,accuracy: 0,star: 4, textColor: 'text-green-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmason.png?alt=media&token=60f49aee-73e9-454f-b492-eb9f4b54c723'},
          {oldItemName: 'ตราสัญลักษณ์เมสัน',name:'ตราสัญลักษณ์มูน',health: 0, attack: 9, defend: 0,accuracy: 0,star: 5, textColor: 'text-cyan-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
          {oldItemName: 'ตราสัญลักษณ์มูน',name:'ตราสัญลักษณ์ลูคัส',health: 0, attack: 11, defend: 0,accuracy: 0,star: 6, textColor: 'text-sky-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Flucas.png?alt=media&token=77e7d979-3680-415f-a2d4-3588cae83e91'},
          {oldItemName: 'ตราสัญลักษณ์ลูคัส',name:'ตราสัญลักษณ์เบนจามิน',health: 0, attack: 13, defend: 0,accuracy: 0,star: 7, textColor: 'text-blue-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
          {oldItemName: 'ตราสัญลักษณ์เบนจามิน',name:'ตราสัญลักษณ์เจคอบ',health: 0, attack: 15, defend: 0,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce'},
          {oldItemName: 'ตราสัญลักษณ์เจคอบ',name:'ตราสัญลักษณ์เจคอบ',health: 0, attack: 15, defend: 0,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce'},

          {oldItemName: 'เข็มขัดนักล่า',name:'เข็มขัดนักล่า+2',health: 60, attack: 0, defend: 0,accuracy: 0,star: 2, textColor: 'text-amber-500', type:'waist', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fbelt.png?alt=media&token=da413dc1-21e8-4cb9-8d6f-24621e0997d2'},
          {oldItemName: 'เข็มขัดนักล่า+2',name:'เข็มขัดนักล่า+3',health: 90, attack: 0, defend: 0,accuracy: 0,star: 3, textColor: 'text-lime-500', type:'waist', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fbelt.png?alt=media&token=da413dc1-21e8-4cb9-8d6f-24621e0997d2'},
          {oldItemName: 'เข็มขัดนักล่า+3',name:'เข็มขัดนักล่า+4',health: 120, attack: 0, defend: 0,accuracy: 0,star: 4, textColor: 'text-green-500', type:'waist', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fbelt.png?alt=media&token=da413dc1-21e8-4cb9-8d6f-24621e0997d2'},
          {oldItemName: 'เข็มขัดนักล่า+4',name:'เข็มขัดนักล่า+5',health: 150, attack: 0, defend: 0,accuracy: 0,star: 5, textColor: 'text-cyan-500', type:'waist', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fbelt.png?alt=media&token=da413dc1-21e8-4cb9-8d6f-24621e0997d2'},
          {oldItemName: 'เข็มขัดนักล่า+5',name:'เข็มขัดนักล่า+6',health: 180, attack: 0, defend: 0,accuracy: 0,star: 6, textColor: 'text-sky-500', type:'waist', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fbelt.png?alt=media&token=da413dc1-21e8-4cb9-8d6f-24621e0997d2'},
          {oldItemName: 'เข็มขัดนักล่า+6',name:'เข็มขัดนักล่า+7',health: 210, attack: 0, defend: 0,accuracy: 0,star: 7, textColor: 'text-blue-500', type:'waist', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fbelt.png?alt=media&token=da413dc1-21e8-4cb9-8d6f-24621e0997d2'},
          {oldItemName: 'เข็มขัดนักล่า+7',name:'เข็มขัดนักล่า+8',health: 240, attack: 0, defend: 0,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'waist', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fbelt.png?alt=media&token=da413dc1-21e8-4cb9-8d6f-24621e0997d2'},
          {oldItemName: 'เข็มขัดนักล่า+8',name:'เข็มขัดนักล่า+8',health: 270, attack: 0, defend: 0,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'waist', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fbelt.png?alt=media&token=da413dc1-21e8-4cb9-8d6f-24621e0997d2'},

          {oldItemName: 'ถุงมือนักล่า',name:'ถุงมือนักล่า+2',health: 0, attack: 6, defend: 0,accuracy: 0,star: 2, textColor: 'text-amber-500', type:'glove', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fgloves.png?alt=media&token=a8f5dbcd-cf68-4f63-82b2-df962d311d1b'},
          {oldItemName: 'ถุงมือนักล่า+2',name:'ถุงมือนักล่า+3',health: 0, attack: 9, defend: 0,accuracy: 0,star: 3, textColor: 'text-lime-500', type:'glove', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fgloves.png?alt=media&token=a8f5dbcd-cf68-4f63-82b2-df962d311d1b'},
          {oldItemName: 'ถุงมือนักล่า+3',name:'ถุงมือนักล่า+4',health: 0, attack: 12, defend: 0,accuracy: 0,star: 4, textColor: 'text-green-500', type:'glove', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fgloves.png?alt=media&token=a8f5dbcd-cf68-4f63-82b2-df962d311d1b'},
          {oldItemName: 'ถุงมือนักล่า+4',name:'ถุงมือนักล่า+5',health: 0, attack: 15, defend: 0,accuracy: 0,star: 5, textColor: 'text-cyan-500', type:'glove', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fgloves.png?alt=media&token=a8f5dbcd-cf68-4f63-82b2-df962d311d1b'},
          {oldItemName: 'ถุงมือนักล่า+5',name:'ถุงมือนักล่า+6',health: 0, attack: 18, defend: 0,accuracy: 0,star: 6, textColor: 'text-sky-500', type:'glove', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fgloves.png?alt=media&token=a8f5dbcd-cf68-4f63-82b2-df962d311d1b'},
          {oldItemName: 'ถุงมือนักล่า+6',name:'ถุงมือนักล่า+7',health: 0, attack: 21, defend: 0,accuracy: 0,star: 7, textColor: 'text-blue-500', type:'glove', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fgloves.png?alt=media&token=a8f5dbcd-cf68-4f63-82b2-df962d311d1b'},
          {oldItemName: 'ถุงมือนักล่า+7',name:'ถุงมือนักล่า+8',health: 0, attack: 24, defend: 0,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'glove', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fgloves.png?alt=media&token=a8f5dbcd-cf68-4f63-82b2-df962d311d1b'},
          {oldItemName: 'ถุงมือนักล่า+8',name:'ถุงมือนักล่า+8',health: 0, attack: 27, defend: 0,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'glove', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fgloves.png?alt=media&token=a8f5dbcd-cf68-4f63-82b2-df962d311d1b'},

          {oldItemName: 'แหวนนักล่า',name:'แหวนนักล่า+2',health: 0, attack: 0, defend: 0,accuracy: 2,star: 2, textColor: 'text-amber-500', type:'ring', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fring.png?alt=media&token=08f298a6-fe77-49f2-9e7b-513a458070f7'},
          {oldItemName: 'แหวนนักล่า+2',name:'แหวนนักล่า+3',health: 0, attack: 0, defend: 0,accuracy: 3,star: 3, textColor: 'text-lime-500', type:'ring', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fring.png?alt=media&token=08f298a6-fe77-49f2-9e7b-513a458070f7'},
          {oldItemName: 'แหวนนักล่า+3',name:'แหวนนักล่า+4',health: 0, attack: 0, defend: 0,accuracy: 4,star: 4, textColor: 'text-green-500', type:'ring', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fring.png?alt=media&token=08f298a6-fe77-49f2-9e7b-513a458070f7'},
          {oldItemName: 'แหวนนักล่า+4',name:'แหวนนักล่า+5',health: 0, attack: 0, defend: 0,accuracy: 5,star: 5, textColor: 'text-cyan-500', type:'ring', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fring.png?alt=media&token=08f298a6-fe77-49f2-9e7b-513a458070f7'},
          {oldItemName: 'แหวนนักล่า+5',name:'แหวนนักล่า+6',health: 0, attack: 0, defend: 0,accuracy: 6,star: 6, textColor: 'text-sky-500', type:'ring', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fring.png?alt=media&token=08f298a6-fe77-49f2-9e7b-513a458070f7'},
          {oldItemName: 'แหวนนักล่า+6',name:'แหวนนักล่า+7',health: 0, attack: 0, defend: 0,accuracy: 7,star: 7, textColor: 'text-blue-500', type:'ring', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fring.png?alt=media&token=08f298a6-fe77-49f2-9e7b-513a458070f7'},
          {oldItemName: 'แหวนนักล่า+7',name:'แหวนนักล่า+8',health: 0, attack: 0, defend: 0,accuracy: 8,star: 8, textColor: 'text-indigo-500', type:'ring', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fring.png?alt=media&token=08f298a6-fe77-49f2-9e7b-513a458070f7'},
          {oldItemName: 'แหวนนักล่า+8',name:'แหวนนักล่า+8',health: 0, attack: 0, defend: 0,accuracy: 9,star: 8, textColor: 'text-indigo-500', type:'ring', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fring.png?alt=media&token=08f298a6-fe77-49f2-9e7b-513a458070f7'},

          {oldItemName: 'เกาะซองอาวุธ',name:'เกาะซองอาวุธ+2',health: 0, attack: 0, defend: 2,accuracy: 0,star: 2, textColor: 'text-amber-500', type:'armor', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fholster.png?alt=media&token=b537af7a-1fa2-4d0a-96df-385e51d3939a'},
          {oldItemName: 'เกาะซองอาวุธ+2',name:'เกาะซองอาวุธ+3',health: 0, attack: 0, defend: 3,accuracy: 0,star: 3, textColor: 'text-lime-500', type:'armor', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fholster.png?alt=media&token=b537af7a-1fa2-4d0a-96df-385e51d3939a'},
          {oldItemName: 'เกาะซองอาวุธ+3',name:'เกาะซองอาวุธ+4',health: 0, attack: 0, defend: 4,accuracy: 0,star: 4, textColor: 'text-green-500', type:'armor', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fholster.png?alt=media&token=b537af7a-1fa2-4d0a-96df-385e51d3939a'},
          {oldItemName: 'เกาะซองอาวุธ+4',name:'เกาะซองอาวุธ+5',health: 0, attack: 0, defend: 5,accuracy: 0,star: 5, textColor: 'text-cyan-500', type:'armor', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fholster.png?alt=media&token=b537af7a-1fa2-4d0a-96df-385e51d3939a'},
          {oldItemName: 'เกาะซองอาวุธ+5',name:'เกาะซองอาวุธ+6',health: 0, attack: 0, defend: 6,accuracy: 0,star: 6, textColor: 'text-sky-500', type:'armor', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fholster.png?alt=media&token=b537af7a-1fa2-4d0a-96df-385e51d3939a'},
          {oldItemName: 'เกาะซองอาวุธ+6',name:'เกาะซองอาวุธ+7',health: 0, attack: 0, defend: 7,accuracy: 0,star: 7, textColor: 'text-blue-500', type:'armor', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fholster.png?alt=media&token=b537af7a-1fa2-4d0a-96df-385e51d3939a'},
          {oldItemName: 'เกาะซองอาวุธ+7',name:'เกาะซองอาวุธ+8',health: 0, attack: 0, defend: 8,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'armor', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fholster.png?alt=media&token=b537af7a-1fa2-4d0a-96df-385e51d3939a'},
          {oldItemName: 'เกาะซองอาวุธ+8',name:'เกาะซองอาวุธ+8',health: 0, attack: 0, defend: 9,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'armor', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fholster.png?alt=media&token=b537af7a-1fa2-4d0a-96df-385e51d3939a'},

          {oldItemName: 'สร้อยคอเขี้ยวหมาป่า',name:'สร้อยคอเขี้ยวหมาป่า+2',health: 60, attack: 0, defend: 0,accuracy: 0,star: 2, textColor: 'text-amber-500', type:'necklace', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fnecklace.png?alt=media&token=534370ac-c5f1-449e-985b-e5809cb6b102'},
          {oldItemName: 'สร้อยคอเขี้ยวหมาป่า+2',name:'สร้อยคอเขี้ยวหมาป่า+3',health: 90, attack: 0, defend: 0,accuracy: 0,star: 3, textColor: 'text-lime-500', type:'necklace', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fnecklace.png?alt=media&token=534370ac-c5f1-449e-985b-e5809cb6b102'},
          {oldItemName: 'สร้อยคอเขี้ยวหมาป่า+3',name:'สร้อยคอเขี้ยวหมาป่า+4',health: 120, attack: 0, defend: 0,accuracy: 0,star: 4, textColor: 'text-green-500', type:'necklace', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fnecklace.png?alt=media&token=534370ac-c5f1-449e-985b-e5809cb6b102'},
          {oldItemName: 'สร้อยคอเขี้ยวหมาป่า+4',name:'สร้อยคอเขี้ยวหมาป่า+5',health: 150, attack: 0, defend: 0,accuracy: 0,star: 5, textColor: 'text-cyan-500', type:'necklace', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fnecklace.png?alt=media&token=534370ac-c5f1-449e-985b-e5809cb6b102'},
          {oldItemName: 'สร้อยคอเขี้ยวหมาป่า+5',name:'สร้อยคอเขี้ยวหมาป่า+6',health: 180, attack: 0, defend: 0,accuracy: 0,star: 6, textColor: 'text-sky-500', type:'necklace', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fnecklace.png?alt=media&token=534370ac-c5f1-449e-985b-e5809cb6b102'},
          {oldItemName: 'สร้อยคอเขี้ยวหมาป่า+6',name:'สร้อยคอเขี้ยวหมาป่า+7',health: 210, attack: 0, defend: 0,accuracy: 0,star: 7, textColor: 'text-blue-500', type:'necklace', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fnecklace.png?alt=media&token=534370ac-c5f1-449e-985b-e5809cb6b102'},
          {oldItemName: 'สร้อยคอเขี้ยวหมาป่า+7',name:'สร้อยคอเขี้ยวหมาป่า+8',health: 240, attack: 0, defend: 0,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'necklace', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fnecklace.png?alt=media&token=534370ac-c5f1-449e-985b-e5809cb6b102'},
          {oldItemName: 'สร้อยคอเขี้ยวหมาป่า+8',name:'สร้อยคอเขี้ยวหมาป่า+8',health: 270, attack: 0, defend: 0,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'necklace', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fnecklace.png?alt=media&token=534370ac-c5f1-449e-985b-e5809cb6b102'},

          {oldItemName: 'แว่นตานักเดินทาง',name:'แว่นตานักเดินทาง+2',health: 0, attack: 0, defend: 0,accuracy: 2,star: 2, textColor: 'text-amber-500', type:'glasses', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fglasses.png?alt=media&token=ef7c58f8-4c01-4344-9e0c-2e2f82a3e758'},
          {oldItemName: 'แว่นตานักเดินทาง+2',name:'แว่นตานักเดินทาง+3',health: 0, attack: 0, defend: 0,accuracy: 3,star: 3, textColor: 'text-lime-500', type:'glasses', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fglasses.png?alt=media&token=ef7c58f8-4c01-4344-9e0c-2e2f82a3e758'},
          {oldItemName: 'แว่นตานักเดินทาง+3',name:'แว่นตานักเดินทาง+4',health: 0, attack: 0, defend: 0,accuracy: 4,star: 4, textColor: 'text-green-500', type:'glasses', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fglasses.png?alt=media&token=ef7c58f8-4c01-4344-9e0c-2e2f82a3e758'},
          {oldItemName: 'แว่นตานักเดินทาง+4',name:'แว่นตานักเดินทาง+5',health: 0, attack: 0, defend: 0,accuracy: 5,star: 5, textColor: 'text-cyan-500', type:'glasses', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fglasses.png?alt=media&token=ef7c58f8-4c01-4344-9e0c-2e2f82a3e758'},
          {oldItemName: 'แว่นตานักเดินทาง+5',name:'แว่นตานักเดินทาง+6',health: 0, attack: 0, defend: 0,accuracy: 6,star: 6, textColor: 'text-sky-500', type:'glasses', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fglasses.png?alt=media&token=ef7c58f8-4c01-4344-9e0c-2e2f82a3e758'},
          {oldItemName: 'แว่นตานักเดินทาง+6',name:'แว่นตานักเดินทาง+7',health: 0, attack: 0, defend: 0,accuracy: 7,star: 7, textColor: 'text-blue-500', type:'glasses', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fglasses.png?alt=media&token=ef7c58f8-4c01-4344-9e0c-2e2f82a3e758'},
          {oldItemName: 'แว่นตานักเดินทาง+7',name:'แว่นตานักเดินทาง+8',health: 0, attack: 0, defend: 0,accuracy: 8,star: 8, textColor: 'text-indigo-500', type:'glasses', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fglasses.png?alt=media&token=ef7c58f8-4c01-4344-9e0c-2e2f82a3e758'},
          {oldItemName: 'แว่นตานักเดินทาง+8',name:'แว่นตานักเดินทาง+8',health: 0, attack: 0, defend: 0,accuracy: 9,star: 8, textColor: 'text-indigo-500', type:'glasses', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fglasses.png?alt=media&token=ef7c58f8-4c01-4344-9e0c-2e2f82a3e758'},

          {oldItemName: 'เข็มทิศที่สูญหาย',name:'เข็มทิศที่สูญหาย+2',health: 0, attack: 6, defend: 0,accuracy: 0,star: 2, textColor: 'text-amber-500', type:'glove', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcompass.png?alt=media&token=2d890498-ca56-4ddc-b815-4766c43aa4f5'},
          {oldItemName: 'เข็มทิศที่สูญหาย+2',name:'เข็มทิศที่สูญหาย+3',health: 0, attack: 9, defend: 0,accuracy: 0,star: 3, textColor: 'text-lime-500', type:'glove', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcompass.png?alt=media&token=2d890498-ca56-4ddc-b815-4766c43aa4f5'},
          {oldItemName: 'เข็มทิศที่สูญหาย+3',name:'เข็มทิศที่สูญหาย+4',health: 0, attack: 12, defend: 0,accuracy: 0,star: 4, textColor: 'text-green-500', type:'glove', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcompass.png?alt=media&token=2d890498-ca56-4ddc-b815-4766c43aa4f5'},
          {oldItemName: 'เข็มทิศที่สูญหาย+4',name:'เข็มทิศที่สูญหาย+5',health: 0, attack: 15, defend: 0,accuracy: 0,star: 5, textColor: 'text-cyan-500', type:'glove', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcompass.png?alt=media&token=2d890498-ca56-4ddc-b815-4766c43aa4f5'},
          {oldItemName: 'เข็มทิศที่สูญหาย+5',name:'เข็มทิศที่สูญหาย+6',health: 0, attack: 18, defend: 0,accuracy: 0,star: 6, textColor: 'text-sky-500', type:'glove', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcompass.png?alt=media&token=2d890498-ca56-4ddc-b815-4766c43aa4f5'},
          {oldItemName: 'เข็มทิศที่สูญหาย+6',name:'เข็มทิศที่สูญหาย+7',health: 0, attack: 21, defend: 0,accuracy: 0,star: 7, textColor: 'text-blue-500', type:'glove', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcompass.png?alt=media&token=2d890498-ca56-4ddc-b815-4766c43aa4f5'},
          {oldItemName: 'เข็มทิศที่สูญหาย+7',name:'เข็มทิศที่สูญหาย+8',health: 0, attack: 24, defend: 0,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'glove', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcompass.png?alt=media&token=2d890498-ca56-4ddc-b815-4766c43aa4f5'},
          {oldItemName: 'เข็มทิศที่สูญหาย+8',name:'เข็มทิศที่สูญหาย+8',health: 0, attack: 27, defend: 0,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'glove', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcompass.png?alt=media&token=2d890498-ca56-4ddc-b815-4766c43aa4f5'},

          {oldItemName: 'ปลอกแขนเหล็ก',name:'ปลอกแขนเหล็ก+2',health: 0, attack: 0, defend: 2,accuracy: 0,star: 2, textColor: 'text-amber-500', type:'armlet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Farmlet.png?alt=media&token=96693594-83d5-4d68-9812-d3befd2733f6'},
          {oldItemName: 'ปลอกแขนเหล็ก+2',name:'ปลอกแขนเหล็ก+3',health: 0, attack: 0, defend: 3,accuracy: 0,star: 3, textColor: 'text-lime-500', type:'armlet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Farmlet.png?alt=media&token=96693594-83d5-4d68-9812-d3befd2733f6'},
          {oldItemName: 'ปลอกแขนเหล็ก+3',name:'ปลอกแขนเหล็ก+4',health: 0, attack: 0, defend: 4,accuracy: 0,star: 4, textColor: 'text-green-500', type:'armlet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Farmlet.png?alt=media&token=96693594-83d5-4d68-9812-d3befd2733f6'},
          {oldItemName: 'ปลอกแขนเหล็ก+4',name:'ปลอกแขนเหล็ก+5',health: 0, attack: 0, defend: 5,accuracy: 0,star: 5, textColor: 'text-cyan-500', type:'armlet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Farmlet.png?alt=media&token=96693594-83d5-4d68-9812-d3befd2733f6'},
          {oldItemName: 'ปลอกแขนเหล็ก+5',name:'ปลอกแขนเหล็ก+6',health: 0, attack: 0, defend: 6,accuracy: 0,star: 6, textColor: 'text-sky-500', type:'armlet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Farmlet.png?alt=media&token=96693594-83d5-4d68-9812-d3befd2733f6'},
          {oldItemName: 'ปลอกแขนเหล็ก+6',name:'ปลอกแขนเหล็ก+7',health: 0, attack: 0, defend: 7,accuracy: 0,star: 7, textColor: 'text-blue-500', type:'armlet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Farmlet.png?alt=media&token=96693594-83d5-4d68-9812-d3befd2733f6'},
          {oldItemName: 'ปลอกแขนเหล็ก+7',name:'ปลอกแขนเหล็ก+8',health: 0, attack: 0, defend: 8,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'armlet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Farmlet.png?alt=media&token=96693594-83d5-4d68-9812-d3befd2733f6'},
          {oldItemName: 'ปลอกแขนเหล็ก+8',name:'ปลอกแขนเหล็ก+8',health: 0, attack: 0, defend: 9,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'armlet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Farmlet.png?alt=media&token=96693594-83d5-4d68-9812-d3befd2733f6'},
          
          {oldItemName: 'เครื่องรางชนพื้นเมือง',name:'เครื่องรางชนพื้นเมือง+2',health: 60, attack: 6, defend: 2,accuracy: 2,star: 2, textColor: 'text-amber-500', type:'amulet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Famulet.png?alt=media&token=83faa66a-ad84-4007-a5d5-37dc6ce8be1f'},
          {oldItemName: 'เครื่องรางชนพื้นเมือง+2',name:'เครื่องรางชนพื้นเมือง+3',health: 90, attack: 9, defend: 3,accuracy: 3,star: 3, textColor: 'text-lime-500', type:'amulet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Famulet.png?alt=media&token=83faa66a-ad84-4007-a5d5-37dc6ce8be1f'},
          {oldItemName: 'เครื่องรางชนพื้นเมือง+3',name:'เครื่องรางชนพื้นเมือง+4',health: 120, attack: 12, defend: 4,accuracy: 4,star: 4, textColor: 'text-green-500', type:'amulet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Famulet.png?alt=media&token=83faa66a-ad84-4007-a5d5-37dc6ce8be1f'},
          {oldItemName: 'เครื่องรางชนพื้นเมือง+4',name:'เครื่องรางชนพื้นเมือง+5',health: 150, attack: 15, defend: 5,accuracy: 5,star: 5, textColor: 'text-cyan-500', type:'amulet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Famulet.png?alt=media&token=83faa66a-ad84-4007-a5d5-37dc6ce8be1f'},
          {oldItemName: 'เครื่องรางชนพื้นเมือง+5',name:'เครื่องรางชนพื้นเมือง+6',health: 180, attack: 18, defend: 6,accuracy: 6,star: 6, textColor: 'text-sky-500', type:'amulet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Famulet.png?alt=media&token=83faa66a-ad84-4007-a5d5-37dc6ce8be1f'},
          {oldItemName: 'เครื่องรางชนพื้นเมือง+6',name:'เครื่องรางชนพื้นเมือง+7',health: 210, attack: 21, defend: 7,accuracy: 7,star: 7, textColor: 'text-blue-500', type:'amulet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Famulet.png?alt=media&token=83faa66a-ad84-4007-a5d5-37dc6ce8be1f'},
          {oldItemName: 'เครื่องรางชนพื้นเมือง+7',name:'เครื่องรางชนพื้นเมือง+8',health: 240, attack: 24, defend: 8,accuracy: 8,star: 8, textColor: 'text-indigo-500', type:'amulet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Famulet.png?alt=media&token=83faa66a-ad84-4007-a5d5-37dc6ce8be1f'},
          {oldItemName: 'เครื่องรางชนพื้นเมือง+8',name:'เครื่องรางชนพื้นเมือง+8',health: 270, attack: 27, defend: 9,accuracy: 9,star: 8, textColor: 'text-indigo-500', type:'amulet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Famulet.png?alt=media&token=83faa66a-ad84-4007-a5d5-37dc6ce8be1f'},

          {oldItemName: 'เชือกนักผจญภัย',name:'เชือกนักผจญภัย+2',health: 0, attack: 6, defend: 0,accuracy: 2,star: 2, textColor: 'text-amber-500', type:'lasso', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Flasso.png?alt=media&token=82c1874b-ab5a-47e8-933b-5153e42344fb'},
          {oldItemName: 'เชือกนักผจญภัย+2',name:'เชือกนักผจญภัย+3',health: 0, attack: 9, defend: 0,accuracy: 3,star: 3, textColor: 'text-lime-500', type:'lasso', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Flasso.png?alt=media&token=82c1874b-ab5a-47e8-933b-5153e42344fb'},
          {oldItemName: 'เชือกนักผจญภัย+3',name:'เชือกนักผจญภัย+4',health: 0, attack: 12, defend: 0,accuracy: 4,star: 4, textColor: 'text-green-500', type:'lasso', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Flasso.png?alt=media&token=82c1874b-ab5a-47e8-933b-5153e42344fb'},
          {oldItemName: 'เชือกนักผจญภัย+4',name:'เชือกนักผจญภัย+5',health: 0, attack: 15, defend: 0,accuracy: 5,star: 5, textColor: 'text-cyan-500', type:'lasso', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Flasso.png?alt=media&token=82c1874b-ab5a-47e8-933b-5153e42344fb'},
          {oldItemName: 'เชือกนักผจญภัย+5',name:'เชือกนักผจญภัย+6',health: 0, attack: 18, defend: 0,accuracy: 6,star: 6, textColor: 'text-sky-500', type:'lasso', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Flasso.png?alt=media&token=82c1874b-ab5a-47e8-933b-5153e42344fb'},
          {oldItemName: 'เชือกนักผจญภัย+6',name:'เชือกนักผจญภัย+7',health: 0, attack: 21, defend: 0,accuracy: 7,star: 7, textColor: 'text-blue-500', type:'lasso', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Flasso.png?alt=media&token=82c1874b-ab5a-47e8-933b-5153e42344fb'},
          {oldItemName: 'เชือกนักผจญภัย+7',name:'เชือกนักผจญภัย+8',health: 0, attack: 24, defend: 0,accuracy: 8,star: 8, textColor: 'text-indigo-500', type:'lasso', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Flasso.png?alt=media&token=82c1874b-ab5a-47e8-933b-5153e42344fb'},
          {oldItemName: 'เชือกนักผจญภัย+8',name:'เชือกนักผจญภัย+8',health: 0, attack: 27, defend: 0,accuracy: 9,star: 8, textColor: 'text-indigo-500', type:'lasso', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Flasso.png?alt=media&token=82c1874b-ab5a-47e8-933b-5153e42344fb'},

          {oldItemName: 'กระเป๋านักผจญภัย',name:'กระเป๋านักผจญภัย+2',health: 60, attack: 0, defend: 2,accuracy: 0,star: 2, textColor: 'text-amber-500', type:'bag', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fhaversack.png?alt=media&token=94695701-81ce-4e43-ba7b-a5a158d143f8'},
          {oldItemName: 'กระเป๋านักผจญภัย+2',name:'กระเป๋านักผจญภัย+3',health: 90, attack: 0, defend: 3,accuracy: 0,star: 3, textColor: 'text-lime-500', type:'bag', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fhaversack.png?alt=media&token=94695701-81ce-4e43-ba7b-a5a158d143f8'},
          {oldItemName: 'กระเป๋านักผจญภัย+3',name:'กระเป๋านักผจญภัย+4',health: 120, attack: 0, defend: 4,accuracy: 0,star: 4, textColor: 'text-green-500', type:'bag', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fhaversack.png?alt=media&token=94695701-81ce-4e43-ba7b-a5a158d143f8'},
          {oldItemName: 'กระเป๋านักผจญภัย+4',name:'กระเป๋านักผจญภัย+5',health: 150, attack: 0, defend: 5,accuracy: 0,star: 5, textColor: 'text-cyan-500', type:'bag', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fhaversack.png?alt=media&token=94695701-81ce-4e43-ba7b-a5a158d143f8'},
          {oldItemName: 'กระเป๋านักผจญภัย+5',name:'กระเป๋านักผจญภัย+6',health: 180, attack: 0, defend: 6,accuracy: 0,star: 6, textColor: 'text-sky-500', type:'bag', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fhaversack.png?alt=media&token=94695701-81ce-4e43-ba7b-a5a158d143f8'},
          {oldItemName: 'กระเป๋านักผจญภัย+6',name:'กระเป๋านักผจญภัย+7',health: 210, attack: 0, defend: 7,accuracy: 0,star: 7, textColor: 'text-blue-500', type:'bag', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fhaversack.png?alt=media&token=94695701-81ce-4e43-ba7b-a5a158d143f8'},
          {oldItemName: 'กระเป๋านักผจญภัย+7',name:'กระเป๋านักผจญภัย+8',health: 240, attack: 0, defend: 8,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'bag', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fhaversack.png?alt=media&token=94695701-81ce-4e43-ba7b-a5a158d143f8'},
          {oldItemName: 'กระเป๋านักผจญภัย+8',name:'กระเป๋านักผจญภัย+8',health: 270, attack: 0, defend: 9,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'bag', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fhaversack.png?alt=media&token=94695701-81ce-4e43-ba7b-a5a158d143f8'},

          
          {oldItemName: 'โล่ชนพื้นเมือง',name:'โล่ชนพื้นเมือง+2',health: 60, attack: 6, defend: 1,accuracy: 0,star: 2, textColor: 'text-amber-500', type:'offhand', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fwoodshield.png?alt=media&token=58ddf24a-a138-48c1-b6d1-90241a7e2542'},
          {oldItemName: 'โล่ชนพื้นเมือง+2',name:'โล่ชนพื้นเมือง+3',health: 90, attack: 9, defend: 2,accuracy: 0,star: 3, textColor: 'text-lime-500', type:'offhand', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fwoodshield.png?alt=media&token=58ddf24a-a138-48c1-b6d1-90241a7e2542'},
          {oldItemName: 'โล่ชนพื้นเมือง+3',name:'โล่ชนพื้นเมือง+4',health: 120, attack: 12, defend: 3,accuracy: 0,star: 4, textColor: 'text-green-500', type:'offhand', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fwoodshield.png?alt=media&token=58ddf24a-a138-48c1-b6d1-90241a7e2542'},
          {oldItemName: 'โล่ชนพื้นเมือง+4',name:'โล่ชนพื้นเมือง+5',health: 150, attack: 15, defend: 4,accuracy: 0,star: 5, textColor: 'text-cyan-500', type:'offhand', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fwoodshield.png?alt=media&token=58ddf24a-a138-48c1-b6d1-90241a7e2542'},
          {oldItemName: 'โล่ชนพื้นเมือง+5',name:'โล่ชนพื้นเมือง+6',health: 180, attack: 18, defend: 5,accuracy: 0,star: 6, textColor: 'text-sky-500', type:'offhand', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fwoodshield.png?alt=media&token=58ddf24a-a138-48c1-b6d1-90241a7e2542'},
          {oldItemName: 'โล่ชนพื้นเมือง+6',name:'โล่ชนพื้นเมือง+7',health: 210, attack: 21, defend: 6,accuracy: 0,star: 7, textColor: 'text-blue-500', type:'offhand', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fwoodshield.png?alt=media&token=58ddf24a-a138-48c1-b6d1-90241a7e2542'},
          {oldItemName: 'โล่ชนพื้นเมือง+7',name:'โล่ชนพื้นเมือง+8',health: 240, attack: 24, defend: 7,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'offhand', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fwoodshield.png?alt=media&token=58ddf24a-a138-48c1-b6d1-90241a7e2542'},
          {oldItemName: 'โล่ชนพื้นเมือง+8',name:'โล่ชนพื้นเมือง+8',health: 270, attack: 27, defend: 8,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'offhand', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fwoodshield.png?alt=media&token=58ddf24a-a138-48c1-b6d1-90241a7e2542'},

          {oldItemName: 'ซิการ์',name:'ซิการ์+2',health: 60, attack: 6, defend: 2,accuracy: 2,star: 2, textColor: 'text-amber-500', type:'offhand', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fwoodshield.png?alt=media&token=58ddf24a-a138-48c1-b6d1-90241a7e2542'},
          {oldItemName: 'ซิการ์+2',name:'ซิการ์+3',health: 90, attack: 9, defend: 3,accuracy: 3,star: 3, textColor: 'text-lime-500', type:'offhand', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fwoodshield.png?alt=media&token=58ddf24a-a138-48c1-b6d1-90241a7e2542'},
          {oldItemName: 'ซิการ์+3',name:'ซิการ์+4',health: 120, attack: 12, defend: 4,accuracy: 4,star: 4, textColor: 'text-green-500', type:'offhand', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fwoodshield.png?alt=media&token=58ddf24a-a138-48c1-b6d1-90241a7e2542'},
          {oldItemName: 'ซิการ์+4',name:'ซิการ์+5',health: 150, attack: 15, defend: 5,accuracy: 5,star: 5, textColor: 'text-cyan-500', type:'offhand', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fwoodshield.png?alt=media&token=58ddf24a-a138-48c1-b6d1-90241a7e2542'},
          {oldItemName: 'ซิการ์+5',name:'ซิการ์+6',health: 180, attack: 18, defend: 6,accuracy: 6,star: 6, textColor: 'text-sky-500', type:'offhand', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fwoodshield.png?alt=media&token=58ddf24a-a138-48c1-b6d1-90241a7e2542'},
          {oldItemName: 'ซิการ์+6',name:'ซิการ์+7',health: 210, attack: 21, defend: 7,accuracy: 7,star: 7, textColor: 'text-blue-500', type:'offhand', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fwoodshield.png?alt=media&token=58ddf24a-a138-48c1-b6d1-90241a7e2542'},
          {oldItemName: 'ซิการ์+7',name:'ซิการ์+8',health: 240, attack: 24, defend: 8,accuracy: 8,star: 8, textColor: 'text-indigo-500', type:'offhand', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fwoodshield.png?alt=media&token=58ddf24a-a138-48c1-b6d1-90241a7e2542'},
          {oldItemName: 'ซิการ์+8',name:'ซิการ์+8',health: 270, attack: 27, defend: 9,accuracy: 9,star: 8, textColor: 'text-indigo-500', type:'offhand', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fwoodshield.png?alt=media&token=58ddf24a-a138-48c1-b6d1-90241a7e2542'},
        ]

        const handleSelectItem = (id, item) => {
          itemId.value = id
          itemSelected.value = item
          newItem.value = upgradeItems.find(upgradeItem => upgradeItem.oldItemName === item.name)
        }

        const handleUpgradeItem = e =>{
          const upgradeItem = projectFunctions.httpsCallable('upgradeItem')
          isPending.value = true
          upgradeItem({itemId: itemId.value}).then(result =>{
            isPending.value = false
            report.value = result.data
            itemSelected.value = {id:''}
          }).catch(err =>{
            isPending.value = false
            report.value = err
            itemSelected.value = {id:''}
          })

        }

        return { handleUpgradeItem,report,cancelModal,items,isPending,handleSelectItem,itemSelected,newItem }
    }
  }
  </script>