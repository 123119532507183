<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-full pb-16 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div>
            <div class="flex items-center justify-center"><img :src="treasure.img" class="w-32"></div>
            <div>
              <div class="grid grid-cols-5 sm:grid-cols-6 md:grid-cols-7 lg:grid-cols-8 xl:grid-cols-9 2xl:grid-cols-10 gap-1 text-center">
                <div v-for="key in treasure.amountOfKey" :key="key">
                  <div @click="keySelected = key" :class="[keySelected === key ? 'bg-indigo-900' : '']" class=" p-0.5 py-1 bg-gray-900 rounded hover:bg-indigo-900 duration-300 cursor-pointer text-xs">
                    <div class="flex items-center justify-center"><img src="../../assets/images/key.png" class="w-5"></div>
                    <div>{{ key }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">
          <button v-if="keySelected" type="button" class="w-full justify-center rounded bg-yellow-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-600 duration-300">เปิดกล่องสมบัติ</button>
          <button v-if="!keySelected" type="button" class="w-full justify-center rounded bg-red-900 px-3 py-2 text-sm font-semibold text-white shadow-sm cursor-not-allowed  duration-300">เลือกกุญแจที่สามารถไขกล่องสมบัติได้</button>
          <button @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
        </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { projectFirestore } from '@/firebase/config';
import { ref } from 'vue'
  export default {
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}
        const treasure = ref({})
        projectFirestore.collection('serverPublic').doc('treasure').onSnapshot(snapshot =>{
          treasure.value = snapshot.data()
        })
        const keySelected = ref()
        return { cancelModal,treasure,keySelected }
    },
  }
  </script>