<template>
    <div class="p-1 sm:p-3 bg-gray-900 rounded shadow flex items-center h-full justify-center">
      <div class="flex sm:block sm:space-x-0 items-center space-x-1 justify-center">
        <div class="flex justify-center"><img src="../../assets/images/stranger.png" class=" w-10 sm:w-32"></div>
        <div class="font-bold text-xl sm:text-3xl text-center">{{character.power}} พลัง</div>
      </div>
    </div>
</template>
<script>
import getUser from '@/composables/getUser';
import { projectFirestore } from '@/firebase/config';
import { ref } from 'vue';

export default {
    setup() {
      const { user } = getUser()
      const character = ref({})
      projectFirestore.collection('characterPrivate').doc(user.value.uid).onSnapshot(characterDoc =>{
        character.value = characterDoc.data()
      })


      return { character }
    },  
}
</script>