<template>
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex h-full pb-16 items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
          <div class="bg-gray-800 text-white p-3">
            <div>
              <div v-if="admin" class="text-center">{{ worker.id }}</div>
              <div class="flex items-center space-x-1 justify-center">
                <div class="flex items-center space-x-1 border border-gray-700 rounded p-0.5 px-2 font-bold">
                  <div><img src="../assets/images/star.png" class="w-3 sm:w-4"></div>
                  <div class="text-xs sm:text-sm">{{ worker.star }}</div>
                </div>
                <div :class="worker.textColor" class="text-center">{{worker.name}}</div>
              </div>
              <div class="flex justify-center"><img :src="worker.img" class="w-16 sm:w-20"></div>
              <div class="flex justify-center font-bold space-x-1 items-center text-xs sm:text-sm">
                <div class="flex space-x-1 bg-pink-800 p-0.5 px-2 rounded items-center">
                  <div><img src="../assets/images/hearth.png" class="w-3 sm:w-4"></div>
                  <div>{{ worker.health + worker.itemHealth + worker.skillHealth + worker.collectionHealth }}</div>
                </div>
                <div class="flex space-x-1 bg-red-800 p-0.5 px-2 rounded items-center">
                  <div><img src="../assets/images/sword.png" class="w-3 sm:w-4"></div>
                  <div>{{ worker.attack + worker.itemAttack + worker.skillAttack + worker.collectionAttack }}</div>
                </div>
                <div class="flex space-x-1 bg-blue-800 p-0.5 px-2 rounded items-center">
                  <div><img src="../assets/images/shield.png" class="w-3 sm:w-4"></div>
                  <div>{{ worker.defend + worker.itemDefend + worker.skillDefend + worker.collectionDefend }}</div>
                </div>
                <div class="flex space-x-1 bg-amber-900 p-0.5 px-2 rounded items-center">
                  <div><img src="../assets/images/target.png" class="w-3 sm:w-4"></div>
                  <div>{{ worker.accuracy + worker.itemAccuracy + worker.skillAccuracy + worker.collectionAccuracy }}</div>
                </div>
              </div>
              <div v-if="workerItems.length >= 1"  class="text-center text-xs sm:text-sm text-gray-400 mt-2">ไอเทมที่คนงานใส่อยู่({{ workerItems.length }})</div>
              <div v-if="workerItems.length >= 1" :class="[workerItems.length >= 4 ? 'h-36 sm:h-44 overflow-auto' :'h-auto']" id="style-1">
                <div v-for="item in workerItems" :key="item.id">
                  <div class="flex py-0.5 text-xs sm:text-sm justify-between items-center border-b border-gray-700">
                    <div class="flex items-center space-x-1">
                      <div><img :src="item.img" class="w-7 sm:w-10"></div>
                      <div class="flex justify-center items-center space-x-1 border border-gray-700 px-1 rounded">
                        <div><img src="../assets/images/star.png" class="w-3"></div>
                        <div class=" font-bold text-sm">{{ item.star }}</div>
                      </div>
                      <div :class="item.textColor" class="font-bold">{{ item.name }}</div>
                    </div>
                    <div class="flex items-center space-x-1 font-bold  text-xs sm:text-sm">
                      <div v-if="item.health >= 1" class="flex items-center space-x-1">
                        <div>{{ item.health }}</div>
                        <div><img src="../assets/images/hearth.png" class="w-4 sm:w-5"></div>
                      </div>
                      <div v-if="item.attack >= 1" class="flex items-center space-x-1">
                        <div>{{ item.attack }}</div>
                        <div><img src="../assets/images/sword.png" class="w-4 sm:w-5"></div>
                      </div>
                      <div v-if="item.defend >= 1" class="flex items-center space-x-1">
                        <div>{{ item.defend }}</div>
                        <div><img src="../assets/images/shield.png" class="w-4 sm:w-5"></div>
                      </div>
                      <div v-if="item.accuracy >= 1" class="flex items-center space-x-1">
                        <div>{{ item.accuracy }}</div>
                        <div><img src="../assets/images/target.png" class="w-4 sm:w-5"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="workerSkill.length >= 1">
                <div class="text-gray-400 text-center text-sm my-1">ทักษะคนงาน({{ workerSkill.length }})</div>
                <div :class="[workerSkill.length >= 2 ? 'h-20':'h-auto']" class=" overflow-auto" id="style-1">
                  <div v-for="skill in skills" :key="skill">
                    <div v-if="workerSkill.includes(skill.name)"   class="flex justify-between items-center space-x-1 font-bold border-b border-gray-700 ">
                      <div  class="flex items-center space-x-1">
                        <div  class="flex justify-center"><img :src="skill.img" class="w-7 sm:w-10"></div>
                        <div class="flex justify-center items-center space-x-1 border border-gray-700 px-1 rounded">
                              <div><img src="../assets/images/star.png" class="w-3"></div>
                              <div class=" font-bold text-sm">{{ skill.star }}</div>
                            </div>
                        <div :class="skill.textColor" class="text-xs sm:text-sm">{{ skill.name }}</div>
                      </div>
                      <div v-if="skill.health >= 1" class="flex items-center space-x-1 text-xs sm:text-sm">
                          <div>{{ skill.health }}</div>
                          <div><img src="../assets/images/hearth.png" class="w-4 sm:w-5 "></div>
                        </div>
                      <div v-if="skill.attack >= 1" class="flex  items-center space-x-1 text-xs sm:text-sm">
                        <div>{{ skill.attack }}</div>
                        <div><img src="../assets/images/sword.png" class="w-4 sm:w-5"></div>
                      </div>
                      <div v-if="skill.defend >= 1" class="flex  items-center space-x-1 text-xs sm:text-sm">
                        <div>{{ skill.defend }}</div>
                        <div><img src="../assets/images/shield.png" class="w-4 sm:w-5"></div>
                      </div>
                      <div v-if="skill.accuracy >= 1" class="flex  items-center space-x-1 text-xs sm:text-sm">
                        <div>{{ skill.accuracy }}</div>
                        <div><img src="../assets/images/target.png" class="w-4 sm:w-5"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="workerMaps.length >= 1">
                <div class="text-gray-400 text-center text-sm my-1">แผนที่คนงาน({{ workerMaps.length }})</div>
                <div :class="[workerMaps.length >= 2 ? 'h-20':'h-auto']" class=" overflow-auto" id="style-1">
                  <div v-for="map in maps" :key="map">
                    <div v-if="workerMaps.includes(map.name)"   class="flex justify-between items-center space-x-1 font-bold border-b border-gray-700 ">
                      <div  class="flex items-center space-x-1">
                        <div  class="flex justify-center"><img :src="map.img" class="w-7 sm:w-10"></div>
                        <div class="flex justify-center items-center space-x-1 border border-gray-700 px-1 rounded">
                              <div><img src="../assets/images/star.png" class="w-3"></div>
                              <div class=" font-bold text-sm">{{ map.star }}</div>
                            </div>
                        <div :class="map.textColor" class="text-xs sm:text-sm">{{ map.name }}</div>
                      </div>
                      <div class="flex items-center space-x-1 text-xs sm:text-sm">
                        <div v-if="map.health >= 1" class="flex items-center space-x-1">
                          <div>{{ map.health }}</div>
                          <div><img src="../assets/images/hearth.png" class="w-4 sm:w-5"></div>
                        </div>
                      <div v-if="map.attack >= 1" class="flex  items-center space-x-1">
                        <div>{{ map.attack }}</div>
                        <div><img src="../assets/images/sword.png" class="w-4 sm:w-5"></div>
                      </div>
                      <div v-if="map.defend >= 1" class="flex  items-center space-x-1">
                        <div>{{ map.defend }}</div>
                        <div><img src="../assets/images/shield.png" class="w-4 sm:w-5"></div>
                      </div>
                      <div v-if="map.accuracy >= 1" class="flex  items-center space-x-1">
                        <div>{{ map.accuracy }}</div>
                        <div><img src="../assets/images/target.png" class="w-4 sm:w-5"></div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="workerCollectionUsed.length >= 1">
                <div class="text-gray-400 text-center text-sm my-1">ของสะสมคนงาน({{ workerCollectionUsed.length }})</div>
                <div :class="[workerCollectionUsed.length >= 2 ? 'h-20':'h-auto']" class=" overflow-auto" id="style-1">
                  <div v-for="collection in collections" :key="collection">
                    <div v-if="workerCollectionUsed.includes(collection.name)"   class="flex justify-between items-center space-x-1 font-bold border-b border-gray-700 ">
                      <div  class="flex items-center space-x-1">
                        <div  class="flex justify-center"><img src="../assets/images/lightstar.png" class="w-7 sm:w-10"></div>
                        <div class="flex justify-center items-center space-x-1 border border-gray-700 px-1 rounded">
                              <div><img src="../assets/images/star.png" class="w-3"></div>
                              <div class=" font-bold text-sm">{{ collection.star }}</div>
                            </div>
                        <div :class="collection.textColor" class="text-xs sm:text-sm">{{ collection.name }}</div>
                      </div>
                      <div v-if="collection.health >= 1" class="flex items-center space-x-1 text-xs sm:text-sm">
                          <div>{{ collection.health }}</div>
                          <div><img src="../assets/images/hearth.png" class="w-4 sm:w-5"></div>
                        </div>
                      <div v-if="collection.attack >= 1" class="flex  items-center space-x-1 text-xs sm:text-sm">
                        <div>{{ collection.attack }}</div>
                        <div><img src="../assets/images/sword.png" class="w-4 sm:w-5"></div>
                      </div>
                      <div v-if="collection.defend >= 1" class="flex  items-center space-x-1 text-xs sm:text-sm">
                        <div>{{ collection.defend }}</div>
                        <div><img src="../assets/images/shield.png" class="w-4 sm:w-5"></div>
                      </div>
                      <div v-if="collection.accuracy >= 1" class="flex  items-center space-x-1 text-xs sm:text-sm">
                        <div>{{ collection.accuracy }}</div>
                        <div><img src="../assets/images/target.png" class="w-4 sm:w-5"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <router-link
                :to="{ name: 'Profile', params: { id: worker.ownerUid } }"
                v-if="worker.ownerUid"
                class="block mt-1.5 w-full justify-center rounded bg-indigo-700 px-3 py-2 text-sm font-semibold text-gray-100 shadow-sm hover:bg-indigo-800 duration-300 text-center"
              >
                เจ้าของคนงาน
              </router-link>
              <button @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-white duration-300">
                ยกเลิก
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { projectFirestore } from '@/firebase/config'
import getUser from '@/composables/getUser';

export default {
  props: ['workerId'],
  setup(props, context) {
    const { user, admin } = getUser()
    const cancelModal = () => {
      context.emit('cancelModal')
    }

    const worker = ref({})
    const workerItems = ref([])
    const workerSkill = ref([])
    const workerCollectionUsed = ref([])
    const workerMaps = ref([])

    const maps = [
        {name:'แผนที่เหมืองเดอะฮิวจ์',price: 3000, health: 50, attack: 3, defend: 1, accuracy: 1,star: 1, textColor: 'text-white', type:'map', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fmap.png?alt=media&token=7edafd76-ba65-46cc-bd3a-733e1756e387'},
          {name:'แผนที่ป่าซานฮวน',health: 50, attack: 3, defend: 1, accuracy: 1,star: 2, type: 'map', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fmap.png?alt=media&token=7edafd76-ba65-46cc-bd3a-733e1756e387'},
          {name:'แผนที่ป่าดิบชื้นเดดวูด',price: 6000, health: 50, attack: 3, defend: 1, accuracy: 1,star: 3, textColor: 'text-lime-500', type:'map', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fmap.png?alt=media&token=7edafd76-ba65-46cc-bd3a-733e1756e387'},
          {name:'แผนที่ทะเลทรายแห่งความตาย',price: 9000, health: 50, attack: 3, defend: 1, accuracy: 1,star: 5, textColor: 'text-cyan-500', type:'map', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fmap.png?alt=media&token=7edafd76-ba65-46cc-bd3a-733e1756e387'},
          {name:'แผนที่หมู่บ้านโจรสลัด',price: 12000, health: 50, attack: 3, defend: 1, accuracy: 1,star: 7, textColor: 'text-blue-500', type:'map', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fmap.png?alt=media&token=7edafd76-ba65-46cc-bd3a-733e1756e387'},
          {name:'แผนที่ตรอกซอยลับเจ้าพ่อ',price: 15000, health: 50, attack: 3, defend: 1, accuracy: 1,star: 8, textColor: 'text-indigo-500', type:'map', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fmap.png?alt=media&token=7edafd76-ba65-46cc-bd3a-733e1756e387'},
        ]

    const skills = [
      {name:'หมัดเบา',health: 0, attack: 5, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Foldbook.png?alt=media&token=b3edc4fe-4f0a-4ebd-a723-67a9990fffaa'},
      {name:'เตะเบา',health: 0, attack: 5, defend: 0, accuracy: 0,star: 2, type: 'collection', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Foldbook.png?alt=media&token=b3edc4fe-4f0a-4ebd-a723-67a9990fffaa'},
      {name:'หมัดแย็บ',health: 0, attack: 5, defend: 0, accuracy: 0,star: 3, type: 'collection', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Foldbook.png?alt=media&token=b3edc4fe-4f0a-4ebd-a723-67a9990fffaa'},
      {name:'เตะรัว',health: 0, attack: 5, defend: 0, accuracy: 0,star: 4, type: 'collection', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Foldbook.png?alt=media&token=b3edc4fe-4f0a-4ebd-a723-67a9990fffaa'},
      {name:'อัปเปอร์คัต',health: 0, attack: 5, defend: 0, accuracy: 0,star: 5, type: 'collection', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Foldbook.png?alt=media&token=b3edc4fe-4f0a-4ebd-a723-67a9990fffaa'},
      {name:'กระโดดถีบ',health: 0, attack: 5, defend: 0, accuracy: 0,star: 6, type: 'collection', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Foldbook.png?alt=media&token=b3edc4fe-4f0a-4ebd-a723-67a9990fffaa'},
      {name:'ทำลายจุดอ่อน',health: 0, attack: 5, defend: 0, accuracy: 0,star: 7, type: 'collection', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Foldbook.png?alt=media&token=b3edc4fe-4f0a-4ebd-a723-67a9990fffaa'},
      {name:'เพชฌฆาต',health: 0, attack: 5, defend: 0, accuracy: 0,star: 8, type: 'collection', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Foldbook.png?alt=media&token=b3edc4fe-4f0a-4ebd-a723-67a9990fffaa'},
    ]
    const collections = [
    {name:'กระป๋องขอทาน',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
          {name:'เศษผ้าเก่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
          {name:'กระดาษลัง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
          {name:'หีบเพลงเก่า',health: 0, attack: 0, defend: 1, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
          {name:'ช้อน',health: 0, attack: 0, defend: 0, accuracy: 1,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
          {name:'ระเบิด',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'กระทะกรองทอง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'เศษหิน',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'รถเข็นขนแร่',health: 0, attack: 0, defend: 1, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ชะแลง',health: 0, attack: 0, defend: 0, accuracy: 1,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'หินน้ำตาล',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'เขี้ยวหมูป่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ผลไม้',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'หนังหมูป่า',health: 0, attack: 0, defend: 1, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ลูกตาหมูป่า',health: 0, attack: 0, defend: 0, accuracy: 1,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'เทียน',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'แท่งเหล็ก',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ตราผู้สนับสนุน',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'หนังชั้นดี',health: 0, attack: 0, defend: 1, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'กล้องส่องทางไกล',health: 0, attack: 0, defend: 0, accuracy: 1,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ลูกปัดสีฟ้า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ขลุ่ยชนชั้นสูง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กระดิ่ง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'รูปปั้นเทพพระเจ้า',health: 0, attack: 0, defend: 1, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'หนังสือโบราณ',health: 0, attack: 0, defend: 0, accuracy: 1,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ก้อนหินสีเหลือง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'กระดูกส่วนแขน',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ถ่านหิน',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'กระติกน้ำที่เสียแล้ว',health: 0, attack: 0, defend: 1, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ไม้ขีดไฟ',health: 0, attack: 0, defend: 0, accuracy: 1,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ถุงผ้าเก่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ผ้าห่มขาด',health: 0, attack: 3, defend: 0, accuracy: 0,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ขวดน้ำ',health: 0, attack: 3, defend: 0, accuracy: 0,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'เศษกระดาษ',health: 0, attack: 0, defend: 1, accuracy: 0,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ของเล่นเก่า',health: 0, attack: 0, defend: 0, accuracy: 1,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'กระดูกสัตว์ป่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'หนังติดเลือด',health: 0, attack: 3, defend: 0, accuracy: 0,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'หินสีดำ',health: 0, attack: 3, defend: 0, accuracy: 0,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'เศษเสื้อ',health: 0, attack: 0, defend: 1, accuracy: 0,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'หางหมาป่า',health: 0, attack: 0, defend: 0, accuracy: 1,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'คบเพลิง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'เขากวางมูส',health: 0, attack: 3, defend: 0, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'เชือก',health: 0, attack: 3, defend: 0, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'รูปภาพเก่า',health: 0, attack: 0, defend: 1, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'หินล้ำค่า',health: 0, attack: 0, defend: 0, accuracy: 1,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'กระโหลกนักเดินทาง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ใบไม้สีหายาก',health: 0, attack: 3, defend: 0, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เขี้ยวสัตว์ป่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ลากไม้',health: 0, attack: 0, defend: 1, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ขนนกชั้นเลิศ',health: 0, attack: 0, defend: 0, accuracy: 1,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ตะเกียง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 4, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กิ่งไม้',health: 0, attack: 3, defend: 0, accuracy: 0,star: 4, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'สายจูงสัตว์',health: 0, attack: 3, defend: 0, accuracy: 0,star: 4, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เข็มขัดบาง',health: 0, attack: 0, defend: 1, accuracy: 0,star: 4, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ใบมีด',health: 0, attack: 0, defend: 0, accuracy: 1,star: 4, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'นาฬิกาทราย',health: 0, attack: 3, defend: 0, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แผนที่ล้ำค่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เกือกม้า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'หมายประกาศจับ',health: 0, attack: 0, defend: 1, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เอกสารลับ',health: 0, attack: 0, defend: 0, accuracy: 1,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เศษทรายหายาก',health: 0, attack: 3, defend: 0, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ขวดเหล้าเปล่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เศษซากกระดูก',health: 0, attack: 3, defend: 0, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กระติกน้ำ',health: 0, attack: 0, defend: 1, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แผนที่ขาด',health: 0, attack: 0, defend: 0, accuracy: 1,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ธนบัตรเก่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 6, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เครื่องลายคราม',health: 0, attack: 3, defend: 0, accuracy: 0,star: 6, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ผ้าไหม',health: 0, attack: 3, defend: 0, accuracy: 0,star: 6, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'สมุดบัญชี',health: 0, attack: 0, defend: 1, accuracy: 0,star: 6, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แผนที่รถไฟ',health: 0, attack: 0, defend: 0, accuracy: 1,star: 6, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แผนที่สมบัติ',health: 0, attack: 3, defend: 0, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ถ้วยเงิน',health: 0, attack: 3, defend: 0, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กาน้ำ',health: 0, attack: 3, defend: 0, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ธงโจรสลัด',health: 0, attack: 0, defend: 1, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ผ้าโพกหัว',health: 0, attack: 0, defend: 0, accuracy: 1,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แจกันโจรสลัด',health: 0, attack: 3, defend: 0, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'จานแตก',health: 0, attack: 3, defend: 0, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แกวน้ำโจรสลัด',health: 0, attack: 3, defend: 0, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แผ่นไม้',health: 0, attack: 0, defend: 1, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ตะปู',health: 0, attack: 0, defend: 0, accuracy: 1,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'นาฬิกาสร้อยคอ',health: 0, attack: 3, defend: 0, accuracy: 0,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แหวนอัญมนี',health: 0, attack: 3, defend: 0, accuracy: 0,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ภาพวาดยุโรป',health: 0, attack: 3, defend: 0, accuracy: 0,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กำไลทองคำ',health: 0, attack: 0, defend: 1, accuracy: 0,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'หนังสือพิมพ์',health: 0, attack: 0, defend: 0, accuracy: 1,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เศษหนวด',health: 0, attack: 3, defend: 0, accuracy: 0,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'หวีทองคำ',health: 0, attack: 3, defend: 0, accuracy: 0,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กรรไกรคนรวย',health: 0, attack: 3, defend: 0, accuracy: 0,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ขนหมาพิทบูล',health: 0, attack: 0, defend: 1, accuracy: 0,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กระจกกรอบทอง',health: 0, attack: 0, defend: 0, accuracy: 1,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ขนนก',health: 0, attack: 3, defend: 0, accuracy: 0,star: 9, type: 'collection', textColor: 'text-whi te', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
             {name:'ลูกธนู',health: 0, attack: 3, defend: 0, accuracy: 0,star: 9, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'งานปั้นชั้นเลิศ',health: 0, attack: 3, defend: 0, accuracy: 0,star: 9, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กลอง',health: 0, attack: 0, defend: 1, accuracy: 0,star: 9, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'โทเท็ม',health: 0, attack: 0, defend: 0, accuracy: 1,star: 9, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
        ]

    onMounted(() => {
      projectFirestore.collection('workers').doc(props.workerId).get().then(snapshot => {
        if (snapshot.exists) {
          worker.value = snapshot.data()
          workerSkill.value = snapshot.data().skills
          workerCollectionUsed.value = snapshot.data().collectionUsed
          workerMaps.value = snapshot.data().maps
          if (worker.value && worker.value.items) {
            // ใช้ Promise.all เพื่อให้แน่ใจว่าข้อมูลทั้งหมดถูกดึงมา
            Promise.all(worker.value.items.map(itemId => {
              return projectFirestore.collection('items').doc(itemId).get().then(itemDoc => {
                if (itemDoc.exists) {
                  return { id: itemId, ...itemDoc.data() }
                } else {
                  return null
                }
              })
            })).then(items => {
              // กรองค่าที่เป็น null ออก
              workerItems.value = items.filter(item => item !== null)
            }).catch(error => {
              console.error('Error getting item documents:', error)
            })
          } else {
            console.error('worker.value or worker.value.items is undefined')
          }
        } else {
          console.error('No such document!')
        }
      }).catch(error => {
        console.error('Error getting document:', error)
      })
    })

    return { admin,workerMaps,maps,skills,cancelModal, worker, workerItems,workerSkill,collections, workerCollectionUsed }
  }
}
</script>
