<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-4/5 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div v-if="opponents.length >= 1">
            <div :class="[opponents.length <= 4 ? 'h-auto' : ' h-54 overflow-auto']" class="grid grid-cols-1 gap-1 h-52">
              <div v-for="worker in opponents" :key="worker">
                <div @click="opponentSelected = worker" :class="[opponentSelected.id === worker.id ? 'bg-red-900' : '']" class="flex items-center justify-between bg-gray-900 hover:bg-red-900 cursor-pointer p-1 rounded-lg ">
                  <div class="flex items-center space-x-1 w-full">
                    <div><img :src="worker.img" class="w-10"></div>
                    <div class="text-xs sm:text-sm w-full space-y-1">
                      <div class="flex items-center justify-between">
                        <div>บุคคลลึกลับ</div>
                        <div class="flex items-center space-x-1">
                            <div class="p-0.5 w-12 flex justify-center items-center space-x-1 bg-red-800 rounded">
                            <div>{{ worker.attack + worker.itemAttack + worker.skillAttack + worker.collectionAttack }}</div>
                            <img src="../../assets/images/sword.png" class="w-3.5 h-3.5">
                            </div>
                            <div class="p-0.5 w-12 flex justify-center items-center space-x-1 bg-blue-800 rounded">
                            <div>{{ worker.defend + worker.itemDefend + worker.skillDefend + worker.collectionDefend }}</div>
                            <img src="../../assets/images/shield.png" class="w-3.5 h-3.5">
                            </div>
                            <div class="p-0.5 w-12 flex justify-center items-center space-x-1 bg-amber-900 rounded">
                            <div>{{ worker.accuracy + worker.itemAccuracy + worker.skillAccuracy + worker.collectionAccuracy }}</div>
                            <img src="../../assets/images/target.png" class="w-3.5 h-3.5">
                            </div>
                        </div>
                      </div>
                      <div  class="relative w-full h-3 rounded bg-gray-400 text-xs text-gray-800  overflow-hidden mb-1">
                        <div class="bg-red-600 h-full" :style="{ width: `${100-((worker.hit / (worker.health + worker.itemHealth + worker.skillHealth + worker.collectionHealth)) * 100)}%` }"></div>
                        <div class="absolute inset-0 flex items-center justify-center text-white px-1">
                          {{ (worker.health + worker.itemHealth + worker.skillHealth + worker.collectionHealth) - worker.hit }}/{{ (worker.health + worker.itemHealth + worker.skillHealth + worker.collectionHealth) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="opponents.length < 1" class="text-xl font-bold h-16 flex items-center justify-center border opacity-50">
            <div>ไม่พบคนงานในสถานที่นี้</div>
          </div>
          <div class="mt-2">
            <div v-if="report" class="text-center text-sm font-bold">{{ report }}</div>
          </div>
          <div class="mt-2">
            <button v-if="opponentSelected.id && !isPending" @click="handleBattle" type="button" class="w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">ต่อสู้ ({{ worker.battleCount }}/15)</button>
            <button v-if="!opponentSelected.id && !isPending" type="button" class="w-full justify-center rounded bg-red-900 px-3 py-2 text-sm font-semibold text-white shadow-sm  duration-300 cursor-not-allowed">กรุณาเลือกคนงานที่คุณต้องการสู้</button>
            <button v-if="isPending"  type="button" class="mt-1.5 w-full justify-center animate-pulse rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm cursor-not-allowed">loading...</button>
            <button v-if="!isPending" @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
<script>
import getUser from '@/composables/getUser';
import { projectFirestore, projectFunctions } from '@/firebase/config';
import { ref, onUnmounted } from 'vue';

export default {
  props: ['id'],
  setup(props, context) {
    const cancelModal = (e) => { context.emit('cancelModal') };
    const { user } = getUser()
    const worker = ref({});
    const opponents = ref([]);
    let unsubscribe = null;

    // Fetch the worker data first
    projectFirestore.collection('workers').doc(props.id).onSnapshot((workerDoc) => {
      worker.value = workerDoc.data();

      // Subscribe to workers in the same position
      unsubscribe = projectFirestore.collection('workers')
        .where('position', '==', worker.value.position)
        .where('id','!=',props.id)
        .where('isLive','==',true)
        
        .onSnapshot((snapshot) => {
          opponents.value = []; // Clear the opponents array first
          snapshot.forEach((workerDoc) => {
            if(workerDoc.data().ownerUid !== user.value.uid){
              opponents.value.push(workerDoc.data());
            }
          });
        });
    });

    // Unsubscribe when the component is unmounted
    onUnmounted(() => {
      if (unsubscribe) {
        unsubscribe();
      }
    });

    const opponentSelected = ref({id:''})
    const isPending = ref(false)
    const report = ref('')
    const handleBattle = e =>{
      const battle = projectFunctions.httpsCallable('battle')
      isPending.value = true
      battle({opponentSelected: opponentSelected.value, workerId: props.id}).then(result =>{
        isPending.value = false
        report.value = result.data
        if(report.value === 'คนงานของคุณเสียชีวิต'){
          context.emit('cancelModal')
        }
      }).catch(err =>{
        isPending.value = false
      })

    }

    return { isPending, report, handleBattle, cancelModal, worker, opponents,opponentSelected };
  },
};
</script>
