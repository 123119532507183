<template>
    <div class="p-3  bg-gray-900 rounded shadow ">
      <ModalTreasure v-if="toggleModal === 'ModalTreasure'" @cancelModal="toggleModal = ''" />
      <div>
        <div class="flex justify-center items-center"><img :src="treasure.img" class="w-40"></div>
        <div v-if="openTreasureCooldownHours < 0  && openTreasureCooldownMinutes < 0  && openTreasureCooldownSeconds < 0"  @click="toggleModal = 'ModalTreasure'" class="w-full text-center bg-yellow-600 hover:bg-yellow-500 text-white font-bold p-1  border-b-4 border-yellow-700 hover:border-yellow-600 rounded duration-200 cursor-pointer">เปิดกล่องสมบัติ</div>
        <div v-if="openTreasureCooldownHours >= 0  && openTreasureCooldownMinutes >= 0  && openTreasureCooldownSeconds >= 0" class="w-full text-center bg-red-700  text-white font-bold p-1  border-b-4 border-red-500 rounded duration-200 cursor-not-allowed">{{openTreasureCooldownHours}} ชั่วโมง {{ openTreasureCooldownMinutes }} นาที {{ openTreasureCooldownSeconds }} วินาที</div>
      </div>
    </div>
</template>
<script>
import { projectFirestore } from '@/firebase/config';
import { ref } from 'vue';
import ModalTreasure from './ModalTreasure.vue'
export default {
    components: { ModalTreasure },
    setup() {
      const toggleModal = ref('')
      const treasure = ref({})
      const openTreasureCooldownHours = ref(0);
      const openTreasureCooldownMinutes = ref(0);
      const openTreasureCooldownSeconds = ref(0);
      projectFirestore.collection('serverPublic').doc('treasure').onSnapshot(snapshot =>{
        treasure.value = snapshot.data()
        

        var countDownDate = snapshot.data().openTreasureCooldown.toDate().getTime() + 3000;
                var x = setInterval(() => {
                    var now = new Date().getTime();
                    var distance = countDownDate - now;
                    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    openTreasureCooldownHours.value = hours;
                    openTreasureCooldownMinutes.value = minutes;
                    openTreasureCooldownSeconds.value = seconds;
                    if (distance < 0) {
                        clearInterval(x);
                    }
                }, 0); // Setting interval for 1 second
            });

      return { toggleModal,treasure,openTreasureCooldownHours,
openTreasureCooldownMinutes,
openTreasureCooldownSeconds }
    },
}
</script>