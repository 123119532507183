<template>
    <div class="p-1 sm:p-4 sm:pb-16 pb-24 mb-auto h-full text-white  overflow-auto" id="style-1">
      <div class="grid grid-cols-1 sm:grid-cols-3 gap-1 sm:gap-3">
        <Information />
        <Character />
        <Menu />
        <CharacterInCave />
      </div>
    </div>
  </template>
  <script>
  import Information from '../components/cave/Information.vue'
  import Menu from '../components/cave/Menu.vue'
  import Character from '../components/cave/Character.vue'
  import PlayerInCave from '@/components/cave/PlayerInCave.vue';
  import CharacterInCave from '../components/cave/CharacterInCave.vue'
  export default {
    components: { CharacterInCave,Character,Menu,Information, },
      setup() {
         
      },
  }
  </script>