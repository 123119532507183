<template>
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex h-full pb-16 items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-xl">
          <div class="bg-gray-800 text-white p-3">

            <div v-if="!isPending" class="space-y-1.5">
              <div class="text-center text-sm text-gray-400">เลือกสถานที่</div>
              <div class="grid grid-cols-3 sm:grid-cols-4 gap-1 h-72 overflow-auto" id="style-1">
                <label v-for="location in locations" :key="location.value">
                  <!-- Check if the worker has the required map -->
                  <div v-if="location.requiredAccuracy <= workerAccuracy && (location.requiredMap === '' || workerMaps.includes(location.requiredMap))" 
                    :class="[position === location.name ? 'bg-lime-700' : '']" 
                    class="flex items-center p-2 rounded cursor-pointer bg-gray-900 hover:bg-lime-700 duration-300">
                    <input type="radio" v-model="position" :value="location.value" class="hidden">
                    <div class="w-full text-xs sm:text-sm space-y-1">
                      <div class="flex justify-center">
                        <div class="flex items-center space-x-1 justify-center border border-gray-700 rounded-lg px-1">
                          <div><img src="../../assets/images/star.png" class="w-2 sm:w-3"></div>
                          <div class="text-xs font-bold">{{ location.star }}</div>
                        </div>
                      </div>
                      <div class="flex justify-center"><img :src="location.img" class="w-6 sm:w-8"></div>
                      <div :class="[location.pvp ? 'text-red-500' : 'text-green-500']" class=" flex space-x-0.5 items-center justify-center text-gray-100 text-center">
                        <div v-if="location.pvp"><img src="../../assets/images/battle.png" class="w-3"></div>
                        <div>{{ location.name }}</div>  
                      </div>
                      <div class="flex justify-center items-center">
                        <div><img src="../../assets/images/cowboy.png" class="w-3 sm:w-4"></div>
                        <div class="font-bold">{{ location.count }}</div>
                      </div>
                    </div>
                  </div>
                  
                  <!-- If the worker does not have the required map -->
                  <div v-else class="flex items-center p-2 rounded cursor-not-allowed bg-red-900 duration-300">
                    <input type="radio" :value="location.value" class="hidden">
                    <div class="w-full text-xs space-y-1">
                      <div class="flex justify-center">
                        <div class="flex items-center space-x-1 justify-center border border-gray-300 rounded-lg px-1">
                          <div><img src="../../assets/images/star.png" class="w-2 sm:w-3"></div>
                          <div class="text-xs font-bold">{{ location.star }}</div>
                        </div>
                      </div>
                      <div class="flex justify-center"><img :src="location.img" class="w-6 sm:w-8"></div>
                      <div class=" text-gray-100 text-center">{{ location.name }} </div>
                      <div class="flex justify-center items-center space-x-1">
                        <div><img src="../../assets/images/cowboy.png" class="w-4 sm:w-5"></div>
                        <div v-if="location.requiredAccuracy <= workerAccuracy && (location.requiredMap === '' || workerMaps.includes(location.requiredMap))"  class="font-bold">{{ location.count }}</div>
                        <div v-else  class="font-bold">X</div>
                      </div>
                    </div>
                  </div>
                  
                </label>
              </div>
              <div v-if="report" class="text-center text-red-500 text-sm font-bold my-1">{{ report }}</div>
              <button v-if="position" @click="handleWorkerWork" type="button" class="w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">ทำงาน</button>
              <button @click="cancelModal" type="button" class="w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-white duration-300">ยกเลิก</button>
            </div>
            <div v-if="isPending" class="h-20 p-10 flex justify-center items-center space-x-1">
              <div class="text-xl font-bold animate-spin"><img src="../../assets/images/map.png" alt=""></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ref } from 'vue';
import { projectFirestore, projectFunctions } from '@/firebase/config';

export default {
  props: ['id'],
  setup(props, context) {
    const cancelModal = () => { context.emit('cancelModal'); };
    const workerAccuracy = ref(0)
    const workerMaps = ref([])
    projectFirestore.collection('workers').doc(props.id).get().then(snapshot =>{
      let workerData = snapshot.data()
      workerAccuracy.value = workerData.accuracy + workerData.itemAccuracy + workerData.collectionAccuracy + workerData.skillAccuracy
      workerMaps.value = snapshot.data().maps
    })
    
    const position = ref('');
    const isPending = ref(false);
    const report = ref('');

    const handleWorkerWork = () => {
      const workerWork = projectFunctions.httpsCallable('workerWork');
      isPending.value = true;
      workerWork({ position: position.value, workerId: props.id })
        .then(result => {
          report.value = result.data;
          isPending.value = false;
          if (result.data === 'เปลี่ยนสถานที่สำเร็จ') {
            context.emit('cancelModal');
          }
        })
        .catch(err => {
          isPending.value = false;
        });
    };

    const locations = ref([
      // { value: 'ปากทางเข้าเดอะฮิวจ์', name: 'ปากทางเข้าเดอะฮิวจ์', requiredMap: 'แผนที่เหมืองเดอะฮิวจ์', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
      { pvp: false, value: 'เดอะฮิวจ์', name: 'เดอะฮิวจ์', star: 1, requiredAccuracy: 0, requiredMap: '',  count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
      // { pvp: false, value: 'เดอะฮิวจ์2', name: 'เดอะฮิวจ์2', star: 1,requiredAccuracy: 0, requiredMap: '', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
      // { pvp: false, value: 'เดอะฮิวจ์3', name: 'เดอะฮิวจ์3', star: 1,requiredAccuracy: 0, requiredMap: '', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
      // { pvp: false, value: 'เดอะฮิวจ์4', name: 'เดอะฮิวจ์4', star: 1,requiredAccuracy: 0, requiredMap: '', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
      { pvp: true, value: 'ป่าเดวีคร็อกเก็ต', name: 'ป่าเดวีคร็อกเก็ต', star: 1,requiredAccuracy: 1, requiredMap: '', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/thief%2Fwildboar.png?alt=media&token=84e23e49-66ad-4366-9485-5d675b091cb8'},
      { pvp: true, value: 'เหมืองเดอะฮิวจ์', name: 'เหมืองเดอะฮิวจ์', star: 1,requiredAccuracy: 2, requiredMap: 'แผนที่เหมืองเดอะฮิวจ์', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
      { pvp: true, value: 'ถ้ำลึกลับ', name: 'ถ้ำลึกลับ', star: 1,requiredAccuracy: 0, requiredMap: '', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/thief%2Fjame.webp?alt=media&token=135e2e77-376d-4461-ab58-dcc39ad8aa91'},
      // { pvp: true, value: 'ถ้ำลึกลับ2', name: 'ถ้ำลึกลับ2', star: 1,requiredAccuracy: 0, requiredMap: '', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/thief%2Fjame.webp?alt=media&token=135e2e77-376d-4461-ab58-dcc39ad8aa91'},
      { pvp: false, value: 'บูทฮิลล์', name: 'บูทฮิลล์', star: 2,requiredAccuracy: 5, count: 0, requiredMap: '', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc' },
      // { pvp: false, value: 'บูทฮิลล์2', name: 'บูทฮิลล์2', star: 2,requiredAccuracy: 5, count: 0, requiredMap: '', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc' },
      // { pvp: false, value: 'บูทฮิลล์3', name: 'บูทฮิลล์3', star: 2,requiredAccuracy: 5, count: 0, requiredMap: '', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc' },
      // { pvp: false, value: 'บูทฮิลล์4', name: 'บูทฮิลล์4',requiredAccuracy: 5, requiredMap: '', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc' },
      // { pvp: false, value: 'บูทฮิลล์5', name: 'บูทฮิลล์5',requiredAccuracy: 5, requiredMap: '', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc' },
      { pvp: true, value: 'ป่าซานฮวน', name: 'ป่าซานฮวน', star: 2,requiredAccuracy: 5, count: 0, requiredMap: 'แผนที่ป่าซานฮวน', img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/thief%2Fwolf.png?alt=media&token=36f49bba-68c7-4f61-b519-aa5de8fd9f94' },
      { pvp: false, value: 'เดดวูด', name: 'เดดวูด', star: 3,requiredAccuracy: 8, requiredMap: '', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
      // { pvp: false, value: 'เดดวูด2', name: 'เดดวูด2', star: 3,requiredAccuracy: 8, requiredMap: '', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
      { pvp: true, value: 'ป่าดิบชื้นเดดวูด', name: 'ป่าดิบชื้นเดดวูด', star: 3,requiredAccuracy: 10, requiredMap: 'แผนที่ป่าดิบชื้นเดดวูด', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
      { pvp: false, value: 'ดอดจ์ซิตี้', name: 'ดอดจ์ซิตี้', star: 4,requiredAccuracy: 12, requiredMap: '', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmason.png?alt=media&token=60f49aee-73e9-454f-b492-eb9f4b54c723'},
      { pvp: false, value: 'ทูมสโตน', name: 'ทูมสโตน', star: 5,requiredAccuracy: 15, requiredMap: '', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3' },
      { pvp: true, value: 'ทะเลทรายแห่งความตาย', name: 'ทะเลทรายแห่งความตาย', star: 5,requiredAccuracy: 17, requiredMap: 'แผนที่ทะเลทรายแห่งความตาย', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3' },
      { pvp: false, value: 'เดเซิร์ทโรส', name: 'เดเซิร์ทโรส', star: 6,requiredAccuracy: 18, requiredMap: '', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Flucas.png?alt=media&token=77e7d979-3680-415f-a2d4-3588cae83e91' },
      { pvp: false, value: 'วิสกี้ริเวอร์', name: 'วิสกี้ริเวอร์', star: 7,requiredAccuracy: 21, requiredMap: '', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5' },
      { pvp: true, value: 'หมู่บ้านโจรสลัด', name: 'หมู่บ้านโจรสลัด', star: 7,requiredAccuracy: 23, requiredMap: 'แผนที่หมู่บ้านโจรสลัด', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5' },
      { pvp: false, value: 'เอาท์ลอว์ส์เฮเว่น', name: 'เอาท์ลอว์ส์เฮเว่น', star: 8,requiredAccuracy: 24, requiredMap: '', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce' },
      { pvp: true, value: 'ตรอกซอยลับเจ้าพ่อ', name: 'ตรอกซอยลับเจ้าพ่อ', star: 8,requiredAccuracy: 26, requiredMap: 'แผนที่ตรอกซอยลับเจ้าพ่อ', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce' },
      { pvp: false, value: 'โอกลาโฮม่า', name: 'โอกลาโฮม่า', star: 9,requiredAccuracy: 31, requiredMap: '', count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/thief%2Ftakuma.webp?alt=media&token=e2edd75c-5e80-4cc4-8ebd-010f1cee772a' },
      { pvp: true,value: 'วิหารสมบัติ', name: 'วิหารสมบัติ', star: 1, requiredAccuracy: 0, requiredMap: '',  count: 0, img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/thief%2Fcrimson.webp?alt=media&token=f70af968-9303-49c2-8ff3-22c3944a8e4e'},
    ]);

    const updateLocationCounts = (snapshot, locationKey) => {
      const location = locations.value.find(loc => loc.value === locationKey);
      if (location) {
        location.count = snapshot.data().id.length;
      }
    };
    
    projectFirestore.collection('serverPublic').doc('workerInTempleOfTreasure').onSnapshot(snapshot => {
      updateLocationCounts(snapshot, 'วิหารสมบัติ');
    });
    projectFirestore.collection('serverPublic').doc('workerInTheHews').onSnapshot(snapshot => {
      updateLocationCounts(snapshot, 'เดอะฮิวจ์');
    });
    projectFirestore.collection('serverPublic').doc('workerInTheHews2').onSnapshot(snapshot => {
      updateLocationCounts(snapshot, 'เดอะฮิวจ์2');
    });
    projectFirestore.collection('serverPublic').doc('workerInTheHews3').onSnapshot(snapshot => {
      updateLocationCounts(snapshot, 'เดอะฮิวจ์3');
    });
    projectFirestore.collection('serverPublic').doc('workerInTheHews4').onSnapshot(snapshot => {
      updateLocationCounts(snapshot, 'เดอะฮิวจ์4');
    });
    projectFirestore.collection('serverPublic').doc('workerInTheHewsMine').onSnapshot(snapshot => {
      updateLocationCounts(snapshot, 'เหมืองเดอะฮิวจ์');
    });
    projectFirestore.collection('serverPublic').doc('workerInDavyCrockettForest').onSnapshot(snapshot => {
      updateLocationCounts(snapshot, 'ป่าเดวีคร็อกเก็ต');
    });
    projectFirestore.collection('serverPublic').doc('workerInMysteriousCave').onSnapshot(snapshot => {
      updateLocationCounts(snapshot, 'ถ้ำลึกลับ');
    });
    projectFirestore.collection('serverPublic').doc('workerInMysteriousCave2').onSnapshot(snapshot => {
      updateLocationCounts(snapshot, 'ถ้ำลึกลับ2');
    });
    projectFirestore.collection('serverPublic').doc('workerInBootHill').onSnapshot(snapshot => {
      updateLocationCounts(snapshot, 'บูทฮิลล์');
    });
    projectFirestore.collection('serverPublic').doc('workerInBootHill2').onSnapshot(snapshot => {
      updateLocationCounts(snapshot, 'บูทฮิลล์2');
    });
    projectFirestore.collection('serverPublic').doc('workerInBootHill3').onSnapshot(snapshot => {
      updateLocationCounts(snapshot, 'บูทฮิลล์3');
    });
    projectFirestore.collection('serverPublic').doc('workerInBootHill4').onSnapshot(snapshot => {
      updateLocationCounts(snapshot, 'บูทฮิลล์4');
    });
    projectFirestore.collection('serverPublic').doc('workerInSanJuanForest').onSnapshot(snapshot => {
      updateLocationCounts(snapshot, 'ป่าซานฮวน');
    });
    projectFirestore.collection('serverPublic').doc('workerInDeadWood').get().then(snapshot => {
      updateLocationCounts(snapshot, 'เดดวูด');
    });
    projectFirestore.collection('serverPublic').doc('workerInDeadWood2').get().then(snapshot => {
      updateLocationCounts(snapshot, 'เดดวูด2');
    });
    projectFirestore.collection('serverPublic').doc('workerInRainForestDeadWood').get().then(snapshot => {
      updateLocationCounts(snapshot, 'ป่าดิบชื้นเดดวูด');
    });
    projectFirestore.collection('serverPublic').doc('workerInTombStone').get().then(snapshot => {
      updateLocationCounts(snapshot, 'ทูมสโตน');
    });
    projectFirestore.collection('serverPublic').doc('workerInDeadDesert').get().then(snapshot => {
      updateLocationCounts(snapshot, 'ทะเลทรายแห่งความตาย');
    });
    projectFirestore.collection('serverPublic').doc('workerInDogeCity').get().then(snapshot => {
      updateLocationCounts(snapshot, 'ดอดจ์ซิตี้');
    });
    projectFirestore.collection('serverPublic').doc('workerInDesertRose').get().then(snapshot => {
      updateLocationCounts(snapshot, 'เดเซิร์ทโรส');
    });
    projectFirestore.collection('serverPublic').doc('workerInWhiskeyRiver').get().then(snapshot => {
      updateLocationCounts(snapshot, 'วิสกี้ริเวอร์');
    });
    projectFirestore.collection('serverPublic').doc('workerInPirateVillage').get().then(snapshot => {
      updateLocationCounts(snapshot, 'หมู่บ้านโจรสลัด');
    });
    projectFirestore.collection('serverPublic').doc('workerInOutlawHaven').get().then(snapshot => {
      updateLocationCounts(snapshot, 'เอาท์ลอว์ส์เฮเว่น');
    });
    projectFirestore.collection('serverPublic').doc('workerInGodFatherStreet').get().then(snapshot => {
      updateLocationCounts(snapshot, 'ตรอกซอยลับเจ้าพ่อ');
    });
    projectFirestore.collection('serverPublic').doc('workerInOklahoma').get().then(snapshot => {
      updateLocationCounts(snapshot, 'โอกลาโฮม่า');
    });
    projectFirestore.collection('serverPublic').doc('workerInRockyMine').get().then(snapshot => {
      updateLocationCounts(snapshot, 'ร็อคกี้มาย');
    });

    return { workerMaps,workerAccuracy, locations, cancelModal, handleWorkerWork, position, isPending, report };
  },
};
</script>
