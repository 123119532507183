fire<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-full pb-16 items-end justify-center p-2 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div>
            <div class="text-gray-300">เลือกรูปโปรไฟล์</div>
            <div class="grid grid-cols-8">
              <div v-for="img in imgs" :key="img">
                <div :class="[imgSelected === img ? 'bg-indigo-900' : ' hover:bg-indigo-900']" @click="imgSelected = img" class=" rounded-full cursor-pointer"><img :src="img" alt=""></div>
              </div>
            </div>
            <div class="text-gray-300">ชื่อที่แสดง({{ name.length }}/8)</div>
            <div><input v-model="name" type="text" class="p-2 w-full rounded bg-gray-200 text-gray-900" placeholder="กรอกชื่อของคุณ"></div>
            <div class="text-gray-300">อัพเดทประวัติของคุณ({{ biography.length }}/300)</div>
            <div><textarea v-model="biography" class="p-1 w-full h-12 rounded bg-gray-200 text-gray-900" placeholder="เขียนอะไรก็ได้เท่ๆ"></textarea></div>
            <div class="text-gray-300">เฟสบุ้ค({{ facebook.length }}/30)</div>
            <div><input v-model="facebook" type="text" class="p-2 w-full rounded bg-gray-200 text-gray-900" placeholder="ไม่กรอกก็ได้"></div>
            <div class="text-gray-300">อินสตาแกรม({{ instagram.length }}/20)</div>
            <div><input v-model="instagram" type="text" class="p-2 w-full rounded bg-gray-200 text-gray-900" placeholder="ไม่กรอกก็ได้"></div>
          </div>
          <div :class="[report === 'แก้ไขข้อมูลสำเร็จ' ? 'text-green-500' : 'text-red-500']" @click="report =''" class="text-center text-sm">{{ report }}</div>
          <div class="mt-3">
          <button v-if="!isPending" @click="handleUpdateUser" type="button" class="w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">อัพเดท</button>
          <button v-if="isPending" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300 cursor-not-allowed">Loading...</button>
          <button v-if="!isPending" @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
        </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
import getUser from '@/composables/getUser'
import { projectFirestore, projectFunctions } from '@/firebase/config'
  export default {
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}
        const { user } = getUser()
        const imgs = ref([
            'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fpeter.png?alt=media&token=58cd2ce7-ac69-4da0-96e9-82970737f3a2',
            'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fpierre.png?alt=media&token=b9f093b8-72e7-4a23-8f5d-41bb82d8f60e',
            'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fmark.png?alt=media&token=2d684ac2-b85d-4ea0-9e88-a272117daf7f',
            'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fnathan.png?alt=media&token=1fe18fb4-43df-4bcb-b2d3-8d7fca18897e',
            'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fjuan.png?alt=media&token=9222f167-03de-4d50-8ef6-fbaaafde7c83',
            'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fdakota.png?alt=media&token=21a37af6-2cb6-4bdc-9668-9ae44a20d680',
            'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fkofi.png?alt=media&token=fef78eb4-f520-4c0f-bc55-d8a2231abf17',
            'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fwong.png?alt=media&token=6aa6e3b2-c61b-46f1-9aa4-e8e181425e22',
            'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fkatherine.png?alt=media&token=a05ca758-04e1-40a0-9814-27a8e507d841',
            'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fhouston.png?alt=media&token=4bcf5ded-6b02-4e65-8f6f-72325884d1ad',
            'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc',
            'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0',
            'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmason.png?alt=media&token=60f49aee-73e9-454f-b492-eb9f4b54c723',
            'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3',
            'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Flucas.png?alt=media&token=77e7d979-3680-415f-a2d4-3588cae83e91',
            'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5',
            'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce'
        ])

        const imgSelected = ref('')
        const name = ref('')
        const biography = ref('')
        const facebook = ref('')
        const instagram = ref('')
        
        projectFirestore.collection('users').doc(user.value.uid).onSnapshot(userDoc =>{
          imgSelected.value = userDoc.data().profile
          name.value = userDoc.data().name
          biography.value = userDoc.data().biography
          facebook.value = userDoc.data().facebook
          instagram.value = userDoc.data().instagram
        })

        const isPending = ref(false)
        const report = ref('')
        const handleUpdateUser = e =>{
          const updateUser = projectFunctions.httpsCallable('updateUser')
          isPending.value = true
          updateUser({
            imgSelected: imgSelected.value,
            biography: biography.value,
            name: name.value,
            facebook: facebook.value,
            instagram: instagram.value
          }).then(result =>{
            isPending.value = false
            report.value = result.data
          }).catch(err =>{
            isPending.value = false
          })
        }


        return { handleUpdateUser, isPending, report, cancelModal,imgs,imgSelected,name,biography,facebook,instagram }
    },
  }
  </script>