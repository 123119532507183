<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex h-4/5 items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
            <div class="bg-gray-800 text-white p-3">
              <div>
                <div v-if="!itemSelected.id">
                  <div v-if="items.length >= 1">
                    <div class="text-center font-bold mb-1">เลือกอุปกรณ์ที่คุณต้องการแลกอาหารสัตว์</div>
                    <div :class="[items.length >= 6 ? 'h-60' : 'h-auto']" class="grid gird-cols-1 h-60 overflow-auto" id="style-1">
                      <div v-for="item in items" :key="item.id">
                        <div @click="handleSelectItem(item.id,item)" class="flex items-center space-x-1 justify-between border-b border-gray-700 hover:bg-gray-900 cursor-pointer duration-300">
                          <div class="flex items-center space-x-1">
                            <div><img :src="item.img" class="w-8 sm:w-10"></div>
                            <div class="flex items-center space-x-1 font-bold">
                              <div class="flex items-center space-x-1 border rounded border-gray-600 px-0.5">
                                <div><img src="../../assets/images/star.png" class="w-3"></div>
                                <div class="text-xs sm:text-sm">{{ item.star }}</div>
                              </div>
                              <div class="text-xs sm:text-sm">{{ item.name }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="itemSelected.id">
            <div class="flex items-center">
              <div class="w-1/2">
                <div class="flex justify-center items-center mb-1"><img :src="itemSelected.img" class="w-24"></div>
                <div class="flex justify-center items-center space-x-1">
                  <div class="flex items-center justify-center space-x-1 px-2 border rounded border-gray-700">
                    <div><img src="../../assets/images/star.png" class="w-3"></div>
                    <div class="font-bold text-xs sm:text-sm">{{ itemSelected.star }}</div>
                  </div>
                  <div class="text-center font-bold text-xs sm:text-sm">{{ itemSelected.name }}</div>
                </div>
              </div>
              <div>
                <div><img src="../../assets/images/greenrightarrow.png" class="w-10 animate-pulse"></div>
              </div>
              <div class="w-1/2">
                
                <div class="flex justify-center items-center mb-1"><img src="../../assets/images/haybale.png" class="w-24 p-1  rounded-full"></div>
                <div class="flex justify-center items-center space-x-1 font-bold">
                  <div class="text-center font-bold text-xs sm:text-sm">{{ amountOfAnimalFood }}</div>
                  <div>หญ้าแห้ง</div>
                </div>
              </div>
            </div>
            
          </div>
              </div>
              <div class="mt-2">
                <button v-if="!isPending && !itemSelected.id && items.length >= 1" type="button" class="w-full justify-center rounded bg-red-900 px-3 py-2 text-sm font-semibold text-white shadow-sm cursor-not-allowed">กรุณาเลือกไอเทม</button>
                <button v-if="!isPending && itemSelected.id && items.length >= 1" @click="handleExchangeAnimalFood" type="button" class="w-full justify-center rounded bg-indigo-900 hover:bg-indigo-800 px-3 py-2 text-sm font-semibold text-white shadow-sm cursor-pointer duration-300">แลกอาหารสัตว์</button>
                <button v-if="isPending && itemSelected.id && items.length >= 1" type="button" class="w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm  cursor-not-allowed ">Loading...</button>
                <button v-if="itemSelected.id && !isPending" @click=" itemSelected = {id: ''}" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-white duration-300">ย้อนกลับ</button>
                <button v-if="!itemSelected.id" @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-white duration-300">ยกเลิก</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import getUser from '@/composables/getUser';
  import { projectFirestore, projectFunctions } from '@/firebase/config';
  import { ref } from 'vue';
  export default {
    setup(props, context) {
      const cancelModal = e => { context.emit('cancelModal'); };
      const { user } = getUser();
  
      const items = ref([]);
      projectFirestore.collection('items')
        .where('ownerUid', '==', user.value.uid)
        .where('used', '==', false)
        .orderBy('star', 'asc')
        .orderBy('type')
        .onSnapshot(snapshot => {
          items.value = [];
  
          snapshot.forEach(itemDoc => {
            let itemData = itemDoc.data();
            if (itemData.type !== 'map' && itemData.type !== 'trash' && itemData.type !== 'pet' &&
              itemData.type !== 'glasses' && itemData.type !== 'armlet' && itemData.type !== 'amulet' &&
              itemData.type !== 'necklace' && itemData.type !== 'compass' && itemData.type !== 'collection' &&
              itemData.type !== 'emblem' && itemData.type !== 'waist' && itemData.type !== 'ring' &&
              itemData.type !== 'glove' && itemData.type !== 'armor' && itemData.name !== 'เสื้อเน่า' &&
              itemData.name !== 'กางเกงเน่า' && itemData.name !== 'รองเท้าเน่า' && itemData.name !== 'หมวกเน่า' &&
              itemData.name !== 'ท่อนไม้' &&
              itemData.type !== 'animalfood') {
              itemData.id = itemDoc.id;
              items.value.push(itemData);
            }
          });
        });
  
      const foodExchangeRates = ref([
        { star: 1, amount: 1 },
        { star: 2, amount: 2 },
        { star: 3, amount: 6 },
        { star: 4, amount: 8 },
        { star: 5, amount: 10 },
        { star: 6, amount: 12 },
        { star: 7, amount: 14 },
        { star: 8, amount: 16 },
        { star: 9, amount: 18 },
        { star: 10, amount: 20 }
      ]);
  
      const isPending = ref(false);
      const itemId = ref();
      const itemSelected = ref({ id: '' });
      const amountOfAnimalFood = ref(0);
      const report = ref('')
  
      const handleSelectItem = (id, item) => {
        itemId.value = id;
        itemSelected.value = item;
        const foodRate = foodExchangeRates.value.find(rate => rate.star === item.star);
        amountOfAnimalFood.value = foodRate.amount
      };

      const handleExchangeAnimalFood = e =>{
        const exchangeAnimalFood = projectFunctions.httpsCallable('exchangeAnimalFood')
        isPending.value = true
        exchangeAnimalFood({itemId: itemId.value}).then(result =>{
            report.value = result.data.message
            isPending.value = false
        }).catch(err =>{
            report.value = err.meesage
        })
      }
  
      return {
        handleExchangeAnimalFood,
        handleSelectItem,
        itemSelected,
        isPending,
        itemId,
        cancelModal,
        items,
        foodExchangeRates,
        amountOfAnimalFood
      };
    }
  };
  </script>
  