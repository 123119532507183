<template>
    <div class="bg-gray-900 p-1  rounded">
      <div class="text-center text-gray-400 text-sm">
        <div>ไอเทม</div>
        <div>(ดับเบิ้ลคลิกเพื่อถอดไอเทม)</div>
      </div>
      <div class="grid grid-cols-3  gap-0.5 text-center my-0.5">
        <div v-if="!workerTypeItemUsed.includes('weapon')" class="block p-0 sm:p-2  space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/images/sword.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่อาวุธ</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('offhand')" class="block p-0 sm:p-2  space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/images/woodshield.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่อาวุธรอง</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('glove')" class="block p-0 sm:p-2  space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/shop/gloves.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่ถุงมือ</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('ring')" class="block  p-0 sm:p-2 space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/shop/ring.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่แหวน</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('head')" class="block p-0 sm:p-2  space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/images/head.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่หมวก</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('glasses')" class="block p-0 sm:p-2  space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/images/glasses.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่แว่นตา</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('mouth')" class="block p-0 sm:p-2  space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/images/cigar.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่ส่วนปาก</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('cape')" class="block p-0 sm:p-2  space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/images/cape.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่ผ้าคลุม</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('neck')" class="block p-0 sm:p-2  space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/images/neck.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่ผ้าพันคอ</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('necklace')" class="block  p-0 sm:p-2 space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/images/necklace.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่สร้อยคอ</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('body')" class="block p-0 sm:p-2  space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/images/body.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่เสื้อ</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('armor')" class="block p-0 sm:p-2 space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/shop/holster.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่เกาะ</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('bag')" class="block p-0 sm:p-2 space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/images/haversack.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่กระเป๋า</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('armlet')" class="block p-0 sm:p-2  space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/images/armlet.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่ปลอกแขน</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('waist')" class="block p-0 sm:p-2 space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/shop/belt.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่เข็มขัด</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('bottom')" class="block p-0 sm:p-2 space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/images/bottom.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่กางเกง</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('foots')" class="block p-0 sm:p-2 space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/images/foots.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่รองเท้า</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('lasso')" class="block p-0 sm:p-2 space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/images/lasso.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่เชือก</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('compass')" class="block p-0 sm:p-2 space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/images/compass.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่เข็มทิศ</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('horse')" class="block p-0 sm:p-2 space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/images/horse.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ขี่ม้า</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('emblem')" class="block p-0 sm:p-2  space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/thief/jacob.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่ตราสัญลักษณ์</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('amulet')" class="block p-0 sm:p-2  space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/images/amulet.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่เครื่องราง</div>
        </div>
        <div v-if="!workerTypeItemUsed.includes('pet')" class="block p-0 sm:p-2  space-x-1 justify-center items-center bg-red-800 rounded">
          <div class="flex justify-center"><img src="../../assets/images/germanshepherd.png" class="w-6 sm:w-7 opacity-50"></div>
          <div class="text-xs sm:text-xs">ไม่ได้ใส่สัตว์เลี้ยง</div>
        </div>
        <div v-for="item in workerItems" :key="item">
            <ItemUsed :itemId="item" :id="id" />
          </div>
      </div>

    </div>
</template>
<script>
import { ref } from 'vue'
import { projectFirestore } from '@/firebase/config'
import ItemUsed from '../../components/Worker/ItemUsed.vue'
export default {
  props: ['id'],
    components: { ItemUsed },
    setup(props) {
      const worker = ref({})
      const workerTypeItemUsed = ref([])
      const workerItems = ref([])
      projectFirestore.collection('workers').doc(props.id).onSnapshot(workerDoc =>{
        worker.value = workerDoc.data()
        workerTypeItemUsed.value = workerDoc.data().typeItemUsed
        workerItems.value = workerDoc.data().items
      })

      return { workerItems,worker,workerTypeItemUsed }
        
    },
}
</script>