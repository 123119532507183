<template>
  <ModalWork v-if="toggleModal === 'ModalProfile'" :workerId="workerId" @cancelModal="toggleModal =''" />
  <div class="w-full m-auto">
    <div class="overflow-x-auto">
      <div>
        <select @change="changeFilter" class="w-full rounded p-2 text-gray-900 bg-gray-300">
          <option v-for="option in filterOptions" :key="option.filter" :value="option.filter">{{ option.message }}</option>
        </select>
      </div>
      <table class="min-w-full bg-gray-800 rounded-lg shadow-lg mt-2">
        <thead>
          <tr class="bg-indigo-900 text-left text-gray-300">
            <th class="py-3 px-6">ชื่อ</th>
            <th v-if="filterSelected === 'power'" class="py-3 px-6 text-center cursor-pointer">พลังรวม</th>
            <th v-if="filterSelected === 'itemPower'" class="py-3 px-6 text-center cursor-pointer">พลังไอเทม</th>
            <th v-if="filterSelected === 'kill'" class="py-3 px-6 text-center cursor-pointer">สังหารโจร</th>
            <th v-if="filterSelected === 'silverPrize'" class="py-3 px-6 text-center cursor-pointer">รางวัลเหรียญ</th>
            <th v-if="filterSelected === 'killWorker'" class="py-3 px-6 text-center cursor-pointer">สังหารคนงาน</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(worker, index) in workers" :key="worker.ownerUid" @click="handleModal('ModalProfile', worker.id)" class="border-b border-gray-700 bg-gray-900 hover:bg-opacity-80 cursor-pointer">
            <td class="flex items-center space-x-1 py-3 px-6 font-bold uppercase">
              <div class="w-10 bg-gray-700 text-center rounded-lg text-xs p-0.5 font-normal sm:font-bold">{{ index + 1 }}</div>
              <div><img :src="worker.img" class="w-8 sm:w-10"></div>
              <div v-if="worker.vip.toDate() >= new Date() && admin"><img src="../assets/images/vip.png" class="w-4 sm:w-5"></div>
              <div class="border border-gray-700 flex items-center justify-center space-x-1 rounded-lg p-0.5">
                <div><img src="../assets/images/star.png" class="w-3 sm:w-4"></div>
                <div class="text-xs">{{ worker.star }}</div>
              </div>
              <div :class="worker.textColor" class="font-normal sm:font-fold text-xs sm:text-sm">{{ worker.name }}</div>
            </td>
            <td v-if="filterSelected === 'power'" class="py-3 px-6 text-center">{{ worker.power }}</td>
            <td v-if="filterSelected === 'itemPower'" class="py-3 px-6 text-center">{{ worker.itemPower }}</td>
            <td v-if="filterSelected === 'kill'" class="py-3 px-6 text-center">{{ worker.kill }}</td>
            <td v-if="filterSelected === 'silverPrize'" class="py-3 px-6 text-center">{{ worker.silverPrize }}</td>
            <td v-if="filterSelected === 'killWorker'" class="py-3 px-6 text-center">{{ worker.killWorker }}</td>
          </tr>
        </tbody>
      </table>
      <button @click="loadMore" class="w-full py-2 mt-2 text-center text-gray-900 bg-gray-300 rounded">Load More</button>
    </div>
  </div>
</template>

<script>
import { projectFirestore } from '@/firebase/config'
import { ref, watch } from 'vue'
import ModalWork from './ModalWorker.vue'
import getUser from '@/composables/getUser'

export default {
  components: { ModalWork },
  setup() {
    const { admin } = getUser()
    const filterOptions = ref([
      { filter: 'power', message: 'พลังรวม' },
      { filter: 'itemPower', message: 'พลังไอเทม' },
      { filter: 'kill', message: 'สังหารโจร' },
      { filter: 'silverPrize', message: 'รางวัลเหรียญ' },
      { filter: 'killWorker', message: 'สังหารคนงาน' }
    ])
    const filterSelected = ref('power')
    const workers = ref([])
    const lastVisible = ref(null)

    const fetchWorkers = (reset = false) => {
      let query = projectFirestore.collection('workers')
        .orderBy(filterSelected.value, 'desc')
        .orderBy('createdAt', 'asc')
        .limit(15)

      if (lastVisible.value && !reset) {
        query = query.startAfter(lastVisible.value)
      }

      query.get().then(snapshot => {
        if (snapshot.empty) {
          // No more workers to load
          return
        }
        const newWorkers = []
        snapshot.forEach(workerDoc => {
          newWorkers.push({ id: workerDoc.id, ...workerDoc.data() })
        })
        if (reset) {
          workers.value = newWorkers
        } else {
          workers.value = [...workers.value, ...newWorkers]
        }
        lastVisible.value = snapshot.docs[snapshot.docs.length - 1]
      })
    }

    watch(filterSelected, () => {
      workers.value = []
      lastVisible.value = null
      fetchWorkers(true)
    }, { immediate: true })

    const changeFilter = (event) => {
      filterSelected.value = event.target.value
    }

    const workerId = ref('')
    const toggleModal = ref('')
    const handleModal = (modal, id) => {
      toggleModal.value = modal
      workerId.value = id
    }

    const loadMore = () => {
      fetchWorkers()
    }

    return { admin,filterOptions, workers, filterSelected, changeFilter, workerId, toggleModal, handleModal, loadMore }
  }
}
</script>

<style scoped>
#style-1 {
  scrollbar-width: thin;
  scrollbar-color: #6b7280 #2d3748;
}

#style-1::-webkit-scrollbar {
  width: 12px;
}

#style-1::-webkit-scrollbar-track {
  background: #2d3748;
}

#style-1::-webkit-scrollbar-thumb {
  background-color: #6b7280;
  border-radius: 6px;
  border: 3px solid #2d3748;
}
</style>
