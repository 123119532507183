<template>
    <div class="relative overflow-x-auto rounded-lg">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-sm text-gray-100 text-center uppercase bg-gray-900 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-1.5">วันที่</th>
                    <th scope="col" class="px-6 py-1.5">คีย์คนงานดรอป</th>
                    <th scope="col" class="px-6 py-1.5">คีย์สัตว์เลี้ยงดรอป</th>
                    <th scope="col" class="px-6 py-1.5">แลกเปลี่ยนเหรียญ</th>
                    <th scope="col" class="px-6 py-1.5">เหรียญดรอป</th>
                    <th scope="col" class="px-6 py-1.5">เปิดกล่องคนงาน</th>
                    <th scope="col" class="px-6 py-1.5">เปิดกล่องสัตว์เลี้ยง</th>
                    <th scope="col" class="px-6 py-1.5">แลกเปลี่ยนคนงาน</th>
                    <th scope="col" class="px-6 py-1.5">แลกเปลี่ยนไอเทม</th>
                    <th scope="col" class="px-6 py-1.5">สังหารโจร</th>
                    <th scope="col" class="px-6 py-1.5">สังหารคนงาน</th>
                    <th scope="col" class="px-6 py-1.5">ไอเทมดรอป</th>
                    <th scope="col" class="px-6 py-1.5">ของสะสมดรอป</th>
                    <th scope="col" class="px-6 py-1.5">ซื้อกล่องคนงาน</th>
                    <th scope="col" class="px-6 py-1.5">เรียนรู้ทักษะ</th>
                    <th scope="col" class="px-6 py-1.5">ซื้อไอเทม</th>
                    <th scope="col" class="px-6 py-1.5">อัพเกรด</th>
                    <th scope="col" class="px-6 py-1.5">แยกชิ้นส่วน</th>
                    <th scope="col" class="px-6 py-1.5">ซื้อตั๋ว</th>
                    <th scope="col" class="px-6 py-1.5">คนงานเสียชีวิต</th>
                    <th scope="col" class="px-6 py-1.5">คนงานเกิดใหม่</th>
                    <th scope="col" class="px-6 py-1.5">ซื้อของในตลาด</th>
                    <th scope="col" class="px-6 py-1.5">ภาษี</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(stat, index) in stats" :key="index" class="bg-gray-600 hover:bg-gray-500 text-center duration-300 border-gray-500 border-b text-gray-100 cursor-pointer">
                    <td class="px-6 py-2">{{ formatDate(stat.createdAt.toDate()) }}</td>
                    <td class="px-6 py-2">{{ stat.workerKeyDrop }}</td>
                    <td class="px-6 py-2">{{ stat.petKeyDrop }}</td>
                    <td class="px-6 py-2">{{ formatSilverPrize(stat.tranferSilver) }} ({{ stat.tranferSilverAmount }})</td>
                    <td class="px-6 py-2">{{ formatSilverPrize(stat.coinDrop) }}</td>
                    <td class="px-6 py-2">{{ stat.openWorkerBox }}</td>
                    <td class="px-6 py-2">{{ stat.openPetBox }}</td>
                    <td class="px-6 py-2">{{ stat.tranferWorker }}</td>
                    <td class="px-6 py-2">{{ stat.tranferItem }}</td>
                    <td class="px-6 py-2">{{ formatSilverPrize(stat.killThief) }}</td>
                    <td class="px-6 py-2">{{ formatSilverPrize(stat.killWorker) }}</td>
                    <td class="px-6 py-2">{{ stat.itemDrop }}</td>
                    <td class="px-6 py-2">{{ stat.collectionDrop }}</td>
                    <td class="px-6 py-2">{{ formatSilverPrize(stat.openSilverWorkerBox) }}</td>
                    <td class="px-6 py-2">{{ formatSilverPrize(stat.learnSkill) }}</td>
                    <td class="px-6 py-2">{{ formatSilverPrize(stat.buyItem) }}</td>
                    <td class="px-6 py-2">{{ stat.successUpgrade }}/{{ stat.failUpgrade }}</td>
                    <td class="px-6 py-2">{{ stat.successDisassemble }}/{{ stat.failDisassemble }}</td>
                    <td class="px-6 py-2">{{ stat.buyTicket }}</td>
                    <td class="px-6 py-2">{{ stat.workerDead }}</td>
                    <td class="px-6 py-2">{{ stat.workerBorn }}</td>
                    <td class="px-6 py-2">{{ formatSilverPrize(stat.buyMarketplace) }}({{ stat.amountBuyMarketplace }})</td>
                    <td class="px-6 py-2">{{ formatSilverPrize(stat.marketplaceFee) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { ref } from 'vue'
import { projectFirestore } from '@/firebase/config'
import dayjs from 'dayjs';

export default {
    setup() {
        const stats = ref([])
        const unsubscribe = projectFirestore.collection('stats').orderBy('createdAt','desc').limit(10)
            .get()
            .then(snapshot => {
                stats.value = []; // Clear the array before repopulating
                snapshot.forEach(statDoc => {
                    stats.value.push(statDoc.data());
                });
            });
        const formatDate = date => {
            return dayjs(date).format('DD/MM/YYYY');
        }

        const formatSilverPrize = (silverPrize) => {
          if (silverPrize >= 1000000) return (silverPrize / 1000000).toFixed(1) + 'm';
          if (silverPrize >= 100000) return (silverPrize / 1000).toFixed(0) + 'k';
          if (silverPrize >= 1000) return (silverPrize / 1000).toFixed(1) + 'k'; // Update this line
          return silverPrize;
        };



        return { dayjs,stats, formatDate,formatSilverPrize }
    },
}
</script>