<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-full pb-16 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div v-if="!itemSelected.id">
            <div v-if="items.length >= 1">
              <div class="text-center font-bold mb-1">เลือกอุปกรณ์ที่คุณต้องการแยกชิ้นส่วน</div>
              <div :class="[items.length >= 6 ? 'h-60' : 'h-auto']" class="grid gird-cols-1 h-60 overflow-auto" id="style-1">
                <div v-for="item in items" :key="item">
                  <div @click="handleSelectItem(item.id,item)" class="flex items-center spaec-x-1 justify-between border-b border-gray-700 hover:bg-gray-900 cursor-pointer duration-300">
                    <div class="flex items-center space-x-1">
                      <div><img :src="item.img" class="w-8 sm:w-10"></div>
                      <div class="flex items-center space-x-1 font-bold">
                        <div class="flex items-center space-x-1 border rounded border-gray-600 px-0.5">
                          <div><img src="../../assets/images/star.png" class="w-3"></div>
                          <div class="text-xs sm:text-sm">{{ item.star }}</div>
                        </div>
                        <div class="text-xs sm:text-sm">{{ item.name }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="items < 1" class="h-20 flex justify-center items-center">
              <div class="text-xl font-bold opacity-50">ไม่พบไอเทมที่สามารถแยกชิ้นส่วนได้</div>
            </div>
          </div>
          <div v-if="itemSelected.id">
            <div class="bg-red-200 font-bold border-red-500 border rounded text-red-500 text-xs sm:text-sm p-1 text-center">
              <div>คำเตือนเมื่อแยกชิ้นส่วนและไม่ค้นพบตราสัญลักษณ์ไอเทมจะกลายเป็นขยะทันที</div>
            </div>
            <div class="flex items-center">
              <div class="w-1/2">
                <div class="flex justify-center items-center mb-1"><img :src="itemSelected.img" class="w-24"></div>
                <div class="flex justify-center items-center space-x-1">
                  <div class="flex items-center justify-center space-x-1 px-2 border rounded border-gray-700">
                    <div><img src="../../assets/images/star.png" class="w-3"></div>
                    <div class="font-bold text-xs sm:text-sm">{{ itemSelected.star }}</div>
                  </div>
                  <div class="text-center font-bold text-xs sm:text-sm">{{ itemSelected.name }}</div>
                </div>
                <div class="flex items-center justify-center space-x-1">
                  <div v-if="itemSelected.health >= 1" class="flex items-center justify-center space-x-1 bg-pink-700 w-10 rounded text-xs sm:text-sm p-0.5">
                      <div class="font-bold text-center">{{ itemSelected.health }}</div>
                      <div><img src="../../assets/images/hearth.png" class="w-4 sm:w-5"></div>
                    </div>
                    <div v-if="itemSelected.attack >= 1" class="flex items-center justify-center space-x-1 bg-red-500 w-10 rounded text-xs sm:text-sm p-0.5">
                      <div class="font-bold text-center">{{ itemSelected.attack }}</div>
                      <div><img src="../../assets/images/sword.png" class="w-4 sm:w-5"></div>
                    </div>
                    <div v-if="itemSelected.defend >= 1" class="flex items-center justify-center space-x-1 bg-blue-500 w-10 rounded text-xs sm:text-sm p-0.5">
                      <div class="font-bold text-center">{{ itemSelected.defend }}</div>
                      <div><img src="../../assets/images/shield.png" class="w-4 sm:w-5"></div>
                    </div>
                    <div v-if="itemSelected.accuracy >= 1" class="flex items-center justify-center space-x-1 bg-yellow-700 w-10 rounded text-xs sm:text-sm p-0.5">
                      <div class="font-bold text-center">{{ itemSelected.accuracy }}</div>
                      <div><img src="../../assets/images/target.png" class="w-4 sm:w-5"></div>
                    </div>
                  </div>
              </div>
              <div>
                <div><img src="../../assets/images/greenrightarrow.png" class="w-10 animate-pulse"></div>
              </div>
              <div class="w-1/2">
                
                <div class="flex justify-center items-center mb-1"><img :src="newItem.img" class="w-24 p-1 bg-yellow-500 rounded-full"></div>
                <div class="flex justify-center items-center space-x-1">
                  <div class="flex items-center justify-center space-x-1 px-2 border rounded border-gray-700">
                    <div><img src="../../assets/images/star.png" class="w-3"></div>
                    <div class="font-bold text-xs sm:text-sm">{{ newItem.star }}</div>
                  </div>
                  <div class="text-center font-bold text-xs sm:text-sm">{{ newItem.name }}</div>
                </div>
                <div class="flex items-center justify-center">
                    <div class="flex items-center justify-center space-x-1 bg-red-500 w-10 rounded text-xs sm:text-sm p-0.5">
                      <div class="font-bold text-center">{{ newItem.attack }}</div>
                      <div><img src="../../assets/images/sword.png" class="w-4 sm:w-5"></div>
                    </div>
                  </div>
              </div>
            </div>
            
          </div>
          <div @click="report = ''" v-if="report" :class="[report === 'คุณแยกชิ้นส่วนสำเร็จ' ? 'text-green-500' : 'text-red-500']" class="text-center font-bold text-sm hover:text-red-600 cursor-pointer">{{ report }}</div>
          <div class="space-y-1 mt-2">
            <button v-if="!isPending && !itemSelected.id && items.length >= 1" type="button" class="w-full justify-center rounded bg-red-900 px-3 py-2 text-sm font-semibold text-white shadow-sm  cursor-not-allowed">กรุณาเลือกไอเทม</button>
            <button v-if="!isPending && itemSelected.id && newItem.name !== newItem.oldItemName" @click="handleUpgradeItem" type="button" class="w-full justify-center rounded bg-indigo-900 hover:bg-indigo-800 px-3 py-2 text-sm font-semibold text-white shadow-sm duration-300">แยกชิ้นส่วนไอเทม</button>
            <button v-if="itemSelected.id && !isPending" @click=" itemSelected = {id: ''}" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ย้อนกลับ</button>
            <button v-if="isPending"  type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300 cursor-not-allowed">Loading...</button>
            <button v-if="!itemSelected.id && !isPending" @click="cancelModal" type="button" class=" w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
import { projectFirestore, projectFunctions } from '@/firebase/config'
import getUser from '@/composables/getUser'
  export default {
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}
        const { user } = getUser()
        const items = ref([])
        projectFirestore.collection('items')
    .where('ownerUid', '==', user.value.uid)
    .where('used', '==', false)
    .orderBy('star', 'asc')
    .orderBy('type')
    .onSnapshot(snapshot => {
        // Reset items.value to avoid duplicate entries
        items.value = [];
        
        // Filter the results client-side to exclude 'trash' type
        snapshot.forEach(itemDoc => {
            let itemData = itemDoc.data();
            if (itemData.type !== 'horse' && itemData.type !== 'map' && itemData.type !== 'trash' && itemData.type !== 'pet' && itemData.type !== 'animalfood' && itemData.type !== 'glasses' && itemData.type !== 'armlet' && itemData.type !== 'amulet' && itemData.type !== 'necklace' && itemData.type !== 'compass' && itemData.type !== 'collection' && itemData.type !== 'emblem' && itemData.type !== 'waist'  && itemData.type !== 'ring'  && itemData.type !== 'glove'  && itemData.type !== 'armor' && itemData.name !== 'เสื้อเน่า'  && itemData.name !== 'กางเกงเน่า' && itemData.name !== 'รองเท้าเน่า' && itemData.name !== 'หมวกเน่า' && itemData.name !== 'ท่อนไม้' && itemData.type !== 'lasso' && itemData.type !== 'bag' && itemData.type !== 'mouth' && itemData.type !== 'offhand') {
                itemData.id = itemDoc.id; // Add the document id to the item data
                items.value.push(itemData);
            }
        });
    });



        const isPending = ref(false)
        const report = ref('')
        const itemSelected = ref({id:''})
        const newItem = ref({})
        const itemId = ref('')

        const upgradeItems = [

          {oldItemName: 'ส้อม',name:'ตราสัญลักษณ์วิลเลี่ยม',health: 0, attack: 1, defend: 0,accuracy: 0,star: 1, textColor: 'text-white', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
          {oldItemName: 'มีดหั่นผัก',name:'ตราสัญลักษณ์วูดดี้',health: 0, attack: 3, defend: 0,accuracy: 0,star: 2, textColor: 'text-amber-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc'},
          {oldItemName: 'พลั่ว',name:'ตราสัญลักษณ์วิกกี้',health: 0, attack: 5, defend: 0,accuracy: 0,star: 3, textColor: 'text-lime-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
          {oldItemName: 'มีด',name:'ตราสัญลักษณ์เมสัน',health: 0, attack: 7, defend: 0,accuracy: 0,star: 4, textColor: 'text-green-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmason.png?alt=media&token=60f49aee-73e9-454f-b492-eb9f4b54c723'},
          {oldItemName: 'อีโต้',name:'ตราสัญลักษณ์มูน',health: 0, attack: 9, defend: 0,accuracy: 0,star: 5, textColor: 'text-cyan-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
          {oldItemName: 'ขวาน',name:'ตราสัญลักษณ์ลูคัส',health: 0, attack: 11, defend: 0,accuracy: 0,star: 6, textColor: 'text-sky-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Flucas.png?alt=media&token=77e7d979-3680-415f-a2d4-3588cae83e91'},
          {oldItemName: 'ดาบโจรสลัด',name:'ตราสัญลักษณ์เบนจามิน',health: 0, attack: 13, defend: 0,accuracy: 0,star: 7, textColor: 'text-blue-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
          {oldItemName: 'ดาบเหล็กกล้า',name:'ตราสัญลักษณ์เจคอบ',health: 0, attack: 15, defend: 0,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce'},

          {oldItemName: 'ผ้าพันคอขาด',name:'ตราสัญลักษณ์วิลเลี่ยม',health: 0, attack: 1, defend: 0,accuracy: 0,star: 1, textColor: 'text-white', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
          {oldItemName: 'ผ้าพันคอคนจร',name:'ตราสัญลักษณ์วูดดี้',health: 0, attack: 3, defend: 0,accuracy: 0,star: 2, textColor: 'text-amber-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc'},
          {oldItemName: 'ผ้าพันคอชาวไร่',name:'ตราสัญลักษณ์วิกกี้',health: 0, attack: 5, defend: 0,accuracy: 0,star: 3, textColor: 'text-lime-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
          {oldItemName: 'ผ้าพันคอนักเลง',name:'ตราสัญลักษณ์เมสัน',health: 0, attack: 7, defend: 0,accuracy: 0,star: 4, textColor: 'text-green-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmason.png?alt=media&token=60f49aee-73e9-454f-b492-eb9f4b54c723'},
          {oldItemName: 'ผ้าพันคอโจร',name:'ตราสัญลักษณ์มูน',health: 0, attack: 9, defend: 0,accuracy: 0,star: 5, textColor: 'text-cyan-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
          {oldItemName: 'ผ้าพันคอพ่อค้า',name:'ตราสัญลักษณ์ลูคัส',health: 0, attack: 11, defend: 0,accuracy: 0,star: 6, textColor: 'text-sky-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Flucas.png?alt=media&token=77e7d979-3680-415f-a2d4-3588cae83e91'},
          {oldItemName: 'ผ้าพันคอโจรสลัด',name:'ตราสัญลักษณ์เบนจามิน',health: 0, attack: 13, defend: 0,accuracy: 0,star: 7, textColor: 'text-blue-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
          {oldItemName: 'ผ้าพันคอคนรวย',name:'ตราสัญลักษณ์เจคอบ',health: 0, attack: 15, defend: 0,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce'},

          {oldItemName: 'เสื้อคุมขาด',name:'ตราสัญลักษณ์วิลเลี่ยม',health: 0, attack: 1, defend: 0,accuracy: 0,star: 1, textColor: 'text-white', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
          {oldItemName: 'เสื้อคุมคนจร',name:'ตราสัญลักษณ์วูดดี้',health: 0, attack: 3, defend: 0,accuracy: 0,star: 2, textColor: 'text-amber-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc'},
          {oldItemName: 'เสื้อคุมชาวไร่',name:'ตราสัญลักษณ์วิกกี้',health: 0, attack: 5, defend: 0,accuracy: 0,star: 3, textColor: 'text-lime-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
          {oldItemName: 'เสื้อคุมนักเลง',name:'ตราสัญลักษณ์เมสัน',health: 0, attack: 7, defend: 0,accuracy: 0,star: 4, textColor: 'text-green-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmason.png?alt=media&token=60f49aee-73e9-454f-b492-eb9f4b54c723'},
          {oldItemName: 'เสื้อคุมโจร',name:'ตราสัญลักษณ์มูน',health: 0, attack: 9, defend: 0,accuracy: 0,star: 5, textColor: 'text-cyan-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
          {oldItemName: 'เสื้อคุมพ่อค้า',name:'ตราสัญลักษณ์ลูคัส',health: 0, attack: 11, defend: 0,accuracy: 0,star: 6, textColor: 'text-sky-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Flucas.png?alt=media&token=77e7d979-3680-415f-a2d4-3588cae83e91'},
          {oldItemName: 'เสื้อคุมโจรสลัด',name:'ตราสัญลักษณ์เบนจามิน',health: 0, attack: 13, defend: 0,accuracy: 0,star: 7, textColor: 'text-blue-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
          {oldItemName: 'เสื้อคุมคนรวย',name:'ตราสัญลักษณ์เจคอบ',health: 0, attack: 15, defend: 0,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce'},

          {oldItemName: 'หมวกขาด',name:'ตราสัญลักษณ์วิลเลี่ยม',health: 0, attack: 1, defend: 0,accuracy: 0,star: 1, textColor: 'text-white', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
          {oldItemName: 'หมวกคนจร',name:'ตราสัญลักษณ์วูดดี้',health: 0, attack: 3, defend: 0,accuracy: 0,star: 2, textColor: 'text-amber-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc'},
          {oldItemName: 'หมวกชาวไร่',name:'ตราสัญลักษณ์วิกกี้',health: 0, attack: 5, defend: 0,accuracy: 0,star: 3, textColor: 'text-lime-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
          {oldItemName: 'หมวกนักเลง',name:'ตราสัญลักษณ์เมสัน',health: 0, attack: 7, defend: 0,accuracy: 0,star: 4, textColor: 'text-green-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmason.png?alt=media&token=60f49aee-73e9-454f-b492-eb9f4b54c723'},
          {oldItemName: 'หมวกโจร',name:'ตราสัญลักษณ์มูน',health: 0, attack: 9, defend: 0,accuracy: 0,star: 5, textColor: 'text-cyan-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
          {oldItemName: 'หมวกพ่อค้า',name:'ตราสัญลักษณ์ลูคัส',health: 0, attack: 11, defend: 0,accuracy: 0,star: 6, textColor: 'text-sky-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Flucas.png?alt=media&token=77e7d979-3680-415f-a2d4-3588cae83e91'},
          {oldItemName: 'หมวกโจรสลัด',name:'ตราสัญลักษณ์เบนจามิน',health: 0, attack: 13, defend: 0,accuracy: 0,star: 7, textColor: 'text-blue-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
          {oldItemName: 'หมวกคนรวย',name:'ตราสัญลักษณ์เจคอบ',health: 0, attack: 15, defend: 0,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce'},

          {oldItemName: 'เสื้อขาด',name:'ตราสัญลักษณ์วิลเลี่ยม',health: 0, attack: 1, defend: 0,accuracy: 0,star: 1, textColor: 'text-white', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
          {oldItemName: 'เสื้อคนจร',name:'ตราสัญลักษณ์วูดดี้',health: 0, attack: 3, defend: 0,accuracy: 0,star: 2, textColor: 'text-amber-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc'},
          {oldItemName: 'เสื้อชาวไร่',name:'ตราสัญลักษณ์วิกกี้',health: 0, attack: 5, defend: 0,accuracy: 0,star: 3, textColor: 'text-lime-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
          {oldItemName: 'เสื้อนักเลง',name:'ตราสัญลักษณ์เมสัน',health: 0, attack: 7, defend: 0,accuracy: 0,star: 4, textColor: 'text-green-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmason.png?alt=media&token=60f49aee-73e9-454f-b492-eb9f4b54c723'},
          {oldItemName: 'เสื้อโจร',name:'ตราสัญลักษณ์มูน',health: 0, attack: 9, defend: 0,accuracy: 0,star: 5, textColor: 'text-cyan-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
          {oldItemName: 'เสื้อพ่อค้า',name:'ตราสัญลักษณ์ลูคัส',health: 0, attack: 11, defend: 0,accuracy: 0,star: 6, textColor: 'text-sky-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Flucas.png?alt=media&token=77e7d979-3680-415f-a2d4-3588cae83e91'},
          {oldItemName: 'เสื้อโจรสลัด',name:'ตราสัญลักษณ์เบนจามิน',health: 0, attack: 13, defend: 0,accuracy: 0,star: 7, textColor: 'text-blue-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
          {oldItemName: 'เสื้อคนรวย',name:'ตราสัญลักษณ์เจคอบ',health: 0, attack: 15, defend: 0,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce'},

          {oldItemName: 'กางเกงขาด',name:'ตราสัญลักษณ์วิลเลี่ยม',health: 0, attack: 1, defend: 0,accuracy: 0,star: 1, textColor: 'text-white', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
          {oldItemName: 'กางเกงคนจร',name:'ตราสัญลักษณ์วูดดี้',health: 0, attack: 3, defend: 0,accuracy: 0,star: 2, textColor: 'text-amber-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc'},
          {oldItemName: 'กางเกงชาวไร่',name:'ตราสัญลักษณ์วิกกี้',health: 0, attack: 5, defend: 0,accuracy: 0,star: 3, textColor: 'text-lime-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
          {oldItemName: 'กางเกงนักเลง',name:'ตราสัญลักษณ์เมสัน',health: 0, attack: 7, defend: 0,accuracy: 0,star: 4, textColor: 'text-green-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmason.png?alt=media&token=60f49aee-73e9-454f-b492-eb9f4b54c723'},
          {oldItemName: 'กางเกงโจร',name:'ตราสัญลักษณ์มูน',health: 0, attack: 9, defend: 0,accuracy: 0,star: 5, textColor: 'text-cyan-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
          {oldItemName: 'กางเกงพ่อค้า',name:'ตราสัญลักษณ์ลูคัส',health: 0, attack: 11, defend: 0,accuracy: 0,star: 6, textColor: 'text-sky-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Flucas.png?alt=media&token=77e7d979-3680-415f-a2d4-3588cae83e91'},
          {oldItemName: 'กางเกงโจรสลัด',name:'ตราสัญลักษณ์เบนจามิน',health: 0, attack: 13, defend: 0,accuracy: 0,star: 7, textColor: 'text-blue-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
          {oldItemName: 'กางเกงคนรวย',name:'ตราสัญลักษณ์เจคอบ',health: 0, attack: 15, defend: 0,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce'},
          
          {oldItemName: 'รองเท้าขาด',name:'ตราสัญลักษณ์วิลเลี่ยม',health: 0, attack: 1, defend: 0,accuracy: 0,star: 1, textColor: 'text-white', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
          {oldItemName: 'รองเท้าคนจร',name:'ตราสัญลักษณ์วูดดี้',health: 0, attack: 3, defend: 0,accuracy: 0,star: 2, textColor: 'text-amber-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc'},
          {oldItemName: 'รองเท้าชาวไร่',name:'ตราสัญลักษณ์วิกกี้',health: 0, attack: 5, defend: 0,accuracy: 0,star: 3, textColor: 'text-lime-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
          {oldItemName: 'รองเท้านักเลง',name:'ตราสัญลักษณ์เมสัน',health: 0, attack: 7, defend: 0,accuracy: 0,star: 4, textColor: 'text-green-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmason.png?alt=media&token=60f49aee-73e9-454f-b492-eb9f4b54c723'},
          {oldItemName: 'รองเท้าโจร',name:'ตราสัญลักษณ์มูน',health: 0, attack: 9, defend: 0,accuracy: 0,star: 5, textColor: 'text-cyan-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
          {oldItemName: 'รองเท้าพ่อค้า',name:'ตราสัญลักษณ์ลูคัส',health: 0, attack: 11, defend: 0,accuracy: 0,star: 6, textColor: 'text-sky-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Flucas.png?alt=media&token=77e7d979-3680-415f-a2d4-3588cae83e91'},
          {oldItemName: 'รองเท้าโจรสลัด',name:'ตราสัญลักษณ์เบนจามิน',health: 0, attack: 13, defend: 0,accuracy: 0,star: 7, textColor: 'text-blue-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
          {oldItemName: 'รองเท้าคนรวย',name:'ตราสัญลักษณ์เจคอบ',health: 0, attack: 15, defend: 0,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce'},

          {oldItemName: 'ดัลเมเชียน',name:'ดัลเมเชียน',health: 0, attack: 0, defend: 0, accuracy: 0,star: 0, textColor: 'text-amber-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fbullterrier.png?alt=media&token=053515f5-9a40-404e-b6c1-fadd6a09eed3'},
          {oldItemName: 'บูลเทอร์เรีย',name:'บูลเทอร์เรีย',health: 0, attack: 0, defend: 0, accuracy: 0,star: 0, textColor: 'text-lime-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fboxer.png?alt=media&token=38d2078e-6dce-4e18-ad9d-b680b9c9eefe'},
          {oldItemName: 'บ๊อกเซอร์',name:'บ๊อกเซอร์',health: 0, attack: 0, defend: 0, accuracy: 0,star: 0, textColor: 'text-green-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fbulldog.png?alt=media&token=901c8fe8-30f6-4f7a-aaa5-dc6a89556f52'},
          {oldItemName: 'บูลด็อก',name:'บูลด็อก',health: 0, attack:0, defend: 0, accuracy: 0,star: 0, textColor: 'text-cyan-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fbordercollie.png?alt=media&token=9bebdac7-3dc0-45f9-9c8b-2f5aff8e77f3'},
          {oldItemName: 'บอร์เดอร์คอลลี่',name:'บอร์เดอร์คอลลี่',health: 0, attack: 0, defend: 0, accuracy: 0,star: 0, textColor: 'text-sky-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fgreatdane.png?alt=media&token=9eca6356-b999-4434-8f98-14ab37cd50d4'},
          {oldItemName: 'เกรทเดน',name:'เกรทเดน',health: 0, attack: 0, defend: 0, accuracy: 0,star: 0, textColor: 'text-blue-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fgermanshepherd.png?alt=media&token=efd6ef7a-2fff-4d57-9df5-27569d378c39'},
          {oldItemName: 'เยอรมันเชพเพิร์ด',name:'เยอรมันเชพเพิร์ด',health: 0, attack: 0, defend: 0, accuracy: 0,star: 0, textColor: 'text-indigo-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fdoberman.png?alt=media&token=1aa5bbf8-ee8e-4720-bb6b-0adba32b72cf'},
          {oldItemName: 'โดเบอร์แมน',name:'โดเบอร์แมน',health: 0, attack: 0, defend: 0, accuracy: 0,star: 0, textColor: 'text-violet-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fpitbull.png?alt=media&token=c5fe566a-ed20-4e80-8812-3449dc859601'},
          {oldItemName: 'พิทบูล',ame:'พิทบูล',health: 0, attack: 0, defend: 0, accuracy: 0,star: 0, textColor: 'text-pink-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Frottweiler.png?alt=media&token=01b12ac2-a4c9-4ceb-9005-23e62c338177'},
          {oldItemName: 'ร็อตไวเลอร์',ame:'ร็อตไวเลอร์',health: 0, attack: 0, defend: 0, accuracy: 0,star: 0, textColor: 'text-pink-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Frottweiler.png?alt=media&token=01b12ac2-a4c9-4ceb-9005-23e62c338177'},
        ]

        const handleSelectItem = (id, item) => {
          itemId.value = id
          itemSelected.value = item
          newItem.value = upgradeItems.find(upgradeItem => upgradeItem.oldItemName === item.name)
        }

        const handleUpgradeItem = e =>{
          const upgradeItem = projectFunctions.httpsCallable('disassembleItem')
          isPending.value = true
          upgradeItem({itemId: itemId.value}).then(result =>{
            isPending.value = false
            report.value = result.data
            itemSelected.value = {id:''}
          }).catch(err =>{
            isPending.value = false
            report.value = err
            itemSelected.value = {id:''}
          })

        }

        return { handleUpgradeItem,report,cancelModal,items,isPending,handleSelectItem,itemSelected,newItem }
    }
  }
  </script>