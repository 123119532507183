<template>
    <div class="col-span-1 sm:col-span-3 p-3 bg-gray-900 rounded shadow">
       <ModalCharacter v-if="toggleModal === 'ModalCharacter' && character.cave && user.uid !== characterId" @cancelModal="toggleModal = ''" :characterId="characterId" /> 
      <div v-if="characters.length >= 1">
        <div class="text-gray-300 text-center">ผู้เล่นที่อยู่ในถ้ำ</div>
        <div class="grid grid-cols-5 sm:grid-cols-6 md:grid-cols-7 lg:grid-cols-8 xl:grid-cols-9 2xl:grid-cols-10 gap-1">
          <div v-for="character in characters" :key="character.id">
            <div :class="[user.uid === character.uid ? 'bg-green-900 cursor-not-allowed' : 'bg-gray-800 hover:bg-red-700 cursor-pointer']" @click="handleModalCharacter('ModalCharacter',character.uid)" class="p-0.5  duration-300 rounded">
                <div class="flex justify-center"><img src="../../assets/images/stranger.png" class="w-20"></div>
            </div>
           
          </div>
        </div>
      </div>
      <div v-if="characters.length < 1" class="h-20 flex items-center justify-center">
        <div class="text-white opacity-50 text-xl font-bold text-center">ไม่พบผู้เล่นอยู่ในถ้ำ</div>
      </div>
    </div>
  </template>
  
  <script>
  import { projectFirestore } from '@/firebase/config';
  import { ref, onUnmounted } from 'vue';
  import ModalCharacter from './ModalCharacter.vue'
import getUser from '@/composables/getUser';
  export default {
    components: { ModalCharacter },
    setup() {
      const { user } = getUser()
      const characters = ref([]);
      const character = ref({})

      projectFirestore.collection('characters').doc(user.value.uid).onSnapshot(snapshot =>{
        character.value = snapshot.data()
      })
      
  
      // Store the snapshot listener in a variable
      const unsubscribe = projectFirestore.collection('characters')
        .where('isLive', '==', true)
        .where('cave', '==', true)
        .orderBy('position', 'asc')
        .onSnapshot(snapshot => {
          characters.value = []; // Clear the array first
          snapshot.forEach(characterDoc => {
            characters.value.push({ id: characterDoc.id, ...characterDoc.data() });
          });
        });
  
      // Unsubscribe from Firestore on component unmount
      onUnmounted(() => {
        unsubscribe();
      });

      const toggleModal = ref('')
      const characterId = ref('')
      const handleModalCharacter = (modal,id) =>{
        toggleModal.value  = modal
        characterId.value = id
      }
  
      return { character, user,characters,toggleModal,characterId,handleModalCharacter };
    },
  };
  </script>
  