<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-4/5 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div>
            <div class="flex justify-center items-center"><img src="../../assets/images/cave.png" class="w-40"></div>
          </div>
          <div v-if="report" :class="[report === 'ออกถ้ำสำเร็จ' ? 'text-green-500' : 'text-red-500']" class="text-center text-sm">{{ report }}</div>
          <div >
            <button v-if="!isPending && exitHours < 0 && exitMinutes < 0 && exitSeconds < 0" @click="handleExit" type="button" class="w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">ออกถ้ำ</button>
            <button v-if="!isPending && exitHours >= 0 && exitMinutes >= 0 && exitSeconds >= 0"  type="button" class="w-full justify-center rounded bg-red-900 px-3 py-2 text-sm font-semibold text-white shadow-sm cursor-not-allowed ">กรุณารอ {{exitHours}} ชั่วโมง {{exitMinutes}} นาที {{exitSeconds}} วินาที</button>
          <button v-if="isPending"  type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 animate-pulse px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm     ">Loading...</button>
          <button v-if="!isPending" @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
        </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
  import getUser from '@/composables/getUser';
import { projectFirestore, projectFunctions } from '@/firebase/config';
  export default {
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}
        const { user } = getUser()
        const exitMinutes = ref(1)
        const exitSeconds = ref(1)
        const exitHours = ref(1)
        projectFirestore.collection('characterPrivate').doc(user.value.uid).onSnapshot(snapshot =>{

        var countDownDate = snapshot.data().exit.toDate().getTime() + 3000
        var x = setInterval(() => {
            var now = new Date().getTime()
            var distance = countDownDate - now
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000)
            exitHours.value = hours
            exitMinutes.value = minutes
            exitSeconds.value = seconds
            if (distance < 0) {
            clearInterval(x)
            }
        }, 0)
        })
        const isPending = ref(false);
        const report = ref('');

        const handleExit = e => {
        const exitCave = projectFunctions.httpsCallable('exitCave');
        isPending.value = true;

        exitCave()
            .then(result => {
            isPending.value = false;
            report.value = result.data;
             if(report.value === 'ออกถ้ำสำเร็จ'){
                context.emit('cancelModal')
             }
            })
            .catch(err => {
            isPending.value = false; // Reset isPending to false when error occurs
            report.value = err.message || 'An error occurred'; // Show error message
            });
        };


        return { exitMinutes,
exitSeconds,
exitHours, cancelModal,handleExit,isPending,report }
    },
  }
  </script>