<!-- <template>
  <div class=" w-full bg-gray-800">
    <div class="flex flex-col h-screen  text-gray-100 justify-between">
      <Navbar />
      <router-view/>
    </div>
  </div>
  <BottomMenu />
</template> -->

<template>
  <div>
    <div class="h-full flex justify-center items-center space-x-1">
      <div><img src="./assets/worker/houston.png" class="w-20"></div>
      <div>ปิดให้บริการ</div>
    </div>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import BottomMenu from './components/BottomMenu.vue';

export default {
  components: { Navbar, BottomMenu },
  setup() {

    return {};
  },
};
</script>


<style >
#style-1 {
  /* Custom scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: #2d2d2d #1a1a1a;
}

#style-1::-webkit-scrollbar {
  width: 8px;
}

#style-1::-webkit-scrollbar-track {
  background: #1a1a1a;
}

#style-1::-webkit-scrollbar-thumb {
  background-color: #2d2d2d;
  border-radius: 10px;
}
</style>
