<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-full pb-16 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div class="text-gray-300 text-center text-xl mb-1">ของสะสมคนงาน</div>
          <div  class="h-80 overflow-auto">
            <div v-for="collection in collections" :key="collection">
              <div  class="flex  justify-between bg-gray-900 mb-0.5 rounded p-1">
                <div class="flex items-center space-x-1">
                  <div v-if="!workerCollectionUsed.includes(collection.name)" class="flex justify-center"><img src="../../assets/images/blackstar.png" class="w-8 "></div>
                  <div v-if="workerCollectionUsed.includes(collection.name)" class="flex justify-center"><img src="../../assets/images/lightstar.png" class="w-8 "></div>
                  <div class="flex justify-center items-center space-x-1 border border-gray-700 px-1 rounded">
                              <div><img src="../../assets/images/star.png" class="w-3"></div>
                              <div class=" font-bold text-sm">{{ collection.star }}</div>
                            </div>
                  <div :class="[workerCollectionUsed.includes(collection.name) ? 'text-green-500' : 'text-red-500']" class="text-gray-300 text-sm">{{ collection.name }}</div>
                </div>
                <div class="flex items-center space-x-1 font-bold">
                  <div v-if="collection.health >= 1" class="flex items-center space-x-1">
                          <div>{{ collection.health }}</div>
                          <div><img src="../../assets/images/hearth.png" class="w-4 sm:w-5"></div>
                        </div>
                      <div v-if="collection.attack >= 1" class="flex  items-center space-x-1">
                        <div>{{ collection.attack }}</div>
                        <div><img src="../../assets/images/sword.png" class="w-4 sm:w-5"></div>
                      </div>
                      <div v-if="collection.defend >= 1" class="flex  items-center space-x-1">
                        <div>{{ collection.defend }}</div>
                        <div><img src="../../assets/images/shield.png" class="w-4 sm:w-5"></div>
                      </div>
                      <div v-if="collection.accuracy >= 1" class="flex  items-center space-x-1">
                        <div>{{ collection.accuracy }}</div>
                        <div><img src="../../assets/images/target.png" class="w-4 sm:w-5"></div>
                      </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-1">
          <button  @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
        </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
import { projectFirestore, projectFunctions } from '@/firebase/config'
import { useRouter } from 'vue-router'
  export default {
    props: ['id'],
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}
        const worker = ref()
        const workerCollectionUsed = ref([])
        projectFirestore.collection('workers').doc(props.id).onSnapshot(snapshot =>{
          worker.value = snapshot.data()
          workerCollectionUsed.value = snapshot.data().collectionUsed
        })

        const collections = [
    {name:'กระป๋องขอทาน',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
          {name:'เศษผ้าเก่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
          {name:'กระดาษลัง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
          {name:'หีบเพลงเก่า',health: 0, attack: 0, defend: 1, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
          {name:'ช้อน',health: 0, attack: 0, defend: 0, accuracy: 1,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
          {name:'ระเบิด',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'กระทะกรองทอง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'เศษหิน',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'รถเข็นขนแร่',health: 0, attack: 0, defend: 1, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ชะแลง',health: 0, attack: 0, defend: 0, accuracy: 1,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'หินน้ำตาล',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'เขี้ยวหมูป่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ผลไม้',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'หนังหมูป่า',health: 0, attack: 0, defend: 1, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ลูกตาหมูป่า',health: 0, attack: 0, defend: 0, accuracy: 1,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'เทียน',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'แท่งเหล็ก',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ตราผู้สนับสนุน',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'หนังชั้นดี',health: 0, attack: 0, defend: 1, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'กล้องส่องทางไกล',health: 0, attack: 0, defend: 0, accuracy: 1,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ลูกปัดสีฟ้า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ขลุ่ยชนชั้นสูง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กระดิ่ง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'รูปปั้นเทพพระเจ้า',health: 0, attack: 0, defend: 1, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'หนังสือโบราณ',health: 0, attack: 0, defend: 0, accuracy: 1,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ก้อนหินสีเหลือง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'กระดูกส่วนแขน',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ถ่านหิน',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'กระติกน้ำที่เสียแล้ว',health: 0, attack: 0, defend: 1, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ไม้ขีดไฟ',health: 0, attack: 0, defend: 0, accuracy: 1,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ถุงผ้าเก่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ผ้าห่มขาด',health: 0, attack: 3, defend: 0, accuracy: 0,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ขวดน้ำ',health: 0, attack: 3, defend: 0, accuracy: 0,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'เศษกระดาษ',health: 0, attack: 0, defend: 1, accuracy: 0,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ของเล่นเก่า',health: 0, attack: 0, defend: 0, accuracy: 1,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'กระดูกสัตว์ป่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'หนังติดเลือด',health: 0, attack: 3, defend: 0, accuracy: 0,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'หินสีดำ',health: 0, attack: 3, defend: 0, accuracy: 0,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'เศษเสื้อ',health: 0, attack: 0, defend: 1, accuracy: 0,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'หางหมาป่า',health: 0, attack: 0, defend: 0, accuracy: 1,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'คบเพลิง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'เขากวางมูส',health: 0, attack: 3, defend: 0, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'เชือก',health: 0, attack: 3, defend: 0, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'รูปภาพเก่า',health: 0, attack: 0, defend: 1, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'หินล้ำค่า',health: 0, attack: 0, defend: 0, accuracy: 1,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'กระโหลกนักเดินทาง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ใบไม้สีหายาก',health: 0, attack: 3, defend: 0, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เขี้ยวสัตว์ป่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ลากไม้',health: 0, attack: 0, defend: 1, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ขนนกชั้นเลิศ',health: 0, attack: 0, defend: 0, accuracy: 1,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ตะเกียง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 4, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กิ่งไม้',health: 0, attack: 3, defend: 0, accuracy: 0,star: 4, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'สายจูงสัตว์',health: 0, attack: 3, defend: 0, accuracy: 0,star: 4, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เข็มขัดบาง',health: 0, attack: 0, defend: 1, accuracy: 0,star: 4, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ใบมีด',health: 0, attack: 0, defend: 0, accuracy: 1,star: 4, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'นาฬิกาทราย',health: 0, attack: 3, defend: 0, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แผนที่ล้ำค่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เกือกม้า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'หมายประกาศจับ',health: 0, attack: 0, defend: 1, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เอกสารลับ',health: 0, attack: 0, defend: 0, accuracy: 1,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เศษทรายหายาก',health: 0, attack: 3, defend: 0, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ขวดเหล้าเปล่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เศษซากกระดูก',health: 0, attack: 3, defend: 0, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กระติกน้ำ',health: 0, attack: 0, defend: 1, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แผนที่ขาด',health: 0, attack: 0, defend: 0, accuracy: 1,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ธนบัตรเก่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 6, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เครื่องลายคราม',health: 0, attack: 3, defend: 0, accuracy: 0,star: 6, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ผ้าไหม',health: 0, attack: 3, defend: 0, accuracy: 0,star: 6, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'สมุดบัญชี',health: 0, attack: 0, defend: 1, accuracy: 0,star: 6, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แผนที่รถไฟ',health: 0, attack: 0, defend: 0, accuracy: 1,star: 6, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แผนที่สมบัติ',health: 0, attack: 3, defend: 0, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ถ้วยเงิน',health: 0, attack: 3, defend: 0, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กาน้ำ',health: 0, attack: 3, defend: 0, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ธงโจรสลัด',health: 0, attack: 0, defend: 1, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ผ้าโพกหัว',health: 0, attack: 0, defend: 0, accuracy: 1,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แจกันโจรสลัด',health: 0, attack: 3, defend: 0, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'จานแตก',health: 0, attack: 3, defend: 0, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แกวน้ำโจรสลัด',health: 0, attack: 3, defend: 0, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แผ่นไม้',health: 0, attack: 0, defend: 1, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ตะปู',health: 0, attack: 0, defend: 0, accuracy: 1,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'นาฬิกาสร้อยคอ',health: 0, attack: 3, defend: 0, accuracy: 0,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แหวนอัญมนี',health: 0, attack: 3, defend: 0, accuracy: 0,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ภาพวาดยุโรป',health: 0, attack: 3, defend: 0, accuracy: 0,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กำไลทองคำ',health: 0, attack: 0, defend: 1, accuracy: 0,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'หนังสือพิมพ์',health: 0, attack: 0, defend: 0, accuracy: 1,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เศษหนวด',health: 0, attack: 3, defend: 0, accuracy: 0,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'หวีทองคำ',health: 0, attack: 3, defend: 0, accuracy: 0,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กรรไกรคนรวย',health: 0, attack: 3, defend: 0, accuracy: 0,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ขนหมาพิทบูล',health: 0, attack: 0, defend: 1, accuracy: 0,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กระจกกรอบทอง',health: 0, attack: 0, defend: 0, accuracy: 1,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ขนนก',health: 0, attack: 3, defend: 0, accuracy: 0,star: 9, type: 'collection', textColor: 'text-whi te', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
             {name:'ลูกธนู',health: 0, attack: 3, defend: 0, accuracy: 0,star: 9, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'งานปั้นชั้นเลิศ',health: 0, attack: 3, defend: 0, accuracy: 0,star: 9, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กลอง',health: 0, attack: 0, defend: 1, accuracy: 0,star: 9, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'โทเท็ม',health: 0, attack: 0, defend: 0, accuracy: 1,star: 9, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
        ]

        return { workerCollectionUsed,collections, cancelModal }
    },
  }
  </script>