<template>
  <div class="space-y-1 items-center h-full bg-gray-900 p-1 text-sm sm:">
    <ModalAddItemToWorker v-if="toggleModal === 'AddItemToWorker'" @cancelModal = "toggleModal = ''" :id="id" />
    <ModalChangeWorkerName v-if="toggleModal === 'ModalChangeWorkerName'" @cancelModal = "toggleModal = ''" :id="id" />
    <ModalChangeOwner v-if="toggleModal === 'ChangeOwner'" @cancelModal = "toggleModal = ''" :id="id" />
    <ModalWork v-if="toggleModal === 'ModalWork'" @cancelModal = "toggleModal = ''" :id="id" />
    <ModalLearnSkill v-if="toggleModal === 'ModalLearnSkill'" @cancelModal ="toggleModal =''" :id="id" />
    <ModalVip v-if="toggleModal === 'ModalVip'" @cancelModal ="toggleModal =''" :id="id" />
    <ChangeOwnerLog v-if="toggleModal === 'ChangeOwnerLog'" @cancelModal ="toggleModal =''" :id="id"  />
    <ModalCollection v-if="toggleModal === 'Collection'" @cancelModal ="toggleModal =''" :id="id"  />
    <ModalAddCollection v-if="toggleModal === 'AddCollection'" @cancelModal ="toggleModal =''" :id="id"  />
    <ModalAddMap v-if="toggleModal === 'ModalAddMap'" @cancelModal ="toggleModal =''" :id="id"  />
    <ModalMap v-if="toggleModal === 'ModalMap'" @cancelModal ="toggleModal =''" :id="id"  />
    <ModalBattle  v-if="toggleModal === 'ModalBattle'" @cancelModal ="toggleModal =''" :id="id"  />
    <div v-if="report" @click="report =''" class="text-red-500 text-center text-sm">{{ report }}</div>
    <div v-if="!worker.isLive" class="m-1 text-xs sm:text-sm">
      <div class="space-y-1">
      <div v-if="workerInstantBorn < new Date().getTime()" @click="handleInstantBorn" :class="[instantBornPending ? 'animate-pulse' : '']" class="p-2 bg-lime-900 hover:bg-lime-800 duration-300 cursor-pointer border-lime-500 border rounded text-center text-lime-300 font-bold">เกิดทันที</div>
      <div v-if="workerInstantBorn >= new Date().getTime()" class="p-2 bg-red-900  duration-300 cursor-not-allowed border-red-500 border rounded text-center text-red-300 font-bold">
        <div>สามารถใช้เกิดทันทีได้ทุกๆ 24 ชั่วโมง</div>
        <div>ครั้งถัดไป {{ dayjs(worker.instantBorn.toDate()).format('D MMMM YYYY HH:mm') }}</div>
      </div>
    </div>
  </div>
  <div v-if="worker.isLive" class=" text-xs sm:text-sm">
    <div class="space-y-1">
      <div v-if="workerFirstAid < new Date().getTime() && worker.hit >= 1" @click="handleFirstAid" :class="[firstAidPending ? 'animate-pulse' : '']" class="p-2 bg-lime-900 hover:bg-lime-800 duration-300 cursor-pointer border-lime-500 border rounded text-center text-lime-300 font-bold">ปฐมพยาบาลตัวเอง</div>
      
      <div v-if="workerFirstAid >= new Date().getTime()" class="p-2 bg-red-900  duration-300 cursor-not-allowed border-red-500 border rounded text-center text-red-300 font-bold">
        <div>สามารถปฐมยาบาลให้ตัวเองได้ทุกๆ 24 ชั่วโมง</div>
        <div>ครั้งถัดไป {{ dayjs(worker.firstAid.toDate()).format('D MMMM YYYY HH:mm') }}</div>
      </div>
    </div>
  </div>
    <div v-if="workerWork && worker.isLive" @click="handleWokerWork" :class="[isPedning ? 'animate-pulse' : '']" class="w-full text-center bg-red-500 hover:bg-red-400 text-white font-bold p-1  border-b-4 border-red-700 hover:border-red-500 rounded duration-200 cursor-pointer">หยุดทำงาน</div>
    <div v-if="!workerWork && worker.isLive" @click="toggleModal ='ModalWork'" :class="[isPedning ? 'animate-pulse' : '']" class="w-full text-center bg-green-500 hover:bg-green-400 text-white font-bold p-1  border-b-4 border-green-700 hover:border-green-500 rounded duration-200 cursor-pointer">ทำงาน</div>
    <div v-if="workerWork && worker.isLive" @click="toggleModal ='ModalBattle'" class="w-full text-center bg-indigo-500 hover:bg-indigo-400 text-white font-bold p-1  border-b-4 border-indigo-700 hover:border-indigo-500 rounded duration-200 cursor-pointer">ต่อสู้</div>
    <div @click="toggleModal ='AddItemToWorker'" class="w-full text-center bg-indigo-500 hover:bg-indigo-400 text-white font-bold p-1  border-b-4 border-indigo-700 hover:border-indigo-500 rounded duration-200 cursor-pointer">ใส่ไอเทม</div>
    <div @click="toggleModal ='ModalLearnSkill'" class="w-full text-center bg-indigo-500 hover:bg-indigo-400 text-white font-bold p-1  border-b-4 border-indigo-700 hover:border-indigo-500 rounded duration-200 cursor-pointer">เรียนรู้ทักษะ</div>
    <div @click="toggleModal ='Collection'" class="w-full text-center bg-indigo-500 hover:bg-indigo-400 text-white font-bold p-1  border-b-4 border-indigo-700 hover:border-indigo-500 rounded duration-200 cursor-pointer">ของสะสม</div>
    <div @click="toggleModal ='AddCollection'" class="w-full text-center bg-indigo-500 hover:bg-indigo-400 text-white font-bold p-1  border-b-4 border-indigo-700 hover:border-indigo-500 rounded duration-200 cursor-pointer">เพิ่มของสะสม</div>
    <div @click="toggleModal ='ModalMap'" class="w-full text-center bg-indigo-500 hover:bg-indigo-400 text-white font-bold p-1  border-b-4 border-indigo-700 hover:border-indigo-500 rounded duration-200 cursor-pointer">แผนที่</div>
    <div @click="toggleModal ='ModalAddMap'" class="w-full text-center bg-indigo-500 hover:bg-indigo-400 text-white font-bold p-1  border-b-4 border-indigo-700 hover:border-indigo-500 rounded duration-200 cursor-pointer">เพิ่มแผนที่</div>
    <div @click="toggleModal ='ModalVip'" class="flex items-center justify-center space-x-1 w-full text-center bg-indigo-500 hover:bg-indigo-400 text-white font-bold p-1  border-b-4 border-indigo-700 hover:border-indigo-500 rounded duration-200 cursor-pointer">
      <div>เติมคีย์ VIP</div>
      <div v-if="workerVip >= new Date()" >(หมดอายุ {{ formatDate(workerVip) }})</div>
    </div>
    <div @click="toggleModal ='ChangeOwnerLog'" class="w-full text-center bg-indigo-500 hover:bg-indigo-400 text-white font-bold p-1  border-b-4 border-indigo-700 hover:border-indigo-500 rounded duration-200 cursor-pointer">ประวัติการเปลี่ยน</div>
    <div @click="toggleModal ='ModalChangeWorkerName'" class="w-full text-center bg-indigo-500 hover:bg-indigo-400 text-white font-bold p-1  border-b-4 border-indigo-700 hover:border-indigo-500 rounded duration-200 cursor-pointer">เปลี่ยนชื่อคนงาน</div>
    <div @click="toggleModal ='ChangeOwner'" class="w-full text-center bg-indigo-500 hover:bg-indigo-400 text-white font-bold p-1  border-b-4 border-indigo-700 hover:border-indigo-500 rounded duration-200 cursor-pointer">เปลี่ยนเจ้านาย</div>
  </div>
</template>

<script>
import { ref } from 'vue'
import ModalAddItemToWorker from './ModalAddItemToWorker.vue'
import ModalWork from './ModalWork.vue'
import ModalChangeOwner from './ModalChangeOwner.vue'
import ModalLearnSkill from './ModalLearnSkill.vue'
import { projectFirestore, projectFunctions } from '@/firebase/config'
import ModalVip from './ModalVip.vue'
import dayjs from 'dayjs';
import ChangeOwnerLog from './ChangeOwnerLog.vue'
import ModalChangeWorkerName from './ModalChangeWorkerName.vue'
import ModalCollection from './ModalCollection.vue'
import ModalAddCollection from './ModalAddCollection.vue'
import ModalMap from './ModalMap.vue'
import ModalAddMap from './ModalAddMap.vue'
import 'dayjs/locale/th';
import ModalBattle from './ModalBattle.vue'
export default {
  props: ['id'],
  components : { ModalBattle,ModalAddMap,ModalMap,ModalAddCollection,ModalCollection,ModalChangeWorkerName,ChangeOwnerLog,ModalVip,ModalLearnSkill, ModalAddItemToWorker,ModalWork,ModalChangeOwner },
    setup(props,context) {
      const toggleModal = ref('')
      const workerWork = ref()
      const worker = ref({})
      const workerVip = ref()
      const workerInstantBorn = ref()
      const workerFirstAid = ref()
    
      projectFirestore.collection('workers').doc(props.id).onSnapshot(doc =>{
        worker.value = doc.data()
        workerWork.value = doc.data().work
        workerVip.value = doc.data().vip.toDate()
        workerInstantBorn.value = doc.data().instantBorn.toDate().getTime()
        workerFirstAid.value = doc.data().firstAid.toDate().getTime()
      })

      const isPedning = ref(false)
      const report = ref('')
      const handleWokerWork = e =>{
        const workerWork = projectFunctions.httpsCallable('workerWork')
        isPedning.value = true
        workerWork({workerId: props.id}).then(result =>{
          isPedning.value = false
          report.value = result.data
        }).catch(err =>{
          isPedning.value = false
        })
      }

      const formatDate = date => {
        return dayjs(date).format('DD/MM/YYYY');
    }

    const instantBornPending = ref(false)
    const handleInstantBorn = e =>{
      const instantBorn = projectFunctions.httpsCallable('instantBorn')
      instantBornPending.value = true
      instantBorn({id: props.id}).then(reuslt =>{
        console.log(reuslt.data)
        instantBornPending.value = false
      }).catch(err =>{
        instantBornPending.value = false
      })

    }

    const firstAidPending = ref(false)
    const handleFirstAid = e =>{
      const firstAid = projectFunctions.httpsCallable('firstAid')
      firstAidPending.value = true
      firstAid({id: props.id}).then(result =>{
        report.value = result.data
        firstAidPending.value = false
      }).catch(err =>{
        firstAidPending. value =false
      })
    }

      return { dayjs,workerInstantBorn,
        workerFirstAid, instantBornPending,
handleInstantBorn,
firstAidPending,
handleFirstAid, report,workerVip,formatDate,worker,toggleModal,workerWork,handleWokerWork,isPedning }
    },
}
</script>