import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Signup from '../views/auth/Signup.vue'
import Login from '../views/auth/Login.vue'
import Shop from '../views/Shop.vue'
import Account from '../views/Account.vue'
import Worker from '../views/Worker.vue'
import Item from '../views/Item.vue'
import WorkerDetail from '../views/worker/Detail.vue'
import Thief from '../views/Thief.vue'
import Partner from '../views/Partner.vue'
import Admin from '../views/Admin.vue'
import HowToPlay from '../views/HowToPlay.vue'
import BecomeAlliance from '../views/BecomeAlliance.vue'
import TermOfService from '../views/TermOfService.vue'
import Rank from '../views/Rank.vue'
import getUser from '../composables/getUser'
import Key from '../views/Key.vue'
import Marketplace from '../views/Marketplace.vue'
import Stat from '../views/Stat.vue'
import Ticket from '../views/Ticket.vue'
import Profile from '../views/Profile.vue'
import PVP from '../views/PVP.vue'
import Data from '../views/Data.vue'
import Lotto from '../views/Lotto.vue'
import Map from '../views/Map.vue'
import Farm from '../views/Farm.vue'
import Cave from '../views/Cave.vue'

const { user, admin, partner } = getUser()

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
    
  },
  {
    path: '/newupdate',
    name: 'Data',
    component: Data
    
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop,
    meta: { requiresAuth: true }
  },
  {
    path: '/rank',
    name: 'Rank',
    component: Rank,
  },
  {
    path: '/key',
    name: 'Key',
    component: Key,
  },
  {
    path: '/marketplace',
    name: 'Marketplace',
    component: Marketplace,
  },
  {
    path: '/profile/:id',
    name: 'Profile',
    component: Profile,
    props: true
  },
  {
    path: '/stat',
    name: 'Stat',
    component: Stat,
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: { requiresAuth: true }
  },
  {
    path: '/worker',
    name: 'Worker',
    component: Worker,
    meta: { requiresAuth: true }
  },
  {
    path: '/worker/:id',
    name: 'WorkerDetail',
    component: WorkerDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/item',
    name: 'Item',
    component: Item,
    meta: { requiresAuth: true }
  },
  {
    path: '/farm',
    name: 'Farm',
    component: Farm,
    meta: { requiresAuth: true }
  },
  {
    path: '/ticket',
    name: 'Ticket',
    component: Ticket,
    meta: { requiresAuth: true }
  },
  {
    path: '/lotto',
    name: 'Lotto',
    component: Lotto,
    meta: { requiresAuth: true }
  },
  {
    path: '/cave',
    name: 'Cave',
    component: Cave,
    meta: { requiresAuth: true }
  },
  {
    path: '/ticket',
    name: 'Ticket',
    component: Ticket,
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    name: 'PVP',
    component: PVP,
    meta: { requiresAuth: true }
  },
  {
    path: '/thief',
    name: 'Thief',
    component: Thief
  },
  {
    path: '/map',
    name: 'Map',
    component: Map
  },
  {
    path: '/partner',
    name: 'Partner',
    component: Partner,
    meta: { requiresPartner: true }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: { requiresAdmin: true }
  },
  {
    path: '/howtoplay',
    name: 'HowToPlay',
    component: HowToPlay
  },
  {
    path: '/becomealliance',
    name: 'BecomeAlliance',
    component: BecomeAlliance
  },
  {
    path: '/terms',
    name: 'TermOfService',
    component: TermOfService
  },
  // Catch-all route for 404 Not Found
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const { user, admin, partner } = getUser()

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!user.value) {
      next('/')
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (!admin.value) {
      next('/')
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresPartner)) {
    if (!partner.value) {
      next('/')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
